<!-- 用户管理 -->
<template>
    <div class="dxxj public-bg">
        <!-- 搜索条件 -->
        <UmFormList @formData="getFormData" @add="addUsers"></UmFormList>
        <ATable :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type="usermanage"
            :roleid="user.roleid" ref="tableRef" @changeUser="changeUser" @editModel="editModel" @editPassModel="changePsd"
            @auth="authUserData" @range="rangeCompany" v-if="user !== null"></ATable>
        <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange"
            ref="paginationRef"></APagination>
        <!-- 编辑 -->
        <UmFormModelList ref="umformModelRef" @updateUmList="updateUmList"></UmFormModelList>
        <!-- 重置密码 -->
        <ChangePsd ref="changePsdref"></ChangePsd>
        <!-- 数据权限 -->
        <UmDataAuth ref="dataauth"></UmDataAuth>
        <!-- 切换公司范围 -->
        <RangeCompany ref="rangcompany"></RangeCompany>
    </div>
</template>
<script>
import UmFormList from '@/components/FormList/UmFormList/index'
import APagination from '@/components/Pagination/index'
import ATable from '@/components/Table/index'
import UmFormModelList from '@/components/UserManage/Modal/UmFormModelList/index'
import UmDataAuth from '@/components/UserManage/Modal/UmDataAuth/DataAuth/index'
import RangeCompany from '@/components/UserManage/Modal/RangeCompany/index'
import ChangePsd from '@/components/PersonalMessage/ChangePsd'
import Busscommutil from '../../utils/busscommutil'

export default {
    data() {
        return {
            columns: [
                {
                    title: '编号',
                    dataIndex: 'id',
                    fixed: 'left',
                    width: '60'
                },
                {
                    title: '用户名',
                    fixed: 'left',
                    dataIndex: 'username',
                    width: '180',
                    scopedSlots: {
                        customRender: 'rulemsg'
                    }
                },
                {
                    title: '账号',
                    dataIndex: 'userCode',
                    scopedSlots: {
                        customRender: 'rulemsg'
                    }
                },
                {
                    title: '手机号',
                    dataIndex: 'mobile'
                },
                {
                    title: '城市',
                    dataIndex: 'site',
                    width: '10%'
                },
                {
                    title: '所属公司',
                    dataIndex: 'companyName',
                    width: '10%',
                    scopedSlots: {
                        customRender: 'rulemsg'
                    }
                },
                {
                    title: '切换公司范围',
                    dataIndex: '',
                    width: '5%',
                    scopedSlots: {
                        customRender: 'rangecompany'
                    }
                },
                {
                    title: '角色',
                    dataIndex: 'roleName',
                    width: '7%'
                },
                {
                    title: '手机认证',
                    dataIndex: 'isMobileAuthDesc',
                    width: '4%'
                },
                {
                    title: '用户状态',
                    dataIndex: 'statusDesc',
                    scopedSlots: {
                        customRender: 'userstatus'
                    }
                },
                // {
                //     title: '是否开启短信通知',
                //     dataIndex: 'smsOffon',
                //     width: '6%',
                //     scopedSlots: {
                //         customRender: 'smsOffon'
                //     }
                // },
                // {
                //     title: '小程序',
                //     dataIndex: 'openId',
                //     width: '4%',
                //     scopedSlots: {
                //         customRender: 'wxApplet'
                //     }
                // },
                {
                    title: '公众号',
                    dataIndex: 'mpOpenid',
                    width: '4%',
                    scopedSlots: {
                        customRender: 'official'
                    }
                },
                {
                    title: '数据权限',
                    dataIndex: 'operation1',
                    width: '4%',
                    scopedSlots: {
                        customRender: 'userauth'
                    }
                },
                {
                    title: '操作时间',
                    dataIndex: 'operateTime',
                    width: '7%'
                },
                {
                    title: '操作ip',
                    dataIndex: 'operateIp'
                },
                {
                    title: '操作用户',
                    dataIndex: 'operateUserName'
                },
                {
                    title: '备注',
                    dataIndex: 'remark',
                    scopedSlots: { customRender: 'rulemsg' }
                }, {
                    title: '操作',
                    dataIndex: 'operation',
                    fixed: 'right',
                    width: '130',
                    scopedSlots: { customRender: 'usermanage' }
                }],
            userType: 1,
            dxxjData: [],
            pageInfo: {
                page: '',
                limit: '',
                companyName: '',
                mobile: '',
                site: '',
                username: '',
                roleid: '',
                status: '',
                mpOpen: '',
                smsOffon: ''
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            },
            scroll: {
                x: 2200,
                y: 500
            },
            modalTitle: ''
        }
    },
    components: {
        UmFormList, APagination, ATable, UmFormModelList, ChangePsd, UmDataAuth, RangeCompany
    },
    created() {
        this.user = this.$store.state.userInfo
    },
    methods: {
        // 查询表格数据
        getFormData(data) {
            if (data) {
                this.pageInfo = data
                this.$refs.paginationRef.onChange(1, 10)
                return
            }
            this.$refs.tableRef.getList(this.pageInfo)
        },
        // 新增用户
        addUsers() {
            let obj = {
                status: '10020001',
                site: '上海市 上海市 黄浦区',
                company: 2
            }
            this.$refs.umformModelRef.getFormData(obj, '新增')
        },
        // 修改用户
        editModel(val) {
            let obj = { ...val }
            this.$refs.umformModelRef.getFormData(obj, '修改')
        },
        updateUmList() {
            this.$refs.tableRef.getList()
        },
        changePsd(record) {
            this.$refs.changePsdref.getFormData(record)
        },
        // 更新用户信息
        changeUser(val) {
            val.row.operateUserId = this.$store.state.userInfo.id;
            val.row.operateUserName = this.$store.state.userInfo.username;
            this.$user.doUpdateUser(val.row).then((res) => {
                this.$message.success('修改成功')
            })
        },
        // 数据权限
        authUserData(row) {
            if (this.$store.state.userInfo.roleid !== 99 && this.$store.state.userInfo.roleid !== 1) { // 只有系统管理员有这个功能
                this.$message.warning('暂无权限')
                return false
            }
            this.$refs.dataauth.show(row)
        },
        // 切换公司范围
        rangeCompany(row) {
            if (this.$store.state.userInfo.roleid !== 99 && this.$store.state.userInfo.roleid !== 1) { // 只有系统管理员有这个功能
                this.$message.warning('暂无权限')
                return false
            }
            this.$refs.rangcompany.show(row)
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.$refs.tableRef.getList(this.pageInfo)
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        },
        onReCancel() {
            this.$refs.tableRef.getList()
        }
    }
}
</script>
<style scoped lang="less">
@deep: ~'>>>';
</style>
