<template>
  <a-form @submit="handleSubmit" :form="repository" class="form" v-bind="layout">
    <a-row class="form-row" :gutter="24">
      <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="用户名">
          <a-input placeholder="用户名" v-model="repository.username" allowClear/>
        </a-form-item>
      </a-col>
      <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="手机号">
          <a-input placeholder="手机号" oninput="value = value.replace(/[^\d]/g,'')" v-model="repository.mobile" allowClear/>
        </a-form-item>
      </a-col>
      <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="角色">
           <a-select v-model="repository.roleid" placeholder="选择角色" allowClear showSearch optionFilterProp="label">
            <a-select-option v-for='item in rolesList' :label='item.authRoleName' :key='item.authRoleId'
            :value='item.authRoleId'>
            {{ item.authRoleName }}
          </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="所属公司">
          <a-select v-model='repository.companyId' placeholder='选择所在公司' show-search optionFilterProp='label' allowClear>
            <a-select-option :label='item.companyname' v-for='item in companyList' :key='item.id' :value='item.id'>
              {{ item.companyname }}
            </a-select-option>
          </a-select>
          <!-- <a-input placeholder="所属公司" v-model="repository.companyName" allowClear/> -->
        </a-form-item>
      </a-col>
       <a-col :xxl="8" :xl="12" :lg="24" :md="24" :sm="24" class="limit-width">
        <a-form-item label="城市">
          <Address ref="addressRef" @site="getSite" :isCity='true' :isCounty="false"></Address>
        </a-form-item>
      </a-col>
      <!-- <a-col :xxl="5" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="短信通知">
          <a-radio-group v-model="repository.smsOffon" @change="handleSubmit">
            <a-radio :value="0">
              未开通
            </a-radio>
            <a-radio :value="1">
              已开通
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col> -->
      <a-col :xxl="5" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="公众号">
          <a-radio-group v-model="repository.mpOpen" @change="handleSubmit">
            <a-radio :value="0">
              未绑定
            </a-radio>
            <a-radio :value="1">
              已绑定
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>
      <a-col :xxl="5" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="用户状态">
          <a-radio-group v-model="repository.status" @change="handleSubmit">
            <a-radio :value="10020001">
              启用
            </a-radio>
            <a-radio :value="10020002">
              禁用
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
        <a-col style="margin-bottom: 40px;float: right">
            <a-button @click='add' type="primary" style="margin-right: 15px" v-if="user.roleid === 99 || user.roleid === 1">新增</a-button>
            <a-button @click='resetData' type="primary" style="margin-right: 15px">重置查询条件</a-button>
            <a-button @click='exportData' type="primary" style="margin-right: 15px">导出数据</a-button>
            <a-button htmlType="submit" type="primary">查询</a-button>
        </a-col>
    </a-row>
  </a-form>
</template>

<script>
  import Address from '@/components/Address/index'
  import Busscommutil from '../../../utils/busscommutil'
  export default {
    name: 'RepositoryForm',
    components: { Address },
    data () {
      return {
        repository: {
          username: '',
          mobile: '',
          companyId: '',
          site: '',
          roleid: '',
          status: '',
          mpOpen: '',
          smsOffon: ''
        },
        layout: {
            labelCol: { span: 5 },
            wrapperCol: { span: 19 }
        },
        currentSite: '',
        rolesList: [],
        companyList: [],
        user: null
      }
    },
    created() {
      this.getRolesList()
      this.getCompanyList()
      this.user = JSON.parse(window.sessionStorage.getItem('userInfo'))
    },
    methods: {
      handleSubmit (e) {
        e.preventDefault()
        this.repository.site = this.currentSite
        this.$emit('formData', this.repository)
      },
      //  获取公司列表
      getCompanyList() {
        let companylist = Busscommutil.checkNull(this.$store.state.companylist)
        if (companylist) {
          this.companyList = this.$store.state.companylist
        } else {
          this.$company.doGetCompanyByDisable('', 0, '').then((res) => {
            this.$store.commit('saveCompanyList', res.data);
            this.companyList = res.data
          }).catch(() => {
          })
        }
      },
      getSite(val) {
        if (val) {
          if (val.length >= 2) {
            if (val.lastIndexOf(' ') === val.length - 1) {
              val = val.substr(0, val.length - 1)
            }
          }
        } else {
          val = ''
        }
        this.currentSite = val
      },
      // 获取角色列表
      getRolesList() {
        let user = this.$store.state.userInfo
        this.$rolemanage.doGetClickRoleAuth(user.roleid).then((res) => {
          this.rolesList = res.data
        }).catch(() => {
        })
      },
      // 导出
      exportData(e) {
        e.preventDefault()
        let fidles = this.repository
        if (fidles.companyId === undefined) {
          fidles.companyId = ''
        }
        const _url = '/api/v1/manager/UserApi/doGetUserList/DoExport'
        window.location.href = this.$axios.defaults.baseURL + _url + '?username=' + fidles.username + '&mobile=' + fidles.mobile + '&site=' + fidles.site + '&roleid=' + fidles.roleid + '&smsOffon=' + fidles.smsOffon + '&status=' + fidles.status + '&mpOpen=' + fidles.mpOpen + '&companyId=' + fidles.companyId + '&token=' + window.sessionStorage.getItem('token')
      },
      // 重置查询条件
      resetData() {
        this.repository = {
          username: '',
          mobile: '',
          companyId: '',
          site: '',
          roleid: '',
          status: '',
          mpOpen: '',
          smsOffon: ''
        }
        this.$refs.addressRef.prov = ''
        this.$refs.addressRef.city = ''
        this.$emit('formData', this.repository)
      },
      // 新增用户
      add() {
        this.$emit('add')
      }
    }
  }
</script>

<style scoped lang="less">
@deep: ~'>>>';
  .limit-width {
    @{deep} .ant-form-item-label {
      width: 15% !important;
    }
  }
</style>
