<template>
    <a-form @submit="handleSubmit" :form="repository" class="form" v-bind="layout">
        <a-row class="form-row" :gutter="24">
            <a-col :xxl="12" :xl="12" :lg="12" :md="12" :sm="24">
                <a-form-item label="地区">
                    <a-cascader :options="areaOptions" v-model="defaultValue" placeholder="请选择省市区" @change="onChangeQjssq" :allowClear="true" change-on-select :showSearch="true"/>
                </a-form-item>
            </a-col>
            <a-col :xxl="12" :xl="12" :lg="12" :md="12" :sm="24">
                <a-button style="margin-right: 10px" type="primary" @click="addRegion">新增</a-button>
                <a-button style="margin-right: 10px" type="primary" @click="deleteAll">批量删除</a-button>
                <a-button htmlType="submit" type="primary">查询</a-button>
            </a-col>
        </a-row>
    </a-form>
</template>
<script>
import areaJson from '../../../../assets/js/area.json'
import Busscommutil from '../../../../utils/busscommutil'
export default {
    name: 'RepositoryForm',
    data() {
        return {
            areaOptions: areaJson, // 地区
            repository: {
            },
            defaultValue: [],
            layout: {
                labelCol: { span: 5 },
                wrapperCol: { span: 19 }
            }
        }
    },
    created() {
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault()
            this.$emit('formData', this.repository)
        },
        addRegion() {
            this.$emit('add')
        },
        deleteAll() {
            this.$emit('delete')
        },
        onChangeQjssq(val) {
            if (val.length === 0) {
                this.repository = {}
            }
            if (val.length === 1) {
                this.repository.provinces = val[0]
                this.repository.city = ''
                this.repository.area = ''
            }
            if (val.length === 2) {
                this.repository.provinces = val[0]
                this.repository.city = val[1]
                this.repository.area = ''
            }
            if (val.length === 3) {
                this.repository.provinces = val[0]
                this.repository.city = val[1]
                this.repository.area = val[2]
            }
        }
    }
}
</script>
<style scoped></style>
