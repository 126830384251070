<template>
  <a-modal v-model="editVisible" :title="modalTitle" ok-text="保存" cancel-text="关闭" @ok="saveModelData"
    :bodyStyle='bodyStyle' :destroyOnClose="true" :maskClosable="false">
    <div class="fixed-mask" v-if="fetching">
      <a-spin size="large" tip="保存中..." />
    </div>
    <a-form-model class="editFormList" ref="ruleForm" :model="form" :rules="rules" layout="inline">
      <a-form-model-item label="序号" prop="sn">
        <a-input v-model="form.sn" allowClear placeholder="请填写序号" type="number" min="1"
          oninput="value=value.replace(/\s/g,'')" :maxLength="20"></a-input>
      </a-form-model-item>
      <a-form-model-item label="批次" prop="batch">
        <a-input v-model="form.batch" allowClear placeholder="请填写批次"
          oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="分标顺序" prop="scaleOrder">
        <a-input v-model="form.scaleOrder" allowClear placeholder="请填写分标顺序"
          oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="分标名称" prop="scaleName">
        <a-input v-model="form.scaleName" allowClear placeholder="请填写分标名称"
          oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="分包名称" prop="subpackageName">
        <a-input v-model="form.subpackageName" allowClear placeholder="请填写分包名称"
          oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="包号" prop="packageNum">
        <a-input v-model="form.packageNum" allowClear placeholder="请填写包号" type="number" min="1"
          oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="网省采购申请号" prop="proOnlineApplyNum">
        <a-input v-model="form.proOnlineApplyNum" allowClear oninput="value=value.replace(/[^\d]/g,'')" placeholder="请填写"
          :maxLength="20"></a-input>
      </a-form-model-item>
      <a-form-model-item label="商品ID" prop="goodsId">
        <a-input v-model="form.goodsId" allowClear placeholder="请填写商品ID" oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="物料大类" prop="matBigType">
        <a-input v-model="form.matBigType" allowClear placeholder="请填写物料大类" oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="物料中类" prop="matCentreType">
        <a-input v-model="form.matCentreType" allowClear placeholder="请填写物料中类" oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="物料小类" prop="matSmallType">
        <a-input v-model="form.matSmallType" allowClear placeholder="请填写物料小类" oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="物料编码" prop="matCode">
        <a-input v-model="form.matCode" allowClear placeholder="请填写物料编码" oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="物料描述" prop="matDesc">
        <!-- <a-input v-model="form.matDesc" type='textarea' placeholder="请填写物料描述" :maxLength="200"></a-input> -->
        <a-textarea v-model="form.matDesc" placeholder="请填写物料描述" :rows="3" :maxLength="200" />
        <div style="position: absolute;top: -5px;right: 5px;">{{ form.matDesc.length }}/200</div>
      </a-form-model-item>
      <a-form-model-item label="扩展码" prop="expandCode">
        <a-input v-model="form.expandCode" allowClear placeholder="请填写扩展码" oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="扩展描述" prop="expandDesc">
        <!-- <a-input v-model="form.expandDesc" type='textarea' placeholder="请填写扩展描述" :maxLength="200"></a-input> -->
        <a-textarea v-model="form.expandDesc" placeholder="请填写扩展描述" :rows="3" :maxLength="200" />
        <div style="position: absolute;top: -5px;right: 5px;">{{ form.expandDesc.length }}/200</div>
      </a-form-model-item>
      <a-form-model-item label="参考品牌" prop="refBrand">
        <a-input v-model="form.refBrand" allowClear placeholder="请填写参考品牌" oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="参考规格型号" prop="refModelSpec">
        <a-input v-model="form.refModelSpec" allowClear placeholder="请填写参考规格型号" oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="参考品牌制造商" prop="refBrandManufacturer">
        <a-input v-model="form.refBrandManufacturer" placeholder="请填写参考品牌制造商"></a-input>
      </a-form-model-item>
      <a-form-model-item label="功能描述" prop="funcDesc">
        <!-- <a-input v-model="form.funcDesc" type='textarea' placeholder="请填写功能描述" :maxLength="200"></a-input> -->
        <a-textarea v-model="form.funcDesc" placeholder="请填写功能描述" :rows="3" :maxLength="200" />
        <div style="position: absolute;top: -5px;right: 5px;">{{ form.funcDesc.length }}/200</div>
      </a-form-model-item>
      <a-form-model-item label="国网计量单位" prop="stateGridUnit">
        <a-input v-model="form.stateGridUnit"  placeholder="请填写国网计量单位"></a-input>
      </a-form-model-item>
      <a-form-model-item label="成交人" prop="trader">
        <a-input v-model="form.trader" placeholder="请填写成交人"></a-input>
      </a-form-model-item>
      <a-form-model-item label="商品名称" prop="tradeName">
        <a-input v-model="form.tradeName" placeholder="请填写商品名称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="响应品牌" prop="respBrand">
        <a-input v-model="form.respBrand" placeholder="请填写响应品牌"></a-input>
      </a-form-model-item>
      <a-form-model-item label="响应规格型号" prop="respModelSpec">
        <a-input v-model="form.respModelSpec" placeholder="请填写响应规格型号"></a-input>
      </a-form-model-item>
      <a-form-model-item label="响应品牌制造商" prop="respBrandManufacturer">
        <a-input v-model="form.respBrandManufacturer" placeholder="请填写响应品牌制造商"></a-input>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
export default {
  name: 'editFormList',
  components: {
  },
  data() {
    // 序号
    var validatorSn = (rule, value, callback) => {
      if ((value === '' || value === null || value === undefined)) {
        callback();
      }
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (value.length >= 20) {
        return callback(new Error('序号长度不能大于20位'));
      } else {
        callback();
      }
    }
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      editVisible: false,
      form: {
        sn: '',
        funcDesc: '',
        matDesc: '',
        expandDesc: '',
        busiLicenseUrl: '',
        batch: '',
        scaleOrder: '',
        scaleName: '',
        subpackageName: '',
        proOnlineApplyNum: '',
        packageNum: '',
        goodsId: '',
        matBigType: '',
        matCentreType: '',
        matSmallType: '',
        matCode: '',
        expandCode: '',
        refBrand: '',
        refModelSpec: '',
        refBrandManufacturer: '',
        stateGridUnit: '',
        trader: '',
        tradeName: '',
        respBrand: '',
        respModelSpec: '',
        respBrandManufacturer: ''
      },
      rules: {
        sn: [{ required: false, message: '请填写序号', trigger: 'blur' }, { validator: validatorSn, trigger: 'blur' }],
        batch: [{ required: false, message: '请填写批次', trigger: 'blur' }],
        scaleOrder: [{ required: false, message: '请填写分标顺序', trigger: 'blur' }],
        scaleName: [{ required: false, message: '请填写分标名称', trigger: 'blur' }],
        subpackageName: [{ required: false, message: '请填写分包名称', trigger: 'blur' }],
        packageNum: [{ required: false, message: '请填写包号', trigger: 'blur' }],
        proOnlineApplyNum: [{ required: false, message: '请填写网省采购申请号', trigger: 'blur' }],
        goodsId: [{ required: false, message: '请填写商品ID', trigger: 'blur' }],
        matBigType: [{ required: false, message: '请填写物料大类', trigger: 'blur' }],
        matCentreType: [{ required: false, message: '请填写物料中类', trigger: 'blur' }],
        matSmallType: [{ required: false, message: '请填写物料小类', trigger: 'blur' }],
        matCode: [{ required: false, message: '请填写物料编码', trigger: 'blur' }],
        matDesc: [{ required: false, message: '请填写物料描述', trigger: 'blur' }],
        expandCode: [{ required: false, message: '请填写扩展码', trigger: 'blur' }],
        expandDesc: [{ required: false, message: '请填写扩展描述', trigger: 'blur' }],
        refBrand: [{ required: false, message: '请填写参考品牌', trigger: 'blur' }],
        refModelSpec: [{ required: false, message: '请填写参考规格型号', trigger: 'blur' }],
        refBrandManufacturer: [{ required: false, message: '请填写参考品牌制造商', trigger: 'blur' }],
        funcDesc: [{ required: false, message: '请填写功能描述', trigger: 'blur' }],
        stateGridUnit: [{ required: false, message: '请填写国网计量单位', trigger: 'blur' }],
        trader: [{ required: false, message: '请填写成交人', trigger: 'blur' }],
        tradeName: [{ required: false, message: '请填写商品名称', trigger: 'blur' }],
        respBrand: [{ required: false, message: '请填写响应品牌', trigger: 'blur' }],
        respModelSpec: [{ required: false, message: '请填写响应规格型号', trigger: 'blur' }],
        respBrandManufacturer: [{ required: false, message: '请填写响应品牌制造商', triggtrigger: 'blur' }]
      },
      bodyStyle: {
        height: '550px',
        overflowY: 'scroll'
      },
      modalTitle: '',
      fetching: false, // 是否加载完成
      isCommit: false // 保存状态
    };
  },
  watch: {
    // 去掉表单验证信息二次打开残留
    editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
      // 监视dialog状态（打开、关闭）
      if (val) {
        try {
          this.$refs.ruleForm.resetFields() // 重置编辑表单
          // addForm 为ref指向的表单
        } catch (e) { }
      }
    }
  },
  created() {
  },
  methods: {
    show(val, title) {
      this.modalTitle = title
      if (this.modalTitle === '修改') {
        this.form = val
      } else {
        this.form = {
          sn: '',
          funcDesc: '',
          matDesc: '',
          expandDesc: '',
          busiLicenseUrl: '',
          batch: '',
          scaleOrder: '',
          scaleName: '',
          subpackageName: '',
          proOnlineApplyNum: '',
          packageNum: '',
          goodsId: '',
          matBigType: '',
          matCentreType: '',
          matSmallType: '',
          matCode: '',
          expandCode: '',
          refBrand: '',
          refModelSpec: '',
          refBrandManufacturer: '',
          stateGridUnit: '',
          trader: '',
          tradeName: '',
          respBrand: '',
          respModelSpec: '',
          respBrandManufacturer: ''
        }
      }
      this.editVisible = true
    },
    saveModelData() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.fetching = true
          if (this.modalTitle === '新增') {
            this.$businessdata.doAddMatInventory(this.form).then(res => {
              this.$message.success('新增成功')
              this.fetching = false
              this.editVisible = false
              this.$emit('update')
            }).catch(() => {
              this.fetching = false
            })
          }
          if (this.modalTitle === '修改') {
            this.$businessdata.doUpdateMatInventory(this.form).then(res => {
              this.$message.success('修改成功')
              this.fetching = false
              this.editVisible = false
              this.$emit('update')
            }).catch(() => {
              this.fetching = false
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>
<style scoped lang="less">
@deep: ~'>>>';

@{deep} .ant-modal {
  width: 1200px !important
}

.ant-form-item {
  width: 33% !important;
  margin-right: 3px !important;

  @{deep}.ant-form-item-label {
    width: 31% !important;
  }

  @{deep}.ant-form-item-control-wrapper {
    width: 69% !important;
  }

  @{deep} .ant-form-item-control {
    width: 100% !important;
  }
}
</style>
