<template>
  <a-modal v-model="editVisible" :title="modalTitle" ok-text="保存" cancel-text="关闭" @ok="saveModelData"
    :bodyStyle='bodyStyle'>
    <div style="display: flex;align-items: center;justify-content: center;height: 500px" v-if="fetching">
      <a-spin size="large" />
    </div>
    <a-form-model class="editFormList" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
      :wrapper-col="wrapperCol">
      <a-form-model-item label="分类代码" prop="id" v-if="modalTitle === '修改'">
        <a-input v-model="form.id" disabled />
      </a-form-model-item>
      <a-form-model-item label="代码名称" prop="name">
        <a-input v-model="form.name" allowClear oninput="value=value.replace(/\s/g,'')"/>
      </a-form-model-item>
      <!-- <a-form-model-item label="父级代码" prop="parentId">
        <a-input v-model="form.parentId"/>
      </a-form-model-item> -->
      <a-form-model-item label="父级名称">
        <a-input v-model="form.parentName" readOnly/>
      </a-form-model-item>
      <a-form-model-item label="类别">
        <!-- <a-select v-model="form.dictId" placeholder="直接选择或搜索选择" allowClear showSearch optionFilterProp="label">
          <a-select-option :label="item.dictName" v-for="(item) in list" :key="item.id" :value="item.id">
            {{ item.dictName }}
          </a-select-option>
        </a-select> -->
        <a-input v-model="form.dictName" readOnly/>
      </a-form-model-item>
      <a-form-model-item label="级数">
        <a-input v-model="form.level" readOnly/>
      </a-form-model-item>
      <a-form-model-item label="是否末级" prop="isEndlevel">
        <a-radio-group v-model="form.isEndlevel">
          <a-radio :value="1">
            是
          </a-radio>
          <a-radio :value="0">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否禁用" prop="disable">
        <a-radio-group v-model="form.disable">
          <a-radio :value="1">
            是
          </a-radio>
          <a-radio :value="0">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
export default {
  name: 'editFormList',
  components: {
  },
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      editVisible: false,
      form: {
        dictName: '',
        name: '',
        level: '',
        isEndlevel: '',
        disable: ''
      },
      rules: {
        name: [{ required: true, message: '请填写代码名称', trigger: 'blur' }],
        level: [
          { required: true, message: '请填写级数', trigger: 'blur' }
        ],
        dictId: [
          { required: true, message: '请选择类别名称', trigger: 'change' }
        ],
        parentId: [
          { required: true, message: '请填写父级代码', trigger: 'blur' }
        ],
        parentName: [
          { required: true, message: '请填写父级名称', trigger: 'blur' }
        ],
        disable: [
          { required: true, message: '请选择是否禁用', trigger: 'change' }
        ],
        isEndlevel: [
          { required: true, message: '请选择是否末级', trigger: 'change' }
        ]
      },
      bodyStyle: {
        height: '500px',
        overflowY: 'scroll'
      },
      modalTitle: '',
      fetching: false, // 是否加载完成
      isCommit: false, // 保存状态
      list: []
    };
  },
  watch: {
    // 去掉表单验证信息二次打开残留
    editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
      // 监视dialog状态（打开、关闭）
      if (val) {
        try {
          this.$refs.ruleForm.resetFields() // 重置编辑表单
          // addForm 为ref指向的表单
        } catch (e) { }
      }
    }
  },
  created() {
  },
  methods: {
    getCategoryList() {
      this.$category.doGetDictAll().then((res) => {
        this.list = res.data
      })
    },
    getFormData(val, title) {
      this.getCategoryList()
      this.modalTitle = title
      this.editVisible = true
      this.form = val
    },
    // 选择分类类别
    getConnectFllb() {
      this.list.forEach(item => {
        if (item.id === this.form.dictId) {
          this.form.dictName = item.dictName
        }
      })
    },
    saveModelData() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.getConnectFllb()
          this.fetching = true
          if (this.modalTitle === '修改') {
            this.$category.doUpdateDictItem(this.form).then((res) => {
              this.fetching = false
              this.$message.success('修改成功')
              this.editVisible = false
              this.$emit('update')
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>
<style scoped lang="less">
@deep: ~'>>>';

.commit {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background: rgba(0, 0, 0, .45);
  top: 0;
  left: 0;
}
</style>
