<template>
    <div class="login-con">
        <a-form-model :model="formInline" :rules="rules" ref="loginFormRef">
            <a-form-model-item prop="phone" v-if="tab === 0">
                <a-input v-model="formInline.phone" placeholder="请输入手机号">
                </a-input>
            </a-form-model-item>
            <a-form-model-item prop="accountCode" v-if="tab === 1">
                <a-input v-model="formInline.accountCode" placeholder="请输入账号">
                </a-input>
            </a-form-model-item>
            <a-form-model-item prop="password">
                <a-input-password placeholder="请输入密码" v-model="formInline.password" class="password"/>
            </a-form-model-item>
            <div style="text-align: center">
                <a-button type="primary" ref='loadRef' @click="handleSubmit">
                    <img src="../../../public/images/login.png" alt="" width="100%">
                </a-button>
            </div>
        </a-form-model>
    </div>
</template>
<script>

import cookUtil from '../../api/util/sessioncook'
export default {
    data() {
        return {
            rules: {
                phone: [
                    { required: true, message: '请输入手机号!' }
                ],
                accountCode: [
                    { required: true, message: '请输入账号!' }
                ],
                password: [{
                    required: true, message: '请输入密码!', validator: 'click'
                }]
            },
            userInfo: {
                phone: '',
                accountCode: '',
                password: ''
            },
            formInline: {
                phone: '',
                password: '',
                accountCode: ''
            },
            fetching: false
        }
    },
    props: ['tab'],
    created() {
        cookUtil.getCookie(this)
        this.formInline = this.userInfo
    },
    watch: {
        tab(val, newVal) {
            if (val) {
                this.$refs.loginFormRef.resetFields() // 重置编辑表单
                cookUtil.getCookie(this)
                this.formInline = this.userInfo
            }
        }
    },
    methods: {
        // 账号密码登录
        handleSubmit() {
            this.$refs.loginFormRef.validate(valid => {
                if (valid) {
                    this.$emit('submit', this.formInline)
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })
        }
    }
}
</script>
<style scoped lang="less">
@deep: ~'>>>';

.login-con {
    width: 350px;
    margin: 5% auto 0;

    @{deep} .ant-input {
        display: block;
        margin: 0 0 8px 0;
        width: 350px;
        height: 44px;
        background-color: #eaf1fe !important;
        border-radius: 4px;
        opacity: 0.9;
        border: 0;
        font-size: 16px;
        outline: none;
        padding-left: 10px;
        color: #000000;
    }

    @{deep} .ant-form-explain {
        text-align: left;
    }

    @{deep} .ant-input-affix-wrapper .ant-input-suffix {
        top: 46% !important
    }

    .title {
        padding-bottom: 10%;
    }

    .ant-btn {
        height: 46px;
        margin-top: 10px !important;
        width: 380px;
        background: none;
        border: none;
        box-shadow: none;
        padding-left: 10px;

        &:hover {
            box-shadow: none;
            outline: none;
        }

        &:after {
            display: none;
        }
    }
}
</style>
