import { getAction, deleteAction, putAction, postAction, postActionQs, httpAction } from '@/api/manage'
import Vue from 'vue'

/**
  * 用户管理接口域名的管理
  */
const base = {
    AdminLoginApi: '/api/v1/AdminLoginApi/', // 用户登录
    userManage: '/api/v1/manager/UserApi/', // 用户管理
    SysUserAuthDataApi: '/api/v1/manager/SysUserAuthDataApi/', // 授权公司
    SysAuthDataRegionApi: '/api/v1/manager/SysAuthDataRegionApi/', // 授权区域
    UserCompanyApi: '/api/v1/manager/UserCompanyApi/' // 用户切换公司范围
}
// 登录
const login = (phone, accountCode, password) => postActionQs(base.AdminLoginApi + 'login', {
    password: password,
    accountCode: accountCode,
    phone: phone
});
// 用户管理
const doAddUser = (params) => postActionQs(base.userManage + 'doAddUser', params);// 新增用户
const doGetUserList = (params) => postActionQs(base.userManage + 'doGetUserList', params); // 获取人员列表
const doUpdateUser = (params) => postActionQs(base.userManage + 'doUpdateUser', params); // 修改人员信息
const getUsersList = () => postActionQs(base.userManage + 'doGetUserListAll'); // 获取所有人员
const doUpdatePass = (params) => postActionQs(base.userManage + 'doUpdatePass', params); // 修改密码
const doGetUserListNoAuth = (params) => postActionQs(base.userManage + 'doGetUserListNoAuth', params); // 获取用户数据-分页(无权限控制)

//  ------------------------------------------------------------------授权公司start------------------------------------------------------------------
const doAddUserAuthData = (params) => postAction(base.SysUserAuthDataApi + 'doAddUserAuthData', params)// 新增
const doDeleteUserAuthData = (ids) => postActionQs(base.SysUserAuthDataApi + 'doDeleteUserAuthData', ids)// 删除
const doGetUserAuthDataList = (userId, companyName) => postActionQs(base.SysUserAuthDataApi + 'doGetUserAuthDataList', {
    userId: userId, // 用户id
    companyName: companyName // 公司名称

})// 获取
//  ------------------------------------------------------------------授权公司end------------------------------------------------------------------

// ----------------------------------------------------授权区域start---------------------------------------------------------
const doAddUserAuthDataRegion = (params) => postAction(base.SysAuthDataRegionApi + 'doAddUserAuthDataRegion', params)// 新增

const doGetUserAuthDataRegionList = (params) => postActionQs(base.SysAuthDataRegionApi + 'doGetUserAuthDataRegionList', params)// 获取全部授权区域数据

const doDeleteUserAuthDataRegion = (ids) => postActionQs(base.SysAuthDataRegionApi + 'doDeleteUserAuthDataRegion', ids)// 删除
// ----------------------------------------------------授权区域end-----------------------------------------------------------
// ----------------------------------------------------用户授权公司start-----------------------------------------------------
const doAddSysUserCompany = (params) => postAction(base.UserCompanyApi + 'doAddSysUserCompany', params)// 新增

const doGetSysUserCompanyList = (userId, companyId) => postActionQs(base.UserCompanyApi + 'doGetSysUserCompanyList', {
    userId: userId, // 用户id
    companyId: companyId // 公司id
})// 获取用户授权公司列表

const doDelSysUserCompany = (ids) => postActionQs(base.UserCompanyApi + 'doDelSysUserCompany', ids)// 删除

const doGetClickSysUserCompany = () => postActionQs(base.UserCompanyApi + 'doGetClickSysUserCompany')// 获取点选授权公司
// ----------------------------------------------------用户授权公司end-------------------------------------------------------
// 用户授权公司
export default {
    login,
    doGetUserList,
    doAddUser,
    doUpdateUser,
    getUsersList,
    doUpdatePass,
    doGetUserListNoAuth,
    doAddUserAuthData,
    doDeleteUserAuthData,
    doGetUserAuthDataList,
    doAddUserAuthDataRegion,
    doGetUserAuthDataRegionList,
    doDeleteUserAuthDataRegion,
    doAddSysUserCompany,
    doGetSysUserCompanyList,
    doDelSysUserCompany,
    doGetClickSysUserCompany
}
