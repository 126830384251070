import axios from './http'
import qs from 'qs'

/**
* get方法，对应get请求
* @param {String} url [请求的url地址]
* @param {Object} params [请求时携带的参数] {id:90,age:80} ==> id=90&age=80
*/
export function getAction(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}
/**
* get方法，对应get请求
* @param {String} url [请求的url地址]
* @param {Object} params [请求时携带的参数] {id:90,age:80} ==> id=90&age=80
*/
export function getActionContent(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            responseType: 'blob' // 设置返回类型
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err)
        })
    });
}
/***********************/
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function postAction(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function postActionQs(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, qs.stringify(params), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/**
*  @param {String} url [请求的url地址]
*  @param {Object} params [请求时携带的参数]
*/
export function deleteAction(url, params) {
    return new Promise((resolve, reject) => {
        axios.delete(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/**
*  @param {String} url [请求的url地址]
*  @param {Object} params [请求时携带的参数]
*/
export function putAction(url, params) {
    return new Promise((resolve, reject) => {
        axios.put(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

export function downFile(url, parameter) {
    return axios({
        url: url,
        params: parameter,
        method: 'get',
        responseType: 'blob'
    })
}
