<template>
  <a-modal v-model="editVisible" :title="modalTitle" ok-text="保存" cancel-text="关闭" @ok="saveModelData">
    <a-form-model class="editFormList" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
      :wrapper-col="wrapperCol">
      <a-form-model-item label="编号" prop="id" v-if="modalTitle === '编辑类别'">
        <a-input v-model="form.id" readOnly />
      </a-form-model-item>
      <a-form-model-item label="类别名称" prop="dictName">
        <a-input v-model="form.dictName" allowClear oninput="value=value.replace(/\s/g,'')"/>
      </a-form-model-item>
      <a-form-model-item label="是否禁用" prop="disable">
        <a-radio-group v-model="form.disable">
          <a-radio :value="1">
            是
          </a-radio>
          <a-radio :value="0">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
export default {
  name: 'editFormList',
  props: ['modalTitle'],
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      editVisible: false,
      form: {
        dictName: '',
        disable: 0
      },
      rules: {
        dictName: [{ required: true, message: '类别名称', trigger: 'blur' }],
        disable: [
          { required: true, message: '请选择是否禁用', trigger: 'change' }
        ]
      }
    };
  },
  created() {
  },
  watch: {
    // 去掉表单验证信息二次打开残留
    editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
      // 监视dialog状态（打开、关闭）
      if (val) {
        try {
          this.$refs.ruleForm.resetFields() // 重置编辑表单
          // addForm 为ref指向的表单
        } catch (e) { }
      }
    }
  },
  methods: {
    getFormData(val) {
      this.editVisible = true
      this.form = val
    },
    saveModelData() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.modalTitle === '新增') {
            this.$category.doAddDict(this.form.dictName, this.form.disable).then((res) => {
              this.$message.success('新增成功')
              this.editVisible = false
              this.$emit('updateList')
            })
          }
          if (this.modalTitle === '修改') {
            this.$category.doUpdateDict(this.form.id, this.form.dictName, this.form.disable).then((res) => {
              this.$message.success('修改成功')
              this.editVisible = false
              this.$emit('updateList')
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>
<style scoped lang="less">
@deep: ~'>>>';
</style>
