<!-- 角色管理 -->
<template>
    <div class="public-bg category-type">
        <JsglFormList @formData="getFormData" @add="addCompanyType"></JsglFormList>
        <Table :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type='jsgl' ref='tableRef'
            @edit="updateData" @delete="getFormData" @update="doUpdateRole" @roleauth="onRoleAuth"></Table>
        <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange"
            ref="paginationRef"></APagination>
        <JsglFormModelList ref="jsgltRef" :modalTitle="modalTitle" @updateList='getFormData'></JsglFormModelList>
        <AddAuth ref="addauth"></AddAuth>
    </div>
</template>
<script>
import JsglFormList from '@/components/DictionaryData/RoleManage/FormList/JsglFormList/index'
import JsglFormModelList from '@/components/DictionaryData/RoleManage/Modal/JsglFormModelList/index'
import AddAuth from '@/components/DictionaryData/RoleManage/FunAuth/AddAuth/index'
import Table from '@/components/Table'
import APagination from '@/components/Pagination/index'
export default {
    name: 'category-type',
    components: { JsglFormList, Table, APagination, JsglFormModelList, AddAuth },
    data() {
        const columns = [
            {
                title: '编号',
                width: '80px',
                dataIndex: 'id',
                fixed: 'left'
            },
            {
                title: '角色名称',
                dataIndex: 'roleName',
                width: '150px',
                fixed: 'left'
            },
            {
                title: '角色代码',
                dataIndex: 'roleCode',
                width: '8%'
            },
            {
                title: '授权登录后台',
                dataIndex: 'authLoginPc',
                scopedSlots: {
                    customRender: 'authLoginPc'
                },
                width: '8%'
            },
            {
                title: '备注',
                dataIndex: 'remark',
                scopedSlots: { customRender: 'rulemsg' },
                width: '15%'
            },
            {
                title: '授权角色',
                dataIndex: 'action',
                scopedSlots: { customRender: 'rolemsgauth' },
                width: '6%'
            },
            {
                title: '是否禁用',
                dataIndex: 'disable',
                scopedSlots: {
                    customRender: 'rolesmsOffon'
                },
                width: '6%'
            },
            {
                title: '创建人',
                dataIndex: 'createBy',
                width: '6%'
            }, {
                title: '创建时间',
                dataIndex: 'createTime',
                width: '11%'
            }, {
                title: '更新人',
                dataIndex: 'updateBy',
                width: '6%'
            }, {
                title: '更新时间',
                dataIndex: 'updateTime',
                width: '11%'
            }, {
                title: '操作IP',
                dataIndex: 'operateIp',
                width: '8%'
            },
            {
                title: '操作',
                dataIndex: 'operation',
                width: '100px',
                scopedSlots: { customRender: 'jsgl' },
                fixed: 'right'
            }
        ];
        return {
            columns,
            modalTitle: '',
            pageInfo: {
                page: '',
                limit: ''
            },
            scroll: {
                x: 1600,
                y: 500
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            }
        }
    },
    methods: {
        // 查询表格
        getFormData(data) {
            if (data) {
                this.pageInfo = data
                this.$refs.paginationRef.onChange(1, 10)
                return
            }
            this.$refs.tableRef.getList(this.pageInfo)
        },
        updateData(row) {
            this.modalTitle = '修改'
            let obj = { ...row }
            this.$refs.jsgltRef.getFormData(obj)
        },
        addCompanyType() {
            this.modalTitle = '新增'
            this.$refs.jsgltRef.getFormData()
        },
        doUpdateRole(val) {
            this.$rolemanage.doUpdateRole(val.row).then(res => {
                this.$message.success('修改成功')
            })
        },
        // 角色授权
        onRoleAuth(row) {
            this.$refs.addauth.getFormData(row)
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.$refs.tableRef.getList(this.pageInfo)
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        }
    }
}
</script>
