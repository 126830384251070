<template>
  <a-modal v-model="editVisible" :title="modalTitle" ok-text="保存" cancel-text="关闭" @ok="saveModelData">
    <div class="fixed-mask" v-if="fetching">
      <a-spin size="large" tip="保存中..." />
    </div>
    <a-form-model class="editFormList" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
      :wrapper-col="wrapperCol">
      <a-form-model-item label="编号" prop="id" v-if="modalTitle === '修改'">
        <a-input v-model="form.id" readOnly />
      </a-form-model-item>
      <a-form-model-item label="角色名称" prop="roleName">
        <a-input v-model="form.roleName" oninput="value=value.replace(/\s/g,'')" />
      </a-form-model-item>
      <a-form-model-item label="角色代码" prop="roleCode">
        <a-input v-model="form.roleCode" placeholder="只能输入大写字母" oninput="value=value.replace(/\s/g,'')" />
      </a-form-model-item>
      <a-form-model-item label="授权登录后台" prop="authLoginPc">
        <a-radio-group v-model="form.authLoginPc">
          <a-radio :value="0">
            未授权
          </a-radio>
          <a-radio :value="1">
            已授权
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否禁用" prop="disable">
        <a-radio-group v-model="form.disable">
          <a-radio :value="1">
            禁用
          </a-radio>
          <a-radio :value="0">
            启用
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" oninput="value=value.replace(/\s/g,'')" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import Busscommutil from '../../../../../utils/busscommutil'
export default {
  name: 'editFormList',
  props: ['modalTitle'],
  data() {
    // 角色代码
    let validatorRoleCode = (rule, value, callback) => {
      const reg = /^[A-Z]+$/
      if (Busscommutil.checkNull(value)) {
        if (!reg.test(value)) {
          return callback(new Error('只能输入大写字母'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    }
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      editVisible: false,
      form: {
      },
      rules: {
        roleName: [{ required: true, message: '请填写角色名称', trigger: 'blur' }],
        remark: [{ required: true, message: '请填写备注', trigger: 'blur' }],
        roleCode: [{ required: false, message: '请填写角色代码', trigger: 'blur' }, { validator: validatorRoleCode, trigger: 'blur' }],
        authLoginPc: [
          { required: true, message: '请选择是否授权登录后台', trigger: 'change' }
        ],
        disable: [
          { required: true, message: '请选择是否禁用', trigger: 'change' }
        ]
      },
      fetching: false
    };
  },
  created() {
  },
  watch: {
    // 去掉表单验证信息二次打开残留
    editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
      // 监视dialog状态（打开、关闭）
      if (val) {
        try {
          this.$refs.ruleForm.resetFields() // 重置编辑表单
          // addForm 为ref指向的表单
        } catch (e) { }
      }
    }
  },
  methods: {
    getFormData(val) {
      this.editVisible = true
      if (!val) {
        if (this.$refs.ruleForm !== undefined) {
          this.$refs.ruleForm.resetFields()
        }
        this.form = {}
        return
      }
      this.form = val
    },
    saveModelData() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.fetching = true
          if (this.modalTitle === '新增') {
            this.fetching = false
            this.$rolemanage.doAddRole(this.form).then(res => {
              this.$message.success('新增成功')
              this.editVisible = false
              this.$emit('updateList')
            })
          }
          if (this.modalTitle === '修改') {
            this.fetching = false
            this.$rolemanage.doUpdateRole(this.form).then(res => {
              this.$message.success('修改成功')
              this.editVisible = false
              this.$emit('updateList')
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>
<style scoped lang="less">
@deep: ~'>>>';
</style>
