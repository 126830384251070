import { getAction, deleteAction, putAction, postAction, postActionQs, httpAction } from '@/api/manage'
import Vue from 'vue'

/**
  * 接口域名的管理
  */
const base = {
  permissionApi: '/api/v1/manager/PermissionApi/' // 权限管理
}
// 权限管理
const doAddUserDataAuth = (params) => postActionQs(base.permissionApi + 'doAddUserDataAuth', params)// 新增数据权限
const doUpdateUserDataAuth = (params) => postActionQs(base.permissionApi + 'doUpdateUserDataAuth', params)// 更新数据权限
const doDeleteBatchUserDataAuth = (params) => postActionQs(base.permissionApi + 'doDeleteBatchUserDataAuth', params)// 删除数据权限
const doGetUserDataAuthList = (params) => postActionQs(base.permissionApi + 'doGetUserDataAuthList', params)// 获取数据权限

export default {
  doDeleteBatchUserDataAuth,
  doAddUserDataAuth,
  doUpdateUserDataAuth,
  doGetUserDataAuthList
}
