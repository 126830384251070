import { getAction, deleteAction, putAction, postAction, postActionQs, httpAction } from '@/api/manage'
import Vue from 'vue'

/**
  * 接口域名的管理
  */
const base = {
  FldmApi: '/api/v1/manager/FldmApi/', // 分类字典
  commapi: '/api/v1/ComAPI/' // 公共方法
}

// 分类字典
const doGetFldmList = (tLbbm) => postActionQs(base.FldmApi + 'getFldmList', {
  tLbbm: tLbbm
})

// 获取验证规则
const doGetFieldCheckRule = (params) => postActionQs(base.commapi + 'doGetFieldCheckRule', {
  companyId: params
})
// 获取公司信息
const doGetCompanyInfo = (company) => postActionQs('/api/v1/GsAPI/doGetCompanyInfo', {
  id: company
})

// 根据公司编号获取人员信息
const doGetUserListByCompany = (companyid, keyword) => postActionQs('/api/v1/UserApi/doGetUserListByCompany', {
  companyid: companyid,
  keyword: keyword
})
export default {
  doGetFldmList,
  doGetFieldCheckRule,
  doGetCompanyInfo,
  doGetUserListByCompany
}
