<template>
    <div class="serve-home public-bg">
        <div v-if='isCommit' class="fixed-mask">
            <a-spin tip="加载中..." size="large">
            </a-spin>
        </div>
        <a-col :span="4">
            <p class="height-120">col-4</p>
        </a-col>
    </div>
</template>
<script>
import { Icon, Empty } from 'ant-design-vue';

import Busscommutil from '../../utils/busscommutil'
const IconFont = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/c/font_3739120_xbnortbop9f.js'
});
export default {
    name: 'serve-home',
    components: {
    },
    beforeCreate() {
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    },
    data() {
        return {
            user: null,
            isCommit: false // 遮罩
        }
    },
    mounted() {
    },
    created() {
        this.user = this.$store.state.userInfo
        if (this.user.roleid === 99) {
            this.getCompanyList()
            this.getAllCompanyList()
        }
    },
    methods: {
        //  获取公司列表
        getCompanyList() {
            let companylist = Busscommutil.checkNull(this.$store.state.companylist)
            if (companylist) {
            } else {
                this.$company.doGetCompanyByDisable('', 0, '').then((res) => {
                    this.$store.commit('saveCompanyList', res.data);
                }).catch(() => { })
            }
        },
        //  获取全部公司列表（包含禁用）
        getAllCompanyList() {
            let companyalllist = Busscommutil.checkNull(this.$store.state.companyalllist)
            if (companyalllist) {
            } else {
                this.$company.getAllCompanyList().then((res) => {
                    this.$store.commit('saveCompanyAllList', res.data);
                }).catch(() => { })
            }
        }
    }
}
</script>
<style scoped lang="less">
.serve-home {
    min-height: calc(100% - 30px);

    .content {
        display: flex;
        align-items: flex-start;
    }

    .home-left {
        width: 48%;
        border: 1px solid #e8e8e8;
        box-sizing: border-box;
        box-shadow: -6px 6px 5px rgba(189, 202, 235, .3);
        border-radius: 8px;

        .title {
            font-size: 16px;
            color: #333;
            border-bottom: 1px solid #e8e8e8;
            padding: 16px;

            .anticon {
                font-size: 20px;
                margin: 0 5px 0 0;
            }
        }

        ul {
            display: flex;
            padding: 16px;

            li {
                width: 25%;
                font-size: 14px;
                text-align: center;
                margin: 6px 0;

                span {
                    display: block;
                    color: #333;
                    line-height: 30px;
                }

                .anticon {
                    font-size: 36px;
                }
            }
        }
    }

    .ant-empty {
        margin: 0;
    }

    .home-right {
        width: 49%;
        border: 1px solid #e8e8e8;
        box-sizing: border-box;
        box-shadow: -6px 6px 5px rgba(189, 202, 235, .3);
        border-radius: 8px;

        .title {
            font-size: 16px;
            color: #333;
            border-bottom: 1px solid #e8e8e8;
            padding: 16px;

            .anticon {
                font-size: 20px;
                margin: 0 5px 0 0;
            }
        }

        ul {
            padding: 0 16px;
            box-sizing: border-box;

            li {
                border-bottom: 1px dashed #e8e8e8;
                font-size: 14px;
                color: #333;
                line-height: 36px;
                cursor: pointer;

                span {
                    float: right;
                    color: #1F8839;
                }

                &:hover {
                    color: #1F8839;
                }

                .empty-li {
                    &:hover {
                        color: rgb(0 0 0 / 25%);
                    }
                }
            }
        }
    }
}
</style>
