<template>
    <div class="page">
        <div class="fixed-mask" v-if="fetching">
            <a-spin size="large" />
        </div>
        <div class="flex-center icon-bind" v-if="reBindAccounts">
            <icon-font type="icon-yibangding"/>
        </div>
        <div class="title">账号绑定</div>
        <a-form-model :model="formInline" :rules="rules" ref="bindFormRef" @submit="doWxMpBindAccount"
            @submit.native.prevent>
            <a-form-model-item prop="mobile">
                <a-input v-model="formInline.mobile" placeholder="请输入手机号" class="user" allowClear />
            </a-form-model-item>
            <a-form-model-item prop="password">
                <a-input-password v-model="formInline.password" placeholder="请输入密码" class="password" allowClear/>
            </a-form-model-item>
            <a-form-model-item>
                <a-button type="primary" block @click="doWxMpBindAccount" ref='loadRef' v-if="reBindAccounts === false">
                    绑定账号
                </a-button>
                <a-button type="danger" block @click="doRemoveWxMpBindAccount" ref='loadRef' v-if="reBindAccounts">
                    解绑账号
                </a-button>
            </a-form-model-item>
        </a-form-model>
        <a-modal title="提示" :visible="visible" @ok="handleOk" @cancel="cancel" style="width: 80%;margin: 0 auto">
            <a-result status="success" :title="title" style="padding-top: 50px" />
        </a-modal>
    </div>
</template>
<script>
import { Icon } from 'ant-design-vue';
const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_3739120_u7iqxbmfgj.js'
});
export default {
    name: 'Home',
    components: {
        IconFont
    },
    data() {
        return {
            code: '',
            token: null,
            user: null,
            formInline: {
                mobile: '',
                password: '',
                openId: ''
            },
            rules: {
                mobile: [
                    { required: true, message: '请输入手机号!' }
                ],
                password: [{
                    required: true, message: '请输入密码!', validator: 'click'
                }]
            },
            fetching: false,
            visible: false,
            reBindAccounts: null, // 是否绑定账号
            title: '',
            openId: null
        }
    },
    created() {
        document.title = '零星物资'
        let userInfo = JSON.parse(window.sessionStorage.getItem('user'))
        if (userInfo) {
            this.user = userInfo
            this.token = window.sessionStorage.getItem('token')
        }
        let _this = this
        document.onkeydown = function (e) {
            var key = window.event.keyCode
            if (key === 13) {
                _this.handleSubmit()
            }
        }
        this.getCode()
    },
    watch: {
    },
    methods: {
        getCode() { // 非静默授权，第一次有弹框
            this.code = ''
            var local = window.location.href // 获取页面url
            var appid = 'wx0882278afa8f854f'
            this.code = this.getUrlCode().code // 截取code
            this.fetching = true
            if (this.code === null || this.code === '' || this.code === undefined) { // 如果没有code，则去请求
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123&connect_redirect=1#wechat_redirect`
            } else {
                this.doGetWxMpOpenId(this.code)
            }
        },
        getUrlCode() { // 截取url中的code方法
            var url = location.search
            this.winUrl = url
            // eslint-disable-next-line no-new-object
            var theRequest = new Object()
            if (url.indexOf('?') !== -1) {
                var str = url.substr(1)
                var strs = str.split('&')
                for (var i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1])
                }
            }
            return theRequest
        },
        // 获取openId
        doGetWxMpOpenId(code) {
            this.$official.doGetWxMpOpenId(code).then(res => {
                this.openId = res.data
                this.doCheckWxMpBindAccount(res.data)
            }).catch(() => {
            })
        },
        // 判断账号是否绑定
        doCheckWxMpBindAccount(openId) {
            this.$official.doCheckWxMpBindAccount(openId).then(res => {
                this.fetching = false
                this.reBindAccounts = res.data
            }).catch(() => {
                this.fetching = false
            })
        },
        // 绑定账号
        doWxMpBindAccount() {
            this.$refs.bindFormRef.validate(valid => {
                if (valid) {
                    if (this.openId === null || this.openId === '' || this.openId === undefined) {
                        this.$message.error('openId不能为空，请重新进入页面')
                        return false
                    }
                    this.formInline.openId = this.openId
                    this.fetching = true
                    this.$official.doWxMpBindAccount(this.formInline).then(res => {
                        this.fetching = false
                        this.visible = true
                        this.title = '绑定成功'
                    }).catch(() => {
                        this.fetching = false
                    })
                }
            })
        },
        // 解绑账号
        doRemoveWxMpBindAccount() {
            this.$refs.bindFormRef.validate(valid => {
                if (valid) {
                    if (this.openId === null || this.openId === '' || this.openId === undefined) {
                        this.$message.error('openId不能为空，请重新进入页面')
                        return false
                    }
                    this.formInline.openId = this.openId
                    this.fetching = true
                    this.$official.doRemoveWxMpBindAccount(this.formInline).then(res => {
                        this.fetching = false
                        this.visible = true
                        this.title = '解绑成功'
                    }).catch(() => {
                        this.fetching = false
                    })
                }
            })
        },
        handleOk() {
            this.visible = false
            this.doCheckWxMpBindAccount(this.openId)
        },
        cancel() {
            this.visible = false
            this.doCheckWxMpBindAccount(this.openId)
        }
    }
}
</script>
<style scoped lang='less'>
@deep: ~'>>>';

.page {
    background: #f8f8f8;
    overflow: hidden;
    padding: 16px 10%;
    position: fixed;
    width: 100%;
    height: 100%;

    .title {
        margin-top: 20%;
        font-size: 16px;
        color: #333;
        line-height: 30px;
        margin-bottom: 20px;
    }

    @{deep}.user .ant-input,
    @{deep}.password .ant-input {
        border-radius: 20px;
        padding: 20px 16px;
        margin-bottom: 20px;
    }

    @{deep}.ant-input-affix-wrapper .ant-input-suffix {
        top: 34% !important
    }

    .ant-btn {
        margin-top: 30px;
        height: 40px;
    }
}
// 已绑定提示
@{deep}.icon-bind .anticon {
  font-size: 100px;
  color: #1F8839;
}
@{deep}.ant-result-icon>.anticon {
    font-size: 60px;
}

@{deep}.ant-result-title {
    font-size: 16px;
}

@{deep}.ant-modal {
    max-width: 80% !important;
}
</style>
