<template>
    <div>
        <a-modal ref="ruleForm" v-model="editVisible" :title="title" cancel-text="关闭" :bodyStyle='bodyStyle'
            :footer="null" :destroyOnClose="true" @cancel="onClose">
            <div v-if='isCommit' class="fixed-mask">
                <a-spin tip="保存中...">
                </a-spin>
            </div>
            <a-row>
                <a-col :span="12" style="border-right: 1px solid #eee;padding: 0 15px 0 0;">
                    <a-form :form="pageInfo" class="form" v-bind="layout">
                        <a-row class="form-row" :gutter="24">
                            <a-col :xxl="12" :xl="12" :lg="24" :md="24" :sm="24">
                                <a-form-item label="菜单名称">
                                    <a-input placeholder="菜单名称" v-model="pageInfo.menuName" allowClear />
                                </a-form-item>
                            </a-col>
                            <a-col :xxl="12" :xl="12" :lg="24" :md="24" :sm="24">
                                <a-form-item label="父级名称">
                                    <a-input placeholder="父级名称" v-model="pageInfo.parentName" allowClear />
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col style="margin-bottom: 20px;float: right">
                                <a-button type="primary" @click="addRoleAuthFun"
                                    style="margin-right: 10px;">授权</a-button>
                                <a-button type="primary" @click="doGetPCMenuList">查询</a-button>
                            </a-col>
                        </a-row>
                    </a-form>
                    <div class="menu-title"><a-icon type="appstore"
                            :style="{ fontSize: '16px', color: '#1F8839', paddingRight: '5px' }" />全部菜单</div>
                    <div class="menu-content">
                        <a-tree v-model="checkedKeys" :replaceFields="{ title: 'menuName', key: 'id' }" checkable
                            :expanded-keys="expandedKeys" :selected-keys="selectedKeys" :tree-data="menuList"
                            @expand="onExpand" @select="onSelect" @check="onCheck" />
                    </div>
                </a-col>
                <a-col :span="12" style="padding: 0 0 0 15px;">
                    <a-form :form="menucheckedInfo" class="form" v-bind="layout">
                        <a-row class="form-row" :gutter="24">
                            <a-col :xxl="12" :xl="12" :lg="24" :md="24" :sm="24">
                                <a-form-item label="菜单名称">
                                    <a-input placeholder="菜单名称" v-model="menucheckedInfo.menuName" allowClear />
                                </a-form-item>
                            </a-col>
                            <a-col :xxl="12" :xl="12" :lg="24" :md="24" :sm="24">
                                <a-form-item label="父级名称">
                                    <a-input placeholder="父级名称" v-model="menucheckedInfo.parentName" allowClear />
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col style="margin-bottom: 20px;float: right">
                                <a-button type="primary" @click="doDeletePCMenuAuth"
                                    style="margin-right: 10px;">取消授权</a-button>
                                <a-button type="primary" @click="doGetPCMenuAuthList">查询</a-button>
                            </a-col>
                        </a-row>
                    </a-form>
                    <div class="menu-title"><a-icon type="appstore" theme="filled"
                            :style="{ fontSize: '16px', color: '#1F8839', paddingRight: '5px' }" />已授权菜单</div>
                    <div class="menu-content">
                        <a-tree v-model="checkedrightKeys" :replaceFields="{ title: 'menuName', key: 'authMenuId' }"
                            checkable :expanded-keys="expandedCheckedKeys" :tree-data="menuCheckedList"
                            @expand="onCheckedExpand" @check="onRightCheck" v-if="menuCheckedList.length > 0" />
                        <a-empty :image="simpleImage" description="暂无授权菜单" v-if="menuCheckedList.length <= 0"
                            style="margin-top: 6%;" />
                    </div>
                </a-col>
            </a-row>
        </a-modal>
    </div>
</template>
<script>
import { Empty } from 'ant-design-vue';
export default {
    beforeCreate() {
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    },
    data() {
        return {
            editVisible: false,
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            bodyStyle: {
                height: '600px',
                overflowY: 'scroll'
            },
            pageInfo: {
                menuName: '',
                parentName: ''
            },
            menucheckedInfo: {
                menuName: '',
                parentName: '',
                companyRoleId: ''
            },
            checkedKeys: [], // 已选中的节点
            expandedKeys: [], // 展开的节点
            selectedKeys: [],
            savecheckedKeys: [], // 保存的已选中的节点id
            expandedCheckedKeys: [], // 右侧已选中节点展开
            checkedrightKeys: [], // 右侧已选中节点选择
            menuList: [],
            menuCheckedList: [],
            isCommit: false,
            title: ''
        }
    },
    watch: {
        // 去掉表单验证信息二次打开残留
        editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
            // 监视dialog状态（打开、关闭）
            if (val) {
                try {
                    this.checkedKeys = []
                    this.expandedKeys = []
                    this.checkedrightKeys = []
                    this.expandedCheckedKeys = []
                    this.savecheckedKeys = []
                    this.$refs.ruleForm.resetFields() // 重置编辑表单
                    // addForm 为ref指向的表单
                } catch (e) { }
            }
        }
    },
    methods: {
        getFormData(row) {
            this.editVisible = true
            this.menucheckedInfo.companyRoleId = row.id // 关联公司角色ID
            this.doGetPCMenuList()
            this.doGetPCMenuAuthList()
            this.title = '菜单授权' + '（' + row.companyName + '-' + row.roleName + '）'
        },
        // 获取菜单数据
        doGetPCMenuList() {
            this.isCommit = true
            this.$pcmenu.doGetPcMenuList(this.pageInfo).then(res => {
                this.isCommit = false
                this.menuList = res.data
            })
        },
        // 获取授权功能列表
        doGetPCMenuAuthList() {
            this.isCommit = true
            this.$pcmenu.doGetPcMenuAuthList(this.menucheckedInfo).then(res => {
                this.isCommit = false
                this.menuCheckedList = res.data
            })
        },
        // -------------------------------左侧全部菜单start----------------------------
        // 展开、关闭节点触发
        onExpand(expandedKeys) {
            this.expandedKeys = expandedKeys
        },
        onCheck(checkedKeys, e) {
            let currentId = e.node.eventKey // 当前节点id
            this.checkedKeys = checkedKeys // 选中的id
            this.doGetPCMenuAuthParent(currentId, checkedKeys)
        },
        onSelect(checkedKeys, e) {
            // this.checkedKeys = checkedKeys // 选中的id
            // otherChecked.forEach(otheritem => {
            //     const existingItem = this.savecheckedKeys.find(item => otheritem === item) // 筛选出两个数组中不同的数据
            //     if (!existingItem) {
            //         this.savecheckedKeys.push(otheritem)
            //     }
            // })
        },
        // 获取父级id
        doGetPCMenuAuthParent(id, checkedKeys) {
            this.savecheckedKeys = { ...checkedKeys } // 保存选中的id
            this.savecheckedKeys = Object.values(this.savecheckedKeys)
            let newChecked = Object.values(this.savecheckedKeys)
            newChecked.forEach(id => { // 每一个选中的节点都获取父级id
                this.$pcmenu.doGetPcMenuAuthParent(id).then(res => {
                    let parentId = res.data // 父级id
                    parentId.forEach(otheritem => {
                        const existingItem = this.savecheckedKeys.find(item => otheritem === item) // 筛选出两个数组中不同的数据
                        if (!existingItem) {
                            this.savecheckedKeys.push(otheritem)
                        }
                    })
                })
            })
        },
        // -------------------------------左侧全部菜单end----------------------------
        // -------------------------------左侧全部菜单start----------------------------
        // 展开、关闭节点触发
        onCheckedExpand(expandedKeys) {
            this.expandedCheckedKeys = expandedKeys
        },
        onRightCheck(checkedKeys) {
            this.checkedrightKeys = checkedKeys
        },
        // 取消选中菜单
        doDeletePCMenuAuth() {
            if (this.checkedrightKeys.length <= 0) {
                this.$message.error('请选择要取消授权的菜单')
                return false
            }
            let value = JSON.stringify({
                ids: this.checkedrightKeys
            })
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$pcmenu.doDeletePcMenuAuth({
                        ids: value
                    }).then(res => {
                        if (res.status === 200) {
                            this.doGetPCMenuAuthList()
                            this.checkedrightKeys = []
                            this.$message.success('取消授权成功')
                        }
                    })
                }
            })
        },
        // -------------------------------左侧全部菜单end----------------------------
        // 新增角色授权
        addRoleAuthFun() {
            let arr = []
            if (this.savecheckedKeys.length > 0) {
                this.savecheckedKeys.forEach((item, index) => {
                    arr.push({
                        appMenuId: item, // app菜单ID
                        companyRoleId: this.menucheckedInfo.companyRoleId // 关联公司对应的角色ID
                    })
                    if (index === this.savecheckedKeys.length - 1) {
                        this.isCommit = true
                        this.$pcmenu.doAddPcMenuAuth(arr).then(res => {
                            this.isCommit = false
                            this.$message.success('授权成功')
                            this.doGetPCMenuAuthList()
                            this.checkedKeys = []
                            this.savecheckedKeys = []
                        }).catch(() => {
                            this.isCommit = false
                        })
                    }
                })
            } else {
                this.$message.error('请选择授权菜单')
            }
        },
        onClose() {
            this.editVisible = false
            this.menuCheckedList = []
            this.menucheckedInfo = {
                menuName: '',
                parentName: ''
            }
            this.pageInfo = {}
        }
    }
}
</script>
<style lang="less" scoped>
@deep: ~'>>>';

@{deep} {
    .ant-modal {
        width: 70% !important;

        .menu-content {
            height: 400px;
            overflow-y: scroll;
            // ::-webkit-scrollbar {
            //     width: 10px;
            // }
        }

        .menu-title {
            font-size: 16px;
            line-height: 30px;
        }
    }
}
</style>
