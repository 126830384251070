// import { parse } from "qs";

const sessionutil = {
    setSession(key, hh) { // key=userInfo value=2 maxAge='' || 可自行设置
        // const maxAgeTime = new Date().getTime() + 1000 * 3600; // 当前时间的+1小时 session 过期时间
        // try {
        //     let data = { value, maxAge: maxAge || maxAgeTime }
        //     sessionStorage.setItem(typeof key === 'string' ? key : JSON.stringify(key), JSON.stringify(data))
        // } catch (err) {
        // }
        const maxTime = Date.parse(new Date()) + 1000 * 3600 * hh;
        try {
            window.sessionStorage.setItem(key, maxTime)
        } catch (err) {}
    },
    getSession(key) {
        // try {
        //     const maxAgeTime = new Date().getTime() + 1000 * 3600; // 首先先设置一个 session 过期时间 1H后可自行设置
        //     let date = new Date().getTime(); // 当前时间
        //     let session = JSON.parse(sessionStorage.getItem(typeof key === 'string' ? key : JSON.stringify(key)));
        //     if (session && session.maxAge != null && session.maxAge - date > 0 && session.maxAge <= maxAgeTime) {
        //         this.setSession(key, session.value);
        //         return session.value;
        //     } else {
        //         return null;
        //     }
        // } catch (err) {
        // }
        try {
            let date = Date.parse(new Date()); // 当前时间
            let sessionTime = window.sessionStorage.getItem(key)
            if (sessionTime >= date) {
                return sessionTime
            } else {
                return null
            }
        } catch (err) {}
    }
}
export default sessionutil
