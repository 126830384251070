<!-- 公告管理 -->
<template>
    <div class="dxxj public-bg">
        <NoticeFormList @formData="getFormData" @add='add'></NoticeFormList>
        <ATable :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type="notice" @edit='edit' @delete='getFormData' ref="tableRef"></ATable>
        <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange" ref="paginationRef"></APagination>
        <NoticeFormModelList @update='getFormData' ref='noticeRef'></NoticeFormModelList>
    </div>
</template>
<script>
    import { getAction, postActionQs } from '../../../api/manage.js'
    import Busscommutil from '../../../utils/busscommutil'
    import NoticeFormList from '@/components/FormList/NoticeFormList/index'
    import NoticeFormModelList from '@/components/FormListModel/NoticeFormModelList/index'
    import APagination from '@/components/Pagination/index'
    import ATable from '@/components/Table/index'
    import store from '../../../store'
    const columns = [
    {
        title: '编号',
        dataIndex: 'tBh'
    },
    {
        title: '公告内容',
        dataIndex: 'tGgnr',
        width: '60%',
        ellipsis: true
    },
    {
        title: '是否展示',
        dataIndex: 'tSfzs'
    },
    {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'notice' }
    }];
    export default {
        data() {
            return {
                dxxjData: [],
                columns,
                pageInfo: {
                    page: '',
                    limit: '',
                    tGgnr: ''
                },
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                    pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                    showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
                },
                scroll: {
                    y: 500
                }
            }
        },
        components: {
            NoticeFormList, APagination, ATable, NoticeFormModelList
        },
        created() {
        },
        methods: {
           // 查询表格数据
            getFormData(data) {
                if (data) {
                    this.pageInfo = data
                    this.$refs.paginationRef.onChange(1, 10)
                    return
                }
                this.$refs.tableRef.getList(this.pageInfo)
            },
            add() {
                let row = {
                    tGgnr: ''
                }
                let val = { ...row }
                this.$refs.noticeRef.getFormData(val, '新增')
            },
            edit(row) {
                let val = { ...row }
                this.$refs.noticeRef.getFormData(val, '修改')
            },
            // 获取分页
            getShowSizeChange(page) {
                this.handleTableChange(page)
            },
            handleTableChange(pagination) {
                this.$refs.tableRef.getList(this.pageInfo)
            },
            onPageSizeChange(page) {
                this.handleTableChange(page)
            },
            onReCancel() {
               this.$refs.tableRef.getList()
            }
        }
    }
</script>
<style scoped lang="less">
    @deep: ~'>>>';
</style>
