<template>
<a-modal v-model="editVisible" :title="title" ok-text="保存" cancel-text="关闭" @ok="saveModelData" :bodyStyle='bodyStyle'>
  <a-form-model
    class="editFormList"
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
     <a-form-model-item label="公告内容" prop="tGgnr">
      <a-input
        v-model="form.tGgnr" type='textarea' :autoSize ='autosize2'/>
    </a-form-model-item>
    <a-form-model-item label="是否展示">
       <a-radio-group v-model="form.tSfzs">
        <a-radio :value="1">
          是
        </a-radio>
        <a-radio :value="0">
          否
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
  </a-form-model>
</a-modal>
</template>
<script>
import { getAction, postAction, postActionQs } from '../../../api/manage.js'
import Busscommutil from '../../../utils/busscommutil'
export default {
  name: 'editFormList',
  props: ['type'],
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      editVisible: false,
      form: {},
      rules: {
        tGgnr: [{ required: true, message: '请填写公告内容', trigger: 'blur' }]
      },
      bodyStyle: {
          height: '300px',
          overflowY: 'scroll'
      },
      title: '',
      autosize2: { minRows: 6 }
    };
  },
   watch: {
    // 去掉表单验证信息二次打开残留
    editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
        // 监视dialog状态（打开、关闭）
        if (val) {
            try {
                this.$refs.ruleForm.resetFields() // 重置编辑表单
                // addForm 为ref指向的表单
            } catch (e) {}
        }
    }
  },
  created() {
    this.form.tGlgxm = this.$store.state.userInfo.username
  },
  methods: {
    getFormData(val, title) {
      this.editVisible = true
      this.form = val
      this.title = title
      if (title === '修改') {
        val.tSfzs = Busscommutil.reverseTransIsShow(val.tSfzs)
      }
    },
    saveModelData() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            if (this.title === '新增') {
              postActionQs('/api/v1/admin/BasicDataApi/addGgnr', this.form).then((res) => {
                if (res.status === 200) {
                  this.$message.success('新增成功')
                  this.editVisible = false
                  this.$emit('update')
                }
              })
            }
            if (this.title === '修改') {
              postActionQs('/api/v1/admin/BasicDataApi/updateGgnr', this.form).then((res) => {
                if (res.status === 200) {
                  this.$message.success('新增成功')
                  this.editVisible = false
                  this.$emit('update')
                }
              })
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    }
  }
};
</script>
<style scoped lang="less">

  @deep: ~'>>>';
</style>
