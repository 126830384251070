<template>
    <div>
        <a-select v-model="prov" style="width: 169px" @change="changeCity,changeCity1" show-search :allowClear="!isAllowClear">
            <a-select-option v-for="(option) in areaOptions" :key="option.code" :value="option.value" :disabled="disabledProv">
                {{ option.value }}
            </a-select-option>
        </a-select>
        <a-select v-model="city" @change="changeCity1" style="width: 169px;margin-left:15px" show-search allowClear v-if="isCity">
            <a-select-option v-for="(option) in cityArr" :key="option.code" :value="option.value">
            {{ option.value }}
            </a-select-option>
        </a-select>
        <a-select v-model="county" style="width: 169px;margin-left:15px" @change="changeCity2" v-if="isCounty" show-search allowClear>
            <a-select-option v-for="(option) in countyArr" :key="option.code" :value="option.value">
                {{ option.value }}
            </a-select-option>
        </a-select>
    </div>
</template>
<script>
import areaJson from '../../assets/js/area.json'
import Busscommutil from '../../utils/busscommutil'
export default {
    props: ['isCounty', 'defaultProv', 'defaultCity', 'disabledProv', 'isCity', 'mode'],
    data() {
        return {
            arr: [],
            cityArr: [],
            countyArr: [],
            areaOptions: areaJson,
            prov: '',
            city: '',
            county: '',
            provCode: '',
            cityCode: '',
            countyCode: '',
            isAllowClear: false
        }
    },
    created() {
        if (this.defaultProv) {
            this.prov = this.defaultProv
        }
        if (this.defaultCity) {
            this.city = this.defaultCity
        }
        if (this.disabledProv) {
            this.isAllowClear = true
        }
        if (this.isCity === null) {
            this.isCity = true
        }
        if (this.mode === null) {
            this.mode = 'default'
        } else if (this.mode === 'multiple') {
            this.city = []
        }
        this.changeCity();
        this.changeCity1();
    },
    watch: {
        defaultProv: {
            handler: function () {
               this.prov = this.defaultProv
            },
            deep: true
        },
        defaultCity: {
            handler: function () {
               this.city = this.defaultCity
            },
            deep: true
        },
        prov: {
            handler: function () {
                // do something
                this.changeCity();
                this.changeCity1();
            },
            deep: true
        },
        city: {
            handler: function () {
                // do something
                this.changeCity1();
            },
            deep: true
        }
    },
    methods: {
        getCurrentSite() {
            let currentSite
            let currentCode
            if (this.prov === '') {
                currentSite = ''
                currentCode = ''
            } else if (this.city === '' || this.city === undefined) {
                currentSite = this.prov
                currentCode = this.provCode
            } else if (this.county === '' || this.county === undefined) {
                currentSite = this.prov + ' ' + this.city
                currentCode = this.provCode + ' ' + this.cityCode
            } else {
                currentSite = this.prov + ' ' + this.city + ' ' + this.county
                currentCode = this.provCode + ' ' + this.cityCode + ' ' + this.countyCode
            }
            this.$emit('site', currentSite)
            this.$emit('code', currentCode)
            this.$emit('province', {
                name: this.prov,
                code: this.provCode
            })
            this.$emit('cityArr', {
                name: this.city,
                code: this.cityCode
            })
            this.$emit('address', {
                tSf: this.prov,
                tDs: this.city
            })
        },
        changeCity() { // 省切换
            var me = this;
            var item
            me.areaOptions.forEach(function(ele) {
                if (ele.value === me.prov) { // 判断与prov是否相等，相等的表示被切换选中的省份
                    item = ele;
                    me.provCode = ele.code
                }
            })
            if (item) {
                this.cityArr = item.children;// 将选中的item的sub设置给cityArr 用于显示市
                // this.city = item.children[0].value;// 默认选择第一个市
                this.city = ''
                this.cityCode = ''
                this.countyArr = [];
                this.county = '';
                this.cityArr.forEach(city => {
                    if (this.defaultCity === city.value) {
                        this.city = this.defaultCity
                    }
                    // console.log(this.city)
                })
            }
            if (!this.prov) {
                this.provCode = ''
                this.city = ''
                this.cityCode = ''
                this.cityArr = []
                this.county = ''
                this.countyCode = ''
                this.countyArr = []
                this.$emit('updateDefaultCity')
                if (this.mode === 'multiple') {
                    this.city = []
                }
            }
            this.getCurrentSite()
        },
        changeCity1() { // 市切换
            var me = this;
            var item
            me.cityArr.forEach(function(ele) {
                if (ele.value === me.city) { // 判断与city是否相等，相等的表示被切换选中的市
                    item = ele;
                    me.cityCode = ele.code
                }
            })
            if (item) {
                this.countyArr = item.children; // 将选中的item的sub设置给cityArr1 用于显示区
                // this.county = item.children[0].value; // 默认选择第一个区
                this.county = ''; // 默认选择第一个区
                this.countyCode = ''; // 默认选择第一个区
            }
            if (!this.prov || !this.city) {
                this.county = ''
                this.countyCode = ''
                this.countyArr = []
            }
            this.getCurrentSite()
        },
        changeCity2() {
            var me = this;
            var item
            me.countyArr.forEach(function (ele) {
                if (ele.value === me.county) { // 判断与city是否相等，相等的表示被切换选中的市
                    item = ele;
                    me.countyCode = ele.code
                }
            })
            this.getCurrentSite()
        }
   }
}
</script>
