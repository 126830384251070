<template>
    <a-form @submit="handleSubmit" :form="repository" class="form" v-bind="layout">
        <a-row class="form-row" :gutter="24">
            <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
                <a-form-item label="公司名称">
                    <a-input v-model="repository.companyName" allowClear oninput="value=value.replace(/\s/g,'')"
                        placeholder="输入公司名称"></a-input>
                </a-form-item>
            </a-col>
            <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
                <a-form-model-item label="角色名称">
                    <a-select v-model="repository.roleId" placeholder="选择角色" allowClear showSearch
                        optionFilterProp="label">
                        <a-select-option :value="item.id" v-for="(item) in rolesList" :key="item.id"
                            :label="item.roleName">{{ item.roleName }}</a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
                <a-form-item label="授权区域">
                    <a-cascader :options="areaOptions" v-model="defaultValue" placeholder="请选择省市区" @change="onChangeQjssq" :allowClear="true" change-on-select :showSearch="true"/>
                </a-form-item>
            </a-col>
        </a-row>
        <a-row>
            <a-col style="margin-bottom: 40px;float: right">
                <a-button style="margin-right: 20px" type="primary" @click="addRoleFun">新增</a-button>
                <a-button style="margin-right: 20px" type="primary" @click="deleteRoleFun">批量删除</a-button>
                <a-button htmlType="submit" type="primary">查询</a-button>
            </a-col>
        </a-row>
    </a-form>
</template>

<script>
import areaJson from '../../../../assets/js/area.json'
import Busscommutil from '../../../../utils/busscommutil'
export default {
    name: 'RepositoryForm',
    data() {
        return {
            repository: {
            },
            layout: {
                labelCol: { span: 5 },
                wrapperCol: { span: 19 }
            },
            areaOptions: areaJson,
            defaultValue: [],
            rolesList: []
        }
    },
    created() {
        this.getRolesList()
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault()
            this.$emit('formData', this.repository)
        },
        // 获取角色列表
        getRolesList() {
            this.$rolemanage.getRolesList().then((res) => {
                this.rolesList = res.data
            })
        },
        addRoleFun() {
            this.$emit('add')
        },
        deleteRoleFun() {
            this.$emit('delete')
        },
        onChangeQjssq(val) {
            if (val.length === 0) {
                this.repository = {}
            }
            if (val.length === 1) {
                this.repository.provinces = val[0]
                this.repository.city = ''
                this.repository.area = ''
            }
            if (val.length === 2) {
                this.repository.provinces = val[0]
                this.repository.city = val[1]
                this.repository.area = ''
            }
            if (val.length === 3) {
                this.repository.provinces = val[0]
                this.repository.city = val[1]
                this.repository.area = val[2]
            }
        }
    }
}
</script>

<style scoped lang="less">
@deep: ~'>>>';

.limit-width {

    @{deep} .ant-form-item-label {
        width: 9.5% !important;
    }
}
</style>
