<!-- 维护分类字典 -->
<template>
        <a-modal v-model="editVisible" :title="title" :maskClosable="false" :footer="null" class="flzd" :destroyOnClose="true">
            <FlzdFormList @formData="getFormData" @add="addFlzd" ref="flzdsearch"></FlzdFormList>
            <ATable :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type="flzd"
                ref="tableRef" @edit="editFlzd" @delete="doDelete"></ATable>
            <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange"
                ref="paginationRef"></APagination>
                <!-- 修改分类字典 -->
            <EditFlzd ref="editFlzd" @update="getFormData"></EditFlzd>
            <!--  批量新增分类字典 -->
            <BatchAddFlzd ref="batchflzd" @update="getFormData"></BatchAddFlzd>
        </a-modal>
</template>
<script>
import FlzdFormList from '@/components/DictionaryData/Fllb/Search/FlzdFormList/index'
import APagination from '@/components/Pagination/index'
import ATable from '@/components/Table/index'
import EditFlzd from '@/components/DictionaryData/Fllb/Modal/EditFlzd/index'
import BatchAddFlzd from '@/components/DictionaryData/Fllb/Modal/BatchAddFlzd/index'
const columns = [
    {
        title: '分类代码',
        dataIndex: 'id',
        width: '100'
    },
    {
        title: '代码名称',
        dataIndex: 'name',
        width: '150',
        scopedSlots: { customRender: 'rulemsg' }
    },
    {
        title: '父级名称',
        dataIndex: 'parentName',
        width: '100',
        scopedSlots: { customRender: 'rulemsg' }
    },
    {
        title: '父级代码',
        dataIndex: 'parentId',
        width: '100'
    },
    {
        title: '级数',
        dataIndex: 'level',
        width: '50'
    }, {
        title: '创建时间',
        dataIndex: 'createTime',
        width: '13%'
    },
    {
        title: '更新时间',
        dataIndex: 'updateTime',
        width: '13%'
    },
    {
        title: '是否末级',
        dataIndex: 'isEndlevelDesc'
    },
    {
        title: '是否禁用',
        dataIndex: 'disableDesc'
    },
    {
        title: '操作',
        dataIndex: 'address',
        width: '100',
        scopedSlots: { customRender: 'flzd' }
    }];
export default {
    data() {
        return {
            columns,
            pageInfo: {
                page: '',
                limit: ''
            },
            selectedRowKeys: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            },
            scroll: {
                y: 500
            },
            editVisible: false,
            title: '',
            fllbData: null, // 分类类别数据
            dictCode: null // 父级编码
        }
    },
    components: {
        FlzdFormList, APagination, ATable, EditFlzd, BatchAddFlzd
    },
    created() {
    },
    methods: {
        show(val) {
            this.title = val.dictName + val.id
            this.fllbData = val // 分类类别数据
            this.editVisible = true
            this.dictCode = val.id
            this.pageInfo.dictCode = this.dictCode
            // 初始化分页
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.pageInfo = {
                limit: 10,
                page: 1
            }
            this.getFormData()
        },
        // 查询表格数据
        getFormData(data) {
            this.pageInfo.dictCode = this.dictCode
            if (data) {
                this.pageInfo = data
                this.pageInfo.dictCode = this.dictCode
                this.$refs.paginationRef.onChange(1, 10)
                return
            }
            if (this.$refs.tableRef) {
                this.$refs.tableRef.getList(this.pageInfo)
            }
        },
        // 新增
        addFlzd() {
            this.$refs.batchflzd.getFormData(this.fllbData)
        },
        // 修改
        editFlzd(val) {
            let obj = { ...val }
            this.$refs.editFlzd.getFormData(obj, '修改')
        },
        // 删除
        doDelete() {
            this.getFormData()
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.$refs.tableRef.getList(this.pageInfo)
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        }
    }
}
</script>
<style scoped lang="less">
@deep: ~'>>>';

.flzd @{deep} .ant-modal {
    width: 70% !important;

    .cancel:hover {
        color: red;
        opacity: .8;
        cursor: pointer;
    }

    .ant-table-footer {
        text-align: right;
    }
}
</style>
