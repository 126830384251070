<template>
    <div class="public-bg">
        <div class="fixed-mask" v-if="feching">
            <a-spin size="large" />
        </div>
        <a-form @submit="searchMat" :form="pageApi" class="form" v-bind="layout">
            <a-row class="form-row" :gutter="24">
                <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
                    <a-form-item label="单号或项目名称">
                        <a-input v-model="pageApi.keyword" allowClear placeholder='单号或项目名称'></a-input>
                    </a-form-item>
                </a-col>
                <a-col style="margin-bottom: 40px;float: right">
                    <a-button type="primary" @click="exportData" style="margin-right: 20px">导出</a-button>
                    <a-button htmlType="submit" type="primary" style="margin-right: 20px">查询</a-button>
                </a-col>
            </a-row>
            <a-row>
                <a-tabs v-model="activeTab" @change="changeTab">
                    <a-tab-pane :tab="item.statusDesc" v-for="item in flowStatusList" :key="item.statusId"></a-tab-pane>
                </a-tabs>
            </a-row>
        </a-form>
        <a-table :columns='columns' :data-source='matList' bordered :pagination='false' :scroll='scroll'
            :loading="loading" rowKey="id">
            <template slot="options" slot-scope="text,record">
                <a>
                    <span @click="checkDetail(record)">查看明细</span>
                </a>
            </template>
            <template slot="msg" slot-scope="text">
                <a-tooltip placement="topLeft">
                    <template slot="title">
                        {{ text }}
                    </template>
                    {{ text }}
                </a-tooltip>
            </template>
        </a-table>
        <!-- 详情 -->
        <GoodsDetail ref="goodsdetailRef"></GoodsDetail>
        <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange"
            ref="paginationRef"></APagination>
    </div>
</template>
<script>

import GoodsDetail from './GoodsDetail/index'
import APagination from '@/components/Pagination/index'
export default {
    data() {
        return {
            layout: {
                labelCol: { span: 9 },
                wrapperCol: { span: 15 }
            },
            columns: [
                {
                    title: '单号',
                    dataIndex: 'id',
                    fixed: 'left',
                    width: 200
                },
                {
                    title: '单据状态',
                    dataIndex: 'statusDesc'
                },
                {
                    title: '物资需求部门',
                    dataIndex: 'matNeedDept'
                },
                {
                    title: '需求提报申请人',
                    dataIndex: 'recorderName'
                },
                {
                    title: '需求提报申请人电话',
                    dataIndex: 'recorderTel'
                },
                {
                    title: '项目编号(WBS)',
                    dataIndex: 'projectNo'
                },
                {
                    title: '项目名称',
                    dataIndex: 'projectName',
                    scopedSlots: { customRender: 'msg' }
                },
                {
                    title: '成本中心',
                    dataIndex: 'costCenter',
                    scopedSlots: { customRender: 'msg' }
                },
                {
                    title: '总账科目',
                    dataIndex: 'glAccounts',
                    scopedSlots: { customRender: 'msg' }
                },
                {
                    title: '内部订单号',
                    dataIndex: 'internalOrderNo',
                    scopedSlots: { customRender: 'msg' }
                },
                {
                    title: '物资需求时间',
                    dataIndex: 'matNeedTime'
                },
                {
                    title: '送货联系人',
                    dataIndex: 'deliverGoodsContacts'
                },
                {
                    title: '送货联系人电话',
                    dataIndex: 'deliverGoodsContactsTel'
                },
                {
                    title: '送货地址',
                    dataIndex: 'deliverGoodsAddress',
                    scopedSlots: { customRender: 'msg' }
                },
                {
                    title: '需求总价合计',
                    dataIndex: 'totalPrice'
                },
                {
                    title: '选购理由',
                    dataIndex: 'choose',
                    scopedSlots: { customRender: 'msg' }
                },
                {
                    title: '匹配理由',
                    dataIndex: 'matchReason',
                    scopedSlots: { customRender: 'msg' }
                },
                {
                    title: '是否有拟选商品单价超5000元',
                    dataIndex: 'isUnitPriceOverFiveThDesc'
                },
                {
                    title: '需求总价合计是否超10万元',
                    dataIndex: 'isTotalPriceOverTenThDesc'
                },
                {
                    title: '提交时间',
                    dataIndex: 'subTime'
                },
                {
                    title: '操作',
                    dataIndex: 'options',
                    fixed: 'right',
                    width: 100,
                    scopedSlots: { customRender: 'options' }
                }],
            matList: [],
            loading: false,
            feching: false,
            scroll: {
                x: 3200,
                y: 500
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            },
            pageApi: {
                page: 1,
                limit: 10,
                keyword: '',
                status: 9999
            },
            flowStatusList: [], // 流程状态
            activeTab: 9999
        }
    },
    created() {
        this.doGetFlowBillStatusList()
        this.getMatList()
    },
    watch: {},
    components: { GoodsDetail, APagination },
    methods: {
        getMatList() {
            this.loading = true
            this.$mat.doGetMatList(this.pageApi).then((res) => {
                this.loading = false
                this.matList = res.data.rows
                this.pagination.total = res.data.total
                this.matList.forEach(item => {
                    item.matNeedTime = item.matNeedTime.split(' ')[0]
                    item.isUnitPriceOverFiveThDesc = item.isUnitPriceOverFiveTh === 1 ? '是' : '否'
                    item.isTotalPriceOverTenThDesc = item.isTotalPriceOverTenTh === 1 ? '是' : '否'
                })
            })
        },
        // 查看详情
        checkDetail(val) {
            this.$refs.goodsdetailRef.show(val.id)
        },
        // 导出
        exportData(e) {
            e.preventDefault()
            let fidles = this.pageApi
            this.feching = true
            let _this = this
            setTimeout(function () {
                _this.feching = false
            }, 5000);
            let _url = 'api/v1/manager/matApi/doGetMatListMult/DoExport'
            window.location.href = this.$axios.defaults.baseURL + _url + '?keyword=' + fidles.keyword + '&status=' + fidles.status + '&token=' + window.sessionStorage.getItem('token')
        },
        // 获取流程状态
        doGetFlowBillStatusList() {
            this.$flow.doGetFlowBillStatusListApp(11).then(res => {
                this.flowStatusList = res.data
            })
        },
        // 搜索
        searchMat() {
            this.pagination.current = 1
            this.pageApi.page = 1
            this.getMatList()
        },
        // 修改状态
        changeTab() {
            this.pagination.current = 1
            this.pageApi.page = 1
            this.pageApi.status = this.activeTab
            this.getMatList()
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.pageApi.page = pagination.current
            this.pageApi.limit = pagination.pageSize
            this.getMatList()
        }
    }
}
</script>
<style lang='less' scoped></style>
