<template>
  <a-modal v-model='editVisible' :title='modalTitle' ok-text='保存' cancel-text='关闭' @ok='saveModelData'
    :bodyStyle='bodyStyle'>
    <a-form-model class='editFormList' ref='ruleForm' :model='form' :rules='rules' :label-col='labelCol'
      :wrapper-col='wrapperCol'>
      <a-form-model-item label='编号' prop='id'>
        <a-input readOnly required disabled v-model='form.id' />
      </a-form-model-item>
      <a-form-model-item label='账号' prop='userCode'>
        <a-input v-model='form.userCode' allowClear placeholder="账号为数字或字母"/>
      </a-form-model-item>
      <a-form-model-item label='用户名' prop='username'>
        <a-input v-model='form.username' allowClear placeholder="请填写用户名"/>
      </a-form-model-item>
      <a-form-model-item label='手机号' prop='mobile'>
        <a-input v-model='form.mobile' oninput='value = value.replace(/[^\d]/g,"")' allowClear placeholder="请填写手机号"/>
      </a-form-model-item>
      <a-form-model-item label='所在公司' prop='company'>
        <a-select v-model='form.company' placeholder='选择所在公司' show-search optionFilterProp='label'
          :disabled='$store.state.userInfo.roleid !== 99 && $store.state.userInfo.roleid !== 1' allowClear>
          <a-select-option :label='item.companyname' v-for='item in companyList' :key='item.id' :value='item.id'>
            {{ item.companyname }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label='公司所在城市' required>
        <a-cascader :options='areaOptions' v-model='defaultValue' placeholder='请选择城市' @change='onChange'
          :allowClear='true' :showSearch="true" />
      </a-form-model-item>
      <a-form-model-item label='角色' prop='roleid'>
        <a-select v-model='form.roleid' placeholder='选择角色' optionFilterProp='label' showSearch>
          <a-select-option v-for='item in rolesList' :label='item.authRoleName' :key='item.authRoleId'
            :value='item.authRoleId'>
            {{ item.authRoleName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label='用户状态' prop='status' required>
        <a-radio-group v-model='form.status'>
          <a-radio :value='item.id' v-for='item in userStatus' :key='item.id'>
            {{ item.name }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <!-- <a-form-model-item label='是否开启短信通知' prop='smsOffon' required>
        <a-radio-group v-model='form.smsOffon'>
          <a-radio :value="1">是</a-radio>
          <a-radio :value="0">否</a-radio>
        </a-radio-group>
      </a-form-model-item> -->
      <a-form-model-item label='备注'>
        <a-input v-model='form.remark' type='textarea' />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import areaJson from '../../../../assets/js/area.json'
import Busscommutil from '../../../../utils/busscommutil'
export default {
  name: 'editFormList',
  data() {
    // 手机号
    let validatorPhone = (rule, value, callback) => {
        const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        if (!reg.test(value)) {
            return callback(new Error('请填写正确的手机号'));
        } else {
            callback();
        }
    }
    // 账号
    let validatorUserCode = (rule, value, callback) => {
        const reg = /^[a-zA-Z0-9]*$/
        if (!reg.test(value)) {
            return callback(new Error('账号为数字或字母'));
        } else {
            callback();
        }
    }
    return {
      modalTitle: '',
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      areaOptions: areaJson,
      editVisible: false,
      form: {},
      rules: {
        userCode: [
          { required: true, message: '请填写账号', trigger: 'blur' }, { validator: validatorUserCode, trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请填写用户名', trigger: 'blur' }
        ],
        mobile: [{ required: true, message: '请填写手机号', trigger: 'blur' }, { validator: validatorPhone, trigger: 'blur' }],
        status: [
          { required: true, message: '请选择用户状态', trigger: 'blur' }
        ],
        company: [
          { required: true, message: '请选择所在公司', trigger: 'change' }
        ],
        roleid: [{ required: true, message: '请选择角色', trigger: 'blur' }],
        smsOffon: [{ required: true, message: '请选择是否开启短信通知', trigger: 'blur' }]
      },
      companyList: [],
      rolesList: [],
      bodyStyle: {
        height: '500px',
        overflowY: 'scroll'
      },
      defaultValue: ['上海市', '上海市', '黄浦区'],
      userStatus: []
    };
  },
  watch: {
    // 去掉表单验证信息二次打开残留
    editVisible(val, newVal) {
      // 监听的是控制模态框显示或影藏开关的布尔值
      // 监视dialog状态（打开、关闭）
      if (val) {
        try {
          this.$refs.ruleForm.resetFields(); // 重置编辑表单
          // addForm 为ref指向的表单
        } catch (e) { }
      }
    }
  },
  created() {
  },
  methods: {
    getFormData(val, title) {
      this.modalTitle = title
      this.form = val;
      this.getCompanyList();
      this.getRolesList();
      this.getUserStatus();
      this.editVisible = true;
      if (this.modalTitle === '新增') {
        this.defaultValue = ['上海市', '上海市', '黄浦区']
      }
      if (this.modalTitle === '修改') {
        let list = [];
        if (val.site) {
          list = val.site.split(' ');
        }
        this.defaultValue = list;
        this.form.company = parseInt(this.form.company);
      }
    },
    // 获取用户状态
    getUserStatus() {
      this.$category.doGetDictItems(1002).then(
        (res) => {
          if (res.status === 200) {
            this.userStatus = res.data;
          }
        }
      );
    },
    //  获取公司列表
    getCompanyList() {
      let companylist = Busscommutil.checkNull(this.$store.state.companylist)
      if (companylist) {
        this.companyList = this.$store.state.companylist
      } else {
        this.$company.doGetCompanyByDisable('', 0, '').then((res) => {
          this.$store.commit('saveCompanyList', res.data);
          this.companyList = res.data
        }).catch(() => {
        })
      }
    },
    // 获取授权角色列表
    getRolesList() {
      let user = this.$store.state.userInfo
      this.$rolemanage.doGetClickRoleAuth(user.roleid).then((res) => {
        this.rolesList = res.data
      }).catch(() => {
      })
    },
    // 获取匹配角色
    getConnectRole() {
      this.rolesList.forEach((item) => {
        if (item.authRoleId === this.form.roleid) {
          this.form.roleName = item.authRoleName;
        }
      });
    },
    // 获取匹配公司
    getConnectCompany() {
      this.companyList.forEach((item) => {
        if (item.id === this.form.company) {
          this.form.companyName = item.companyname;
        }
      });
    },
    onChange(val) {
      const currentSite = val.join(' ');
      this.form.site = currentSite;
    },
    saveModelData() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 判断地区
          if (!Busscommutil.checkNull(this.form.site)) {
            this.$message.warning('请选择公司所在城市')
            return false
          }
          this.getConnectRole();
          this.getConnectCompany();
          this.form.operateUserId = this.$store.state.userInfo.id;
          this.form.operateUserName = this.$store.state.userInfo.username;
          if (this.form.status === '10020001') {
            this.form.statusDesc = '启用'
          }
          if (this.form.status === '10020002') {
            this.form.statusDesc = '禁用'
          }
          if (this.modalTitle === '新增') {
            this.$user.doAddUser(this.form).then(
              (res) => {
                this.$message.success('新增成功');
                this.editVisible = false;
                this.$emit('updateUmList');
              }
            );
          }
          if (this.modalTitle === '修改') {
            this.$user.doUpdateUser(this.form).then(
              (res) => {
                if (res.status === 200) {
                  this.$message.success('修改成功');
                  this.editVisible = false;
                  this.$emit('updateUmList');
                }
              }
            );
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>
<style scoped lang='less'>
@deep: ~'>>>';
</style>
