<template>
    <a-form @submit="handleSubmit" :form="repository" class="form" v-bind="layout">
        <div class="fixed-mask" v-if="fetching">
            <a-spin size="large" />
        </div>
        <a-row class="form-row" :gutter="24">
            <a-col :xxl="12" :xl="12" :lg="12" :md="12" :sm="24">
                <a-form-item label="单据状态">
                    <a-input placeholder="单据状态" v-model="repository.statusDesc" allowClear
                        oninput="value=value.replace(/\s/g,'')" />
                </a-form-item>
            </a-col>
            <a-col style="float: right">
                <a-button htmlType="submit" type="primary">查询</a-button>
            </a-col>
        </a-row>
    </a-form>
</template>

<script>
export default {
    data() {
        return {
            repository: {
                statusDesc: ''
            },
            layout: {
                labelCol: { span: 5 },
                wrapperCol: { span: 19 }
            },
            fetching: false,
            billTypeList: []
        }
    },
    created() {
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault()
            this.$emit('formData', this.repository)
        }
    }
}
</script>
<style scoped lang="less">
</style>
