<template>
    <!-- 选择覆盖区域（公司管理、功能管理） -->
    <a-modal v-model="editVisible" :title="modalTitle" ok-text="保存" cancel-text="关闭" @ok="saveModelData"
        :destroyOnClose="true" :bodyStyle='bodyStyle'>
        <div class="fixed-mask" v-if="fetching">
            <a-spin size="large" />
        </div>
        <div class="select_area">
            <ul class="pro">
                <a-radio-group @change="changeProvince($event)">
                    <li v-for="item in areaOptions" :key="item.code" class="pro_item">
                        <a-radio :value="item.code">
                            {{ item.value }}
                        </a-radio>
                    </li>
                </a-radio-group>
            </ul>
            <ul v-if="cityArr.length > 0" class="city">
                <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
                    全选
                </a-checkbox>
                <li v-for="(item, index) in cityArr" :key="item.code" class="city_item">
                    <a-checkbox @change="onChangeCity($event, item, index)" v-model="item.checked">
                    </a-checkbox>
                    <span @click="getCurrentCity(item, index)">{{ item.value }}</span>
                </li>
            </ul>
            <ul v-if="countyArr.length > 0" class="county">
                <li v-for="item in countyArr" :key="item.code">
                    <a-checkbox @change="onChangeCounty($event, item)" v-model="item.checked">
                        {{ item.value }}
                    </a-checkbox>
                </li>
            </ul>
        </div>
    </a-modal>
</template>
<script>
import $ from 'jquery'
import areaJson from '../../assets/js/area.json'
import Busscommutil from '../../utils/busscommutil'
export default {
    name: 'editFormList',
    props: ['type'],
    components: {
        // Address
    },
    data() {
        return {
            modalTitle: '',
            labelCol: { span: 8 },
            wrapperCol: { span: 14 },
            areaOptions: areaJson,
            editVisible: false,
            bodyStyle: {
                height: '500px',
                overflowY: 'scroll'
            },
            companyId: '', // 公司id
            // 省市选择
            provinces: {},
            cityArr: [], // 市区数据
            countyArr: [], // 县区数据
            currentCityCode: null, // 当前选中地市
            indeterminate: true, // 是否全选
            checkAll: false, // 是否全选
            fetching: false // 保存状态
        };
    },
    watch: {
        // 去掉表单验证信息二次打开残留
        editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
            // 监视dialog状态（打开、关闭）
            if (val) {
                try {
                    this.$refs.ruleForm.resetFields() // 重置编辑表单
                    // addForm 为ref指向的表单
                } catch (e) { }
            }
        }
    },
    created() {
    },
    methods: {
        getFormData(val, title) {
            this.isCommit = false
            this.modalTitle = title
            if (this.modalTitle === '新增') {
                this.provinces = {}
                this.cityArr = []
                this.countyArr = []
                this.indeterminate = true
                this.currentCityCode = null
            }
            this.editVisible = true
            this.companyId = val
        },
        changeProvince(e) {
            let _this = this
            this.cityArr = []
            this.countyArr = []
            this.checkAll = false
            this.indeterminate = true
            if (e.target.checked) {
                try {
                    this.areaOptions.forEach(function (ele) {
                        if (ele.code === e.target.value) { // 判断与prov是否相等，相等的表示被切换选中的省份
                            ele.children.forEach(item1 => {
                                item1.checked = false
                                item1.children.forEach(item2 => {
                                    item2.checked = false
                                })
                            })
                            _this.cityArr = ele.children;
                            _this.provinces = {
                                provinces: ele.value,
                                provincesCode: ele.code,
                                checkedCityList: []
                            }
                            throw new Error('退出循环')
                        }
                    })
                } catch { }
                this.$forceUpdate()
            }
        },
        // 选择地市
        onChangeCity(e, item, index) {
            // 选中地市，地区全选,否则全不选
            let countyList = []
            item.checked = e.target.checked
            item.children.forEach(ele => {
                ele.checked = e.target.checked
                countyList.push({
                    county: ele.value,
                    countyCode: ele.code,
                    checked: true
                })
            })
            $('.city_item').eq(index).addClass('active').siblings().removeClass('active')
            this.currentCityCode = item
            this.countyArr = item.children
            if (e.target.checked) {
                this.provinces.checkedCityList.push({
                    cityCode: item.code,
                    city: item.value,
                    checked: true,
                    checkedCountyList: countyList
                })
            } else {
                this.provinces.checkedCityList.forEach((ele, index) => {
                    if (ele.cityCode === item.code) {
                        this.provinces.checkedCityList.splice(index, 1)
                    }
                })
                // 取消全选
                this.checkAll = false
                this.indeterminate = true
            }
            this.$forceUpdate()
        },
        // 选择县区
        onChangeCounty(e, item) {
            item.checked = e.target.checked
            if (e.target.checked) {
                // 判断上一级地市是否选中
                if (!this.currentCityCode.checked) {
                    this.currentCityCode.checked = true
                    let newCurrent = {
                        city: this.currentCityCode.value,
                        cityCode: this.currentCityCode.code,
                        checked: true,
                        checkedCountyList: [{
                            countyCode: item.code,
                            county: item.value,
                            checked: true
                        }]
                    }
                    this.provinces.checkedCityList.push(newCurrent)
                } else {
                    this.provinces.checkedCityList.forEach((item1) => {
                        if (item1.cityCode === this.currentCityCode.code) {
                            item1.checkedCountyList.push({
                                countyCode: item.code,
                                county: item.value,
                                checked: true
                            })
                        }
                    })
                    console.log(this.provinces)
                }
            } else {
                let _this = this
                this.provinces.checkedCityList.forEach((item1) => {
                    try {
                        item1.checkedCountyList.forEach((item2, index2) => {
                            if (item2.countyCode === item.code) {
                                item1.checkedCountyList.splice(index2, 1)
                                if (item1.checkedCountyList.length <= 0) {
                                    // 全不选时取消地市选中
                                    _this.cityArr.forEach(item1 => {
                                        if (item1.code === _this.currentCityCode.code) {
                                            item1.checked = false
                                            throw new Error('退出循环')
                                        }
                                    })
                                }
                                throw new Error('退出循环')
                            }
                        })
                    } catch {
                    }
                })
            }
            this.$forceUpdate()
        },
        // 全选地市
        onCheckAllChange(e) {
            this.provinces.checkedCityList = []
            this.cityArr.forEach((item, index) => {
                item.checked = e.target.checked
                this.provinces.checkedCityList.push({
                    city: item.value,
                    cityCode: item.code,
                    checked: e.target.checked,
                    checkedCountyList: []
                })
                item.children.forEach(item2 => {
                    item2.checked = e.target.checked
                    this.provinces.checkedCityList[index].checkedCountyList.push({
                        checked: e.target.checked,
                        county: item2.value,
                        countyCode: item2.code
                    })
                })
            })
            // this.currentCityCode = this.cityArr[0].code
            Object.assign(this, {
                indeterminate: false,
                checkAll: e.target.checked
            })
        },
        // 获取当前地市
        getCurrentCity(val, index) {
            $('.city_item').eq(index).addClass('active').siblings().removeClass('active')
            this.currentCityCode = val
            this.countyArr = val.children
        },
        saveModelData() {
            this.$emit('update', this.provinces)
        }
    }
};
</script>
<style scoped lang="less">
@deep: ~'>>>';
.ant-select {
    width: 200px !important
}

.pro {
    border-right: 1px solid #eee;
    width: 30%;
    height: 450px;
    overflow-y: scroll;

    .pro_item {
        position: relative;
        line-height: 25px;
        font-size: 14px;
        cursor: pointer;
    }
}

.city {
    position: absolute;
    border-right: 1px solid #eee;
    top: 74px;
    left: 32%;
    width: 30%;
    line-height: 25px;
    font-size: 14px;
    color: #333;
    height: 455px;
    overflow-y: scroll;

    .city_item {
        span {
            margin-left: 11px;
            cursor: pointer;
        }
    }
}

.county {
    position: absolute;
    top: 74px;
    left: 64%;
    width: 36%;
    line-height: 25px;
    font-size: 14px;
    color: #333;
    height: 455px;
    overflow-y: scroll;
}

.active {
    color: #1F8839;
}

</style>
