import Vue from 'vue'
// -----------------------------------------接口数据start----------------------------------
import api from '../api/api'
import official from '../api/official'// 公众号接口
import permission from '../api/permission'// 权限管理接口
import company from '../api/company'
import wechatFun from '../api/wechatFun'
import pcmenu from '../api/pcmenu'
import user from '../api/user'
import rolemanage from '../api/rolemanage'
import category from '../api/category'
import businessdata from '../api/businessdata'
import flow from '../api/flow'
import organization from '../api/organization'
import mat from '../api/mat'
// -----------------------------------------接口数据end------------------------------------

Vue.prototype.$api = api;
Vue.prototype.$company = company; // 公司管理接口
Vue.prototype.$official = official; // 公众号接口
Vue.prototype.$permission = permission; // 权限管理接口
Vue.prototype.$wechatFun = wechatFun; // 微信小程序权限
Vue.prototype.$pcmenu = pcmenu; // PC端权限
Vue.prototype.$user = user; // 用户
Vue.prototype.$rolemanage = rolemanage; // 角色管理
Vue.prototype.$category = category; // 分类字典
Vue.prototype.$businessdata = businessdata; // 业务数据
Vue.prototype.$flow = flow; // 流程管理
Vue.prototype.$mat = mat; // 物资审批
Vue.prototype.$organization = organization; // 组织机构
