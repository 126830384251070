<template>
    <a-form @submit="handleSubmit" :form="repository" class="form" v-bind="layout">
        <div class="fixed-mask" v-if="fetching">
            <a-spin size="large" />
        </div>
        <a-row class="form-row" :gutter="24">
            <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
                <a-form-item label="单据类型">
                    <a-select v-model="repository.billType" placeholder="直接选择或搜索选择" allowClear showSearch optionFilterProp="label"
          @change="changeBillType">
          <a-select-option :label="item.billTypeDesc" v-for="(item) in billTypeList" :key="item.id" :value="item.id">{{ item.billTypeDesc }}</a-select-option>
        </a-select>
                </a-form-item>
            </a-col>
            <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24" v-if="repository.billType">
                <a-form-item label="节点名称">
                    <a-select v-model="repository.nodeId" placeholder="直接选择或搜索选择" allowClear showSearch
                        optionFilterProp="label">
                        <a-select-option :label="item.nodeName" v-for="(item) in nodeList" :key="item.id"
                            :value="item.id">{{ item.nodeName }}</a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
        </a-row>
        <a-row>
            <a-col style="margin-bottom: 40px;float: right">
                <a-button type="primary" style="margin-right: 20px" @click="addData">新增</a-button>
                <a-button htmlType="submit" type="primary">查询</a-button>
            </a-col>
        </a-row>
    </a-form>
</template>

<script>
export default {
    name: 'category-type',
    data() {
        return {
            repository: {
            },
            layout: {
                labelCol: { span: 5 },
                wrapperCol: { span: 19 }
            },
            fetching: false,
            billTypeList: [],
            nodeList: []
        }
    },
    created() {
        this.doGetFlowBillTypeAll()
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault()
            this.$emit('formData', this.repository)
        },
        addData() {
            this.$emit('add')
        },
        // 获取单据类型列表
        doGetFlowBillTypeAll() {
            this.$flow.doGetFlowBillTypeAll().then(res => {
                this.billTypeList = res.data
            })
        },
        // 获取流程模型根据单据类型
        doGetFlowBillNodeByType(billType) {
            this.$flow.doGetFlowBillNodeByType(billType).then(res => {
                this.nodeList = res.data
            })
        },
        // 更改单据类型
        changeBillType(e) {
            this.$set(this.repository, 'nodeId', '')
            if (e) {
                this.doGetFlowBillNodeByType(e)
            } else {
                this.nodeList = []
            }
        }
    }
}
</script>
<style scoped lang="less">
@deep: ~'>>>';

@{deep}.import {
    background: #fe7e02;
    border-color: #fe7e02;
}
</style>
