<template>
  <a-form @submit="handleSubmit" :form="repository" class="form" v-bind="layout">
    <div class="fixed-mask" v-if="fetching">
      <a-spin size="large" />
    </div>
    <a-row class="form-row" :gutter="24">
      <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="商品名称">
          <a-input placeholder="商品名称" v-model="repository.tradeName" allowClear
            oninput="value=value.replace(/\s/g,'')" />
        </a-form-item>
      </a-col>
      <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="物料小类">
          <a-input placeholder="物料小类" v-model="repository.matSmallType" allowClear
            oninput="value=value.replace(/\s/g,'')" />
        </a-form-item>
      </a-col>
      <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="物料描述">
          <a-input placeholder="物料描述" v-model="repository.matDesc" allowClear oninput="value=value.replace(/\s/g,'')" />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col style="margin-bottom: 40px;float: right">
        <!-- <a-button type="primary" style="margin-right: 20px" @click="exportTemplate" class="import">下载导入模板</a-button> -->
        <div style="display: inline-block;margin-right: 20px;">
          <a-upload name="file" :multiple="true" :action="actionUrl" @change="handleChange" :beforeUpload='beforeUpload'
            :headers='headers' :showUploadList='false'>
            <a-button type="primary" class="import">导入</a-button>
          </a-upload>
        </div>
        <a-button type="primary" class="import" style="margin-right: 20px" @click="exportData">导出</a-button>
        <a-button type="danger" style="margin-right: 20px" @click="deleteAll">批量删除</a-button>
        <a-button type="primary" style="margin-right: 20px" @click="addData">新增</a-button>
        <a-button htmlType="submit" type="primary">查询</a-button>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
export default {
  name: 'category-type',
  data() {
    return {
      repository: {
        matSmallType: '',
        tradeName: '',
        matDesc: ''
      },
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 }
      },
      fetching: false,
      actionUrl: this.$axios.defaults.baseURL + '/api/v1/manager/matInventoryApi/doGetImportMatInventoryList',
      headers: {
        token: window.sessionStorage.getItem('token')
      }
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.$emit('formData', this.repository)
    },
    // 导出
    exportData(e) {
      e.preventDefault()
      this.fetching = true
      let _this = this
      setTimeout(function () {
        _this.fetching = false
      }, 5000);
      let fidles = this.repository
      let _url = 'api/v1/manager/matInventoryApi/doGetMatInventoryList/DoExport'
      window.location.href = this.$axios.defaults.baseURL + _url + '?matSmallType=' + fidles.matSmallType + '&tradeName=' + fidles.tradeName + '&matDesc=' + fidles.matDesc + '&token=' + window.sessionStorage.getItem('token')
    },
    // 导入
    beforeUpload(file) {
      const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      if (!isExcel) {
        this.$message.error('数据格式不正确');
        this.isBefore = false
        return false
      }
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.fetching = true
        return;
      }
      let res = info.file.response // 后端返回的数据
      if (res.status === 200) {
        this.fetching = false
        this.$message.success(`${info.file.name} 上传成功`);
        this.$emit('update')
      } else if (info.file.status === 'error') {
        this.fetching = false
        if (res.msg) {
          this.$message.error(res.msg);
          return false
        }
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },
    // 下载导入模板
    exportTemplate(e) {
      e.preventDefault()
      let _url = 'api/v1/manager/matInventoryApi/doGetImportMatInventoryTemplate'
      window.location.href = this.$axios.defaults.baseURL + _url + '?token=' + window.sessionStorage.getItem('token')
    },
    // 新增
    addData() {
      this.$emit('add')
    },
    // 删除
    deleteAll() {
      this.$emit('delete')
    }
  }
}
</script>
<style scoped lang="less">
@deep: ~'>>>';

@{deep}.import {
  background: #fe7e02;
  border-color: #fe7e02;
}
</style>
