<template>
  <a-modal v-model="editVisible" :title="title" on-ok="saveModelData" :footer="null" :maskClosable="false">
    <div class="fixed-mask" v-if="loading">
      <a-spin tip="保存中...">
      </a-spin>
    </div>
    <div class="top"><a-button @click='addFun' type="primary">新增</a-button></div>
    <a-table :columns="columns" :data-source="data" bordered :pagination="false">
      <div v-for="col in ['menuName', 'menuMark', 'icon', 'sort']" :slot="col" slot-scope="text, record" :key="col">
        <a-input style="margin: -5px 0" :value="text" @change="e => handleChange(e.target.value, record.key, col)"
          allowClear />
      </div>
      <div slot="isLastlevel" slot-scope="text, record">
        <a-radio-group v-model="record.isLastlevel" @change="e => changeIsLastLevel(e.target.value, record)">
          <a-radio :value="1">
            是
          </a-radio>
          <a-radio :value="0">
            否
          </a-radio>
        </a-radio-group>
      </div>
      <template slot="operation" slot-scope="text, record">
        <div class="editable-row-operations">
          <span>
            <a-popconfirm title="确定删除吗？" @confirm="() => onDelete(record.key)">
              <span class="cancel">删除</span>
            </a-popconfirm>
          </span>
        </div>
      </template>
      <template slot="footer">
        <a-button key="back" @click="cancelModel">
          关闭
        </a-button>
        <a-button key="submit" type="primary" @click="saveModelData" style="margin-left:15px">
          保存
        </a-button>
      </template>
    </a-table>
  </a-modal>
</template>
<script>
const columns = [
  {
    title: '菜单名称',
    dataIndex: 'menuName',
    width: '16%',
    scopedSlots: { customRender: 'menuName' }
  },
  {
    title: '父级名称',
    dataIndex: 'parentName'
  },
  {
    title: '级别',
    dataIndex: 'level'
  },
  {
    title: '是否末级',
    dataIndex: 'isLastlevel',
    scopedSlots: { customRender: 'isLastlevel' }
  },
  {
    title: '菜单标识',
    dataIndex: 'menuMark',
    width: '14%',
    scopedSlots: { customRender: 'menuMark' }
  },
  {
    title: '图标',
    dataIndex: 'icon',
    width: '14%',
    scopedSlots: { customRender: 'icon' }
  },
  {
    title: '排序',
    dataIndex: 'sort',
    width: '10%',
    scopedSlots: { customRender: 'sort' }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
];
export default {
  name: 'editFormList',
  data() {
    // this.cacheData = data.map(item => ({ ...item }));
    return {
      editVisible: false,
      form: {
        menuName: '',
        parentName: '',
        menuMark: '',
        sort: '',
        level: '',
        isLastlevel: '',
        parentId: '',
        icon: ''
      },
      count: 1,
      title: '',
      data: [],
      columns,
      loading: false
    };
  },
  watch: {
    // 去掉表单验证信息二次打开残留
    editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
      // 监视dialog状态（打开、关闭）
      if (val) {
        try {
          this.$refs.ruleForm.resetFields() // 重置编辑表单
          // addForm 为ref指向的表单
        } catch (e) { }
      }
    }
  },
  created() {
  },
  methods: {
    getFormData(val, title) {
      this.data = [];
      // 新增一级菜单
      if (title === 'addFirst') {
        this.form = val
        this.form.key = 0
        this.data.push(this.form)
        this.$forceUpdate()
      }
      if (title === 'add') {
        this.form.parentName = val.menuName
        this.form.parentId = val.id
        this.form.menuName = ''
        this.form.menuMark = ''
        this.form.level = val.level + 1
        this.form.sort = 99
        this.form.icon = ''
        this.form.key = 0
        if (this.form.level === 2) {
          this.form.isLastlevel = 1
        } else {
          this.form.isLastlevel = 0
        }
        this.data.push(this.form)
        this.$forceUpdate()
      }
      this.editVisible = true
      this.title = '批量新增'
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter(item => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    onDelete(key) {
      const dataSource = [...this.data];
      this.data = dataSource.filter(item => item.key !== key);
    },
    // 新增行
    addFun() {
      const { count, data } = this;
      const newData = {
        key: count,
        menuName: '',
        menuMark: '',
        level: this.form.level,
        isLastlevel: '',
        icon: '',
        sort: 99
      };
      if (this.form.level === 2) {
        newData.isLastlevel = 1
      } else {
        newData.isLastlevel = 0
      }
      this.data = [...data, newData];
      this.count = count + 1;
    },
    // 选择是否末级
    changeIsLastLevel(value, row) {
      const newData = [...this.data];
      const target = newData.filter(item => row.key === item.key)[0];
      if (target) {
        target.isLastlevel = value;
        this.data = newData;
      }
    },
    saveModelData() {
      if (this.data.length <= 0) {
        this.$message.warning('请添加菜单')
        return false
      }
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].menuName === '') {
          this.$message.warning('请填写菜单名称')
          return
        }
        if (this.data[i].isLastlevel === '') {
          this.$message.warning('请选择是否末级')
          return
        }
        if (this.data[i].menuMark === '') {
          this.$message.warning('请填写菜单标识')
          return
        }
        if (this.data[i].level === 1) {
          if (this.data[i].icon === '') {
            this.$message.warning('请填写图标')
            return
          }
        }
      }
      for (let i = 0; i < this.data.length; i++) {
        this.loading = true
        this.data[i].parentName = this.form.parentName
        this.data[i].parentId = this.form.parentId
        this.data[i].level = this.form.level
        this.data[i].sort = this.form.sort
        if (i === this.data.length - 1) {
          this.$pcmenu.doAddPcMenu(this.data).then((res) => {
            this.loading = false
            this.editVisible = false
            this.$message.success('新增成功')
            this.$emit('update')
          }).catch(() => {
            this.loading = false
          })
        }
      }
    },
    cancelModel() {
      this.editVisible = false
    }
  }
};
</script>
<style scoped lang="less">
@deep: ~'>>>';

@{deep} .ant-modal {
  width: 70% !important;

  .cancel:hover {
    color: red;
    opacity: .8;
    cursor: pointer;
  }

  .ant-table-footer {
    text-align: right;
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;

  span {
    display: block;
    line-height: 42px;
  }
}
</style>
