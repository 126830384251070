import { getAction, deleteAction, putAction, postAction, postActionQs, httpAction } from '@/api/manage'

/**
  * 组织机构
  */
const base = {
    sysOrgApi: '/api/v1/manager/sysOrgApi/'
}
// 分页-获取组织机构列表(根据父级编号)
const doGetSysOrgListByPage = (params) => postActionQs(base.sysOrgApi + 'doGetSysOrgListByPage', params)

// 获取组织机构列表(树状)
const doGetSysOrgListByTree = (orgName) => postActionQs(base.sysOrgApi + 'doGetSysOrgListByTree', {
    orgName: orgName
}) // orgName:机构名称

// 获取组织机构列表(根据级数或父级编号)
const doGetSysOrggList = (level, parentId) => postActionQs(base.sysOrgApi + 'doGetSysOrggList', {
    level: level,
    parentId: parentId
})

// 批量删除组织机构
const doDelBatchSysOrg = (ids) => postActionQs(base.sysOrgApi + 'doDelBatchSysOrg', {
    ids: ids
})

// 批量新增
const doAddBatchSysOrg = (params) => postAction(base.sysOrgApi + 'doAddBatchSysOrg', params)

// 更新组织机构
const doUpdateSysOrg = (params) => postActionQs(base.sysOrgApi + 'doUpdateSysOrg', params)

export default {
    doGetSysOrgListByPage,
    doGetSysOrgListByTree,
    doGetSysOrggList,
    doDelBatchSysOrg,
    doAddBatchSysOrg,
    doUpdateSysOrg
}
