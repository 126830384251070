<template>
    <div class="public-bg">
        <div class="fixed-mask" v-if="fetching">
            <a-spin size="large" tip="保存中..." />
        </div>
        <!-- 搜索 -->
        <ApproveSearch @add="addApprove" @formData="getFormData"></ApproveSearch>
        <!-- 新增 -->
        <ApproveModal ref="approveRef" @update="getFormData"></ApproveModal>
        <Table :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type='approve'
            @edit="editApprove" @update="getFormData" @disable="doUpdateFlowBillNodeUser" ref='tableRef'></Table>
        <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange"
            ref="paginationRef"></APagination>
    </div>
</template>
<script>
import ApproveSearch from '@/components/Flow/Approve/Search/index'
import ApproveModal from '@/components/Flow/Approve/Modal/index'
import Table from '@/components/Table'
import APagination from '@/components/Pagination/index'
export default {
    data() {
        return {
            columns: [
                {
                    title: '单据类型',
                    dataIndex: 'billTypeName',
                    width: '15%',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '节点名称',
                    dataIndex: 'nodeName',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '审批人',
                    dataIndex: 'userName'
                },
                {
                    title: '是否禁用',
                    dataIndex: 'disable',
                    scopedSlots: { customRender: 'doctypedisable' }
                }, {
                    title: '创建人',
                    dataIndex: 'createBy'
                }, {
                    title: '创建时间',
                    dataIndex: 'createTime'
                }, {
                    title: '更新人',
                    dataIndex: 'updateBy'
                }, {
                    title: '更新时间',
                    dataIndex: 'updateTime'
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    scopedSlots: { customRender: 'docapprove' }
                }
            ],
            modalTitle: '',
            pageInfo: {
                page: '',
                limit: '',
                userId: ''
            },
            scroll: {
                y: 500
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            },
            fetching: false
        }
    },
    created() {
    },
    components: {
        Table, APagination, ApproveSearch, ApproveModal
    },
    methods: {
        // 查询表格
        getFormData(data) {
            if (data) {
                this.pageInfo = data
                this.$refs.paginationRef.onChange(1, 10)
                return
            }
            this.$refs.tableRef.getList(this.pageInfo)
        },
        // 新增
        addApprove() {
            let obj = {
                disable: 0
            }
            this.$refs.approveRef.show(obj, '新增')
        },
        // 修改
        editApprove(val) {
            let obj = { ...val }
            this.$refs.approveRef.show(obj, '修改')
        },
        // 更新是否禁用
        doUpdateFlowBillNodeUser(row) {
            this.fetching = true
            this.$flow.doUpdateFlowBillNodeUser(row).then(res => {
                this.$message.success('修改成功')
                this.fetching = false
            }).catch(() => {
                this.fetching = false
            })
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.$refs.tableRef.getList(this.pageInfo)
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        }
    }
}
</script>
<style lang="less" scoped></style>
