// import { type } from "jquery"

export default class signMd5Utils {
    static getDateTimeToString(time) {
        const date_ = new Date(time)
        const year = date_.getFullYear()
        let month = date_.getMonth() + 1
        let day = date_.getDate()
        let hours = date_.getHours()
        let mins = date_.getMinutes()
        let secs = date_.getSeconds()

        // const msecs = date_.getMilliseconds()
        if (month < 10) month = '0' + month
        if (day < 10) day = '0' + day
        if (hours < 10) hours = '0' + hours
        if (mins < 10) mins = '0' + mins
        if (secs < 10) secs = '0' + secs
        // if (msecs < 10) secs = '0' + msecs
        return year + '/' + month + '/' + day + ' ' + hours + ':' + mins + ':' + secs
    }

    static getDateTimeToStringA(time) {
        if (time) {
            const date_ = new Date(time)
            const year = date_.getFullYear()
            let month = date_.getMonth() + 1
            let day = date_.getDate()
            let hours = date_.getHours()
            let mins = date_.getMinutes()
            let secs = date_.getSeconds()
            // const msecs = date_.getMilliseconds()

            if (month < 10) month = '0' + month
            if (day < 10) day = '0' + day
            if (hours < 10) hours = '0' + hours
            if (mins < 10) mins = '0' + mins
            if (secs < 10) secs = '0' + secs
            // if (msecs < 10) secs = '0' + msecs
            return year + '-' + month + '-' + day + ' ' + hours + ':' + mins + ':' + secs
        }
    }

    static getDateTimeToDate(time) {
        if (time) {
            const date_ = new Date(time)
            const year = date_.getFullYear()
            let month = date_.getMonth() + 1
            let day = date_.getDate()

            if (month < 10) month = '0' + month
            if (day < 10) day = '0' + day
            return year + '年' + month + '月' + day + '日'
        }
    }

    static transIsShow(data) {
        let temp = data
        if (typeof temp === 'string') {
            temp = parseInt(data)
        }
        if (temp === 0) {
            return '否'
        } else if (temp === 1) {
            return '是'
        } else if (temp === 10020002) {
            return '禁用'
        } else if (temp === 10020001) {
            return '启用'
        } else {
            return ''
        }
    }

    static reverseTransIsShow(data) {
        let temp = data
        if (temp === '否') {
            return 0
        } else if (temp === '是') {
            return 1
        } else {
            return ''
        }
    }

    static checkRepeate(data, that) {
        let temp = data
        if (temp === 0) {
            return true
        }
        that.$confirm({
            title: '提示',
            content: h => <div style="color:red;">请不要重复点击</div>,
            onOk() {
                location.reload()
            },
            onCancel() {
                location.reload()
            }
        });
    }

    static checkNull(data) {
        let temp = data
        if (temp === null || temp === undefined || temp === 'undefined' || temp === '') {
            return false
        }
        return true
    }

    static checkParams(obj) {
        Object.keys(obj).forEach((key) => {
            if (obj[key] === undefined) {
                obj[key] = ''
            }
        })
        return obj
    }

    static validateCheckRule(that, ruleCode, value) {
        let flag = true
        let validateText = ''
        ruleCode.forEach((item, index) => {
            // 筛选出全局规则
            if (item.digits === '*') {
                ruleCode.unshift(ruleCode.splice(index, 1)[0]);
            }
            if (index === ruleCode.length - 1) {
                for (let i = 0; i < ruleCode.length; i++) {
                    let str = ruleCode[i].pattern
                    let reg = new RegExp(str)
                    if (ruleCode[i].digits !== '*') { // 局部规则
                        let code = value.charAt(ruleCode[i].digits - 1) // 获取digits位数的字符
                        if (reg.test(code)) {
                            // callback()
                        } else {
                            flag = false
                            validateText = ruleCode[i].message
                        }
                    } else {
                        if (ruleCode[i].message === 'ws') {
                            validateText = ''
                        } else {
                            if (reg.test(value)) {
                                // callback()
                                validateText = ''
                            } else {
                                flag = false
                                validateText = ruleCode[i].message
                                break
                            }
                        }
                    }
                    if (i === ruleCode.length - 1 && flag === true) {
                        validateText = ''
                    }
                    // validateText = ruleCode[i].message
                }
            }
        })
        return validateText
    }

    static transIsType(data) {
        let temp = data
        let type = ''
        let typeId = ''
        if (temp === null || temp === undefined || temp === '') {
            return false
        }
        if (temp.length > 0) {
            temp.forEach((item, index) => {
                type = type + ',' + item.label
                typeId = typeId + ',' + item.key
            })
            if (typeId.indexOf(',') === 0) {
                typeId = typeId.substring(1)
            }
            if (type.indexOf(',') === 0) {
                type = type.substring(1)
            }
        }
        return { type, typeId }
    }

    // 判断截止时间是否到期
    static checkJzsjIsExpire(time) {
        let currentDateString = new Date().getTime() // 获取当前时间毫秒数
        let jzsjString = (new Date(time)) / 1000 * 1000; // 把截止时间变成时间戳
        if (jzsjString < currentDateString) {
            return false
        }
        return true
    }

    static replaceSpecialCode(val) {
        // eslint-disable-next-line no-useless-escape
        const pattern = /[`~!@#$^\-&*%+=|{}':;',\\\[\]\.<>\/?~！@#￥……&*——|{}【】'；：""'？“”‘’\s]/g;
        return val.replace(pattern, '')
    }

    static replaceMinus(val, pattern) {
        // eslint-disable-next-line no-useless-escape
        // const pattern = /[`~!@#$^\-&*%+=|{}':;',\\\[\]\<>\/?~！@#￥……&*——|{}【】'；：""'？“”‘’\s]/g;
        return val.replace(pattern, '')
    }

    static getTaxpointCompany(val) {
        let taxRate = ''
        let taxtRateList = ''
        if (val !== '' && val !== null) {
            if (val.indexOf(',') !== -1) { // 多个税率
                taxtRateList = val.split(',')
            } else { // 一个税率
                taxtRateList = null
                taxRate = val
            }
        }
        return [
            taxtRateList, taxRate
        ]
    }

    static getCurrentMonth(date, currentMonth, that) {
        const month1 = ['01', '03', '05', '07', '08', '10', '12']
        const month3 = ['04', '06', '09', '11']
        var res1 = month1.filter(function (item, index, array) {
            return item === currentMonth
        })
        var res3 = month3.filter(function (item, index, array) {
            return item === currentMonth
        })
        if (res1.length > 0) {
            that.pageInfo.startTime = date + '-01 00:00:00'
            that.pageInfo.endTime = date + '-31 23:59:59'
        }
        if (currentMonth === '02') {
            that.pageInfo.startTime = date + '-01 00:00:00'
            that.pageInfo.endTime = date + '-29 23:59:59'
        }
        if (res3.length > 0) {
            that.pageInfo.startTime = date + '-01 00:00:00'
            that.pageInfo.endTime = date + '-30 23:59:59'
        }
    }

    // list:数组 obj:操作对象 title:修改/新增
    static getMathDmmc(list, obj, title) {
        if (title !== '修改') {
            return false
        }
        if (obj.length > 0) { // 多选
            list.forEach(item => {
                obj.forEach((item2, index) => {
                    if (item.id === item2.key) {
                        obj[index].label = item.name
                    }
                })
            })
        } else { // 单选
            for (const item of list) {
                if (item.id === obj.key) {
                    obj.label = item.name
                    break; // 终止循环
                }
              }
        }
    }
}
