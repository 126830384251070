<template>
    <div>
        <a-modal v-model="editVisible" title="数据权限" :bodyStyle='bodyStyle' :footer="null" @cancel="onClose">
            <div v-if='commit' class="fixed-mask">
                <a-spin tip="保存中...">
                </a-spin>
            </div>
            <div v-if='loading' class="fixed-mask">
                <a-spin tip="加载中...">
                </a-spin>
            </div>
            <a-row>
                <a-col :span="10" style="border-right: 1px solid #eee;padding: 0 15px 0 0;">
                    <a-form :form="repository" class="form" v-bind="layout">
                        <a-row class="form-row" :gutter="24">
                            <a-form-item label="公司名称">
                                <a-input v-model="repository.companyName" allowClear oninput="value=value.replace(/\s/g,'')"
                                    placeholder="输入公司名称"></a-input>
                            </a-form-item>
                        </a-row>
                        <a-row>
                            <a-col style="margin-bottom: 10px;float: right">
                                <a-button style="margin-right: 20px" type="primary" @click="addCompany">授权</a-button>
                                <a-button style="margin-right: 20px" type="primary" @click="deleteCompany">取消授权</a-button>
                                <a-button type="primary" @click="doGetUserAuthDataList">查询</a-button>
                            </a-col>
                        </a-row>
                    </a-form>
                    <div class="menu-title"><a-icon type="appstore" theme="filled"
                            :style="{ fontSize: '16px', color: '#1F8839', paddingRight: '5px' }" />已授权公司</div>
                    <div class="menu-content">
                        <a-tree v-model="checkedKeys" :replaceFields="{ title: 'companyName', key: 'companyId' }" checkable
                            :selected-keys="selectedKeys" :tree-data="companyList" @select="onSelect" @check="onCheck" />
                    </div>
                </a-col>
                <a-col :span="14" style="padding: 0 0 0 15px;">
                    <a-form :form="pageInfo" class="form" v-bind="layout">
                        <a-row class="form-row" :gutter="24">
                            <a-form-item label="地区">
                                <a-cascader :options="areaOptions" v-model="defaultValue" placeholder="请选择省市区" @change="onChangeQjssq" :allowClear="true" change-on-select :showSearch="true"/>
                            </a-form-item>
                        </a-row>
                        <a-row>
                            <a-col style="margin-bottom: 10px;float: right">
                                <a-button style="margin-right: 20px" type="primary" @click="addRegion">授权</a-button>
                                <a-button style="margin-right: 20px" type="primary" @click="deleteRegion">取消授权</a-button>
                                <a-button type="primary" @click="update">查询</a-button>
                            </a-col>
                        </a-row>
                    </a-form>
                    <div class="menu-title"><a-icon type="appstore" theme="filled"
                            :style="{ fontSize: '16px', color: '#1F8839', paddingRight: '5px' }" />已授权区域</div>
                    <ATable :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type="userregionauth" ref="tableRef" @delete="update" @doSelect="doSelect"></ATable>
                    <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange"
                        ref="paginationRef"></APagination>
                </a-col>
            </a-row>

        </a-modal>
        <!-- 授权公司 -->
        <AddCompany ref="addcompany" @update="doGetUserAuthDataList"></AddCompany>
        <!-- 授权地区 -->
        <SelectArea ref="addAreaRef" @update="updateData"></SelectArea>
    </div>
</template>
<script>
import areaJson from '../../../../../assets/js/area.json'
import AddCompany from '@/components/UserManage/Modal/UmDataAuth/AddCompany/index'
import SelectArea from '@/components/SelectArea/index'
import ATable from '@/components/Table/index'
import APagination from '@/components/Pagination/index'
export default {
    data() {
        return {
            columns: [{
                title: '省份',
                dataIndex: 'provinces',
                scopedSlots: { customRender: 'rulemsg' }
            }, {
                title: '地市',
                dataIndex: 'city',
                scopedSlots: { customRender: 'rulemsg' }
            }, {
                title: '区县',
                dataIndex: 'area',
                scopedSlots: { customRender: 'rulemsg' }
            }, {
                title: '操作',
                dataIndex: 'action',
                fixed: 'right',
                scopedSlots: { customRender: 'userregionauth' },
                width: '100px'
            }],
            editVisible: false,
            bodyStyle: {
                height: '650px',
                overflowY: 'scroll'
            },
            areaOptions: areaJson,
            companyList: [],
            loading: false,
            fetchingCm: false,
            commit: false,
            userId: '', // 用户id
            layout: {
                labelCol: { span: 4 },
                wrapperCol: { span: 10 }
            },
            repository: {
                companyName: '' // 公司查询
            },
            // 授权区域查询
            pageInfo: {
                limit: '',
                page: '',
                userCompanyId: ''
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            },
            scroll: {
                y: 500
            },
            // 授权区域
            defaultValue: [],
            checkedKeys: [], // 已选中的节点
            selectedKeys: [], // 当前选中的节点id
            selectedRowKeys: [] // 保存的删除地区
        }
    },
    components: { AddCompany, SelectArea, ATable, APagination },
    created() {
    },
    methods: {
        show(row) {
            this.editVisible = true
            this.userId = row.id
            this.doGetUserAuthDataList()
        },
        // 获取授权区域查询条件
        onChangeQjssq(val) {
            if (val.length === 0) {
                this.pageInfo = {}
            }
            if (val.length === 1) {
                this.pageInfo.provinces = val[0]
                this.pageInfo.city = ''
                this.pageInfo.area = ''
            }
            if (val.length === 2) {
                this.pageInfo.provinces = val[0]
                this.pageInfo.city = val[1]
                this.pageInfo.area = ''
            }
            if (val.length === 3) {
                this.pageInfo.provinces = val[0]
                this.pageInfo.city = val[1]
                this.pageInfo.area = val[2]
            }
        },
        // 获取授权公司
        doGetUserAuthDataList() {
            this.loading = true
            this.$user.doGetUserAuthDataList(this.userId, this.repository.companyName).then(res => {
                this.loading = false
                this.companyList = res.data
                this.pageInfo.userCompanyId = ''
                this.selectedKeys = []
                this.checkedKeys = []
                this.$refs.tableRef.getList(this.pageInfo)
            }).catch(() => {
                this.loading = false
            })
        },
        // 新增公司
        addCompany() {
            this.$refs.addcompany.show(this.userId)
        },
        // 删除公司
        deleteCompany() {
            if (this.checkedKeys.length <= 0) {
                this.$message.error('请选择要取消授权的公司')
                return false
            }
            let checkedCompany = []
            for (let i = 0; i < this.companyList.length; i++) {
                for (let j = 0; j < this.checkedKeys.length; j++) {
                    if (this.checkedKeys[j] === this.companyList[i].companyId) {
                        checkedCompany.push(this.companyList[i].id)
                        break
                    }
                }
            }
            let value = JSON.stringify({
                ids: checkedCompany
            })
            this.$confirm({
                title: '删除',
                content: '真的要取消授权吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$user.doDeleteUserAuthData({
                        ids: value
                    }).then(res => {
                        this.$message.success('公司取消授权成功')
                        this.doGetUserAuthDataList()
                        this.checkedKeys = []
                        this.selectedKeys = []
                        this.pageInfo.userCompanyId = ''
                        this.$refs.tableRef.getList(this.pageInfo)
                    })
                }
            })
        },
        // 选择公司
        onCheck(checkedKeys, e) {
            this.checkedKeys = checkedKeys // 选中的id
        },
        // 当前选择公司
        onSelect(selectedKeys, e) {
            if (selectedKeys.length > 0) {
                this.selectedKeys = selectedKeys // 当前选中的id
                this.currentCompanyId = selectedKeys[0]
                this.pageInfo.userCompanyId = selectedKeys[0]
                this.$refs.tableRef.getList(this.pageInfo)
            }
        },
        // 新增地区
        addRegion() {
            if (this.selectedKeys.length <= 0) {
                this.$message.warning('请选择授权公司')
                return false
            }
            this.$refs.addAreaRef.getFormData(this.currentCompanyId, '新增')
        },
        // 更新地区
        updateData(val) {
            let arr = []
            let checkedCityList = val.checkedCityList
            if (checkedCityList && checkedCityList.length > 0) {
                checkedCityList.forEach((item1, index1) => {
                    if (item1.checked) {
                        item1.checkedCountyList.forEach((item2, index2) => {
                            if (item2.checked) {
                                arr.push({
                                    provinces: val.provinces,
                                    provincesCode: val.provincesCode,
                                    city: item1.city,
                                    cityCode: item1.cityCode,
                                    area: item2.county,
                                    areaCode: item2.countyCode,
                                    userCompanyId: this.currentCompanyId
                                })
                            }
                        })
                    }
                })
                this.$refs.addAreaRef.fetching = true
                if (arr.length > 0) {
                    this.$user.doAddUserAuthDataRegion(arr).then(res => {
                        this.$message.success('新增成功')
                        this.$refs.addAreaRef.editVisible = false
                        this.$refs.addAreaRef.fetching = false
                        this.$refs.tableRef.getList()
                    }).catch(() => {
                        this.fetching = false
                    })
                } else {
                    this.$message.error('请选择省市区')
                }
            } else {
                this.$message.error('请选择省市区')
            }
        },
        // 获取删除地区
        doSelect(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys
        },
        // 删除地区
        deleteRegion() {
            if (this.selectedRowKeys.length <= 0) {
                this.$message.error('请选择要取消授权的地区')
                return false
            }
            let value = JSON.stringify({
                ids: this.selectedRowKeys
            })
            this.$confirm({
                title: '删除',
                content: '真的要取消授权吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$user.doDeleteUserAuthDataRegion({
                        ids: value
                    }).then(res => {
                        this.$message.success('地区取消授权成功')
                        this.update()
                        this.selectedRowKeys = []
                    })
                }
            })
        },
        // 删除后更新
        update() {
            this.$refs.tableRef.getList(this.pageInfo)
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.$refs.tableRef.getList(this.pageInfo)
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        },
        onClose() {
            this.editVisible = false
            this.checkedKeys = []
            this.selectedKeys = []
            this.pageInfo.userCompanyId = ''
            this.defaultValue = []
            this.repository.companyName = ''
            this.$refs.tableRef.getList(this.pageInfo)
        }
    }
}
</script>
<style scoped lang="less">
@deep: ~'>>>';

@{deep} .ant-modal {
    width: 70% !important;

    .menu-content {
        height: 400px;
        overflow-y: scroll;
        // ::-webkit-scrollbar {
        //     width: 10px;
        // }
    }

    .menu-title {
        font-size: 16px;
        line-height: 30px;
        padding-bottom: 5px;
    }
}
</style>
