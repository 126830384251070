<template>
  <a-modal v-model="editVisible" :title="modalTitle" ok-text="保存" cancel-text="关闭" @ok="saveModelData"
    :bodyStyle='bodyStyle'>
    <div class="fixed-mask" v-if="fetching">
      <a-spin size="large" tip="保存中..." />
    </div>
    <a-form-model class="editFormList" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
    :wrapper-col="wrapperCol">
      <a-form-model-item label="公司名称" prop="companyname">
        <a-input v-model="form.companyname" allowClear placeholder="请填写公司名称"
          oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="是否禁用" prop="disable">
        <a-radio-group v-model="form.disable">
          <a-radio :value="1">
            禁用
          </a-radio>
          <a-radio :value="0">
            启用
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import Busscommutil from '../../../../utils/busscommutil'
export default {
  name: 'editFormList',
  // props: ['modalTitle'],
  components: {
    // Address
    // UploadYyzz
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      editVisible: false,
      form: {
        companyname: '',
        disable: ''
      },
      rules: {
        companyname: [{ required: true, message: '请填写公司名称', trigger: 'blur' }],
        disable: [
          { required: true, message: '请选择是否禁用', trigger: 'change' }
        ]
      },
      typeList: [], // 公司类型
      taxtRateList: [], // 税率
      openInvoiceTypeList: [], // 开票类型
      bodyStyle: {
        height: '300px',
        overflowY: 'scroll'
      },
      modalTitle: '',
      fetching: false, // 是否加载完成
      isCommit: false, // 保存状态
      companyList: [] // 保险公司
    };
  },
  watch: {
    // 去掉表单验证信息二次打开残留
    editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
      // 监视dialog状态（打开、关闭）
      if (val) {
        try {
          this.$refs.ruleForm.resetFields() // 重置编辑表单
          // addForm 为ref指向的表单
        } catch (e) { }
      }
    }
  },
  created() {
  },
  methods: {
    getFormData(val, title) {
      this.isCommit = false
      this.modalTitle = title
      this.editVisible = true
      this.form = val
    },
    //  获取所有公司列表
    getAllCompanyList() {
      this.$company.doGetCompanyByDisable('', 0, '').then((res) => {
        this.$store.commit('saveCompanyList', res.data);
      }).catch(() => {
      })
      this.$company.getAllCompanyList().then((res) => {
        this.$store.commit('saveCompanyAllList', res.data);
      }).catch(() => { })
    },
    saveModelData() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let data = {
            companyname: this.form.companyname,
            disable: this.form.disable
          }
          this.fetching = true
          if (this.modalTitle === '新增') {
            data.createBy = this.$store.state.userInfo.username
            data.updateBy = this.$store.state.userInfo.username
            this.$company.doAddCompany(data).then(res => {
              this.$message.success('新增成功')
              this.fetching = false
              this.editVisible = false
              this.getAllCompanyList() // 更新缓存公司列表
              this.$emit('update')
            }).catch(() => {
              this.fetching = false
            })
          }
          if (this.modalTitle === '修改') {
            data.id = this.form.id
            data.updateBy = this.$store.state.userInfo.username
            this.$company.doUpdateCompany(data).then(res => {
              this.$message.success('修改成功')
              this.fetching = false
              this.editVisible = false
              this.getAllCompanyList() // 更新缓存公司列表
              this.$emit('update')
            }).catch(() => {
              this.fetching = false
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>
<style scoped lang="less">
</style>
