import { getAction, deleteAction, putAction, postAction, postActionQs, httpAction } from '@/api/manage'
import Vue from 'vue'

/**
  * 流程管理
  */
const base = {
    flowBillTypeApi: '/api/v1/manager/flowBillTypeApi/', // 单据类型
    flowBillNodeApi: '/api/v1/manager/flowBillNodeApi/', // 流程模型
    flowBillStatusApi: '/api/v1/manager/flowBillStatusApi/', // 流程状态
    flowBillNodeUserApi: '/api/v1/manager/flowBillNodeUserApi/' // 审批配置
}
// 获取单据类型列表
const doGetFlowBillTypeList = (params) => postActionQs(base.flowBillTypeApi + 'doGetFlowBillTypeList', params)
// 获取单据类型全部
const doGetFlowBillTypeAll = () => postActionQs(base.flowBillTypeApi + 'doGetFlowBillTypeAll')
// 新增单据类型
const doAddFlowBillType = (params) => postActionQs(base.flowBillTypeApi + 'doAddFlowBillType', params)
// 更新单据类型
const doUpdateFlowBillType = (params) => postActionQs(base.flowBillTypeApi + 'doUpdateFlowBillType', params)
// 删除单据类型
const doDelFlowBillType = (id) => postActionQs(base.flowBillTypeApi + 'doDelFlowBillType', {
  id: id
})
// 获取流程模型列表
const doGetFlowBillNodeList = (params) => postActionQs(base.flowBillNodeApi + 'doGetFlowBillNodeList', params)
// 获取流程模型根据单据类型
const doGetFlowBillNodeByType = (billType) => postActionQs(base.flowBillNodeApi + 'doGetFlowBillNodeByType', {
  billType: billType // 关联单据类型
})
// 获取流程状态列表分页
const doGetFlowBillStatusList = (params) => postActionQs(base.flowBillStatusApi + 'doGetFlowBillStatusList', params)
// 获取流程状态列表不分页
const doGetFlowBillStatusListApp = (billTypeId) => postActionQs(base.flowBillStatusApi + 'doGetFlowBillStatusListApp', billTypeId)

// 获取审批配置列表
const doGetFlowBillNodeUserList = (params) => postActionQs(base.flowBillNodeUserApi + 'doGetFlowBillNodeUserList', params)
// 新增审批配置
const doAddFlowBillNodeUser = (params) => postActionQs(base.flowBillNodeUserApi + 'doAddFlowBillNodeUser', params)
// 更新审批配置
const doUpdateFlowBillNodeUser = (params) => postActionQs(base.flowBillNodeUserApi + 'doUpdateFlowBillNodeUser', params)
// 删除审批配置
const doDelFlowBillNodeUser = (id) => postActionQs(base.flowBillNodeUserApi + 'doDelFlowBillNodeUser', {
  id: id
})
export default {
    doGetFlowBillTypeList,
    doAddFlowBillType,
    doUpdateFlowBillType,
    doDelFlowBillType,
    doGetFlowBillTypeAll,
    doGetFlowBillNodeList,
    doGetFlowBillStatusList,
    doGetFlowBillNodeUserList,
    doAddFlowBillNodeUser,
    doUpdateFlowBillNodeUser,
    doDelFlowBillNodeUser,
    doGetFlowBillNodeByType,
    doGetFlowBillStatusListApp
}
