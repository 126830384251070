<template>
    <a-form @submit="handleSubmit" :form="repository" class="form" v-bind="layout">
        <a-row class="form-row" :gutter="24">
            <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
                <a-form-item label="菜单名称">
                    <a-input placeholder="菜单名称" v-model="repository.menuName" allowClear />
                </a-form-item>
            </a-col>
            <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
                <a-form-item label="父级名称">
                    <a-input placeholder="父级名称" v-model="repository.parentName" allowClear />
                </a-form-item>
            </a-col>
        </a-row>
        <a-row>
            <a-col style="margin-bottom: 40px;float: right">
                <a-button style="margin-right: 20px" type="primary" @click="addMenu">新增</a-button>
                <a-button htmlType="submit" type="primary">查询</a-button>
            </a-col>
        </a-row>
    </a-form>
</template>

<script>
export default {
    name: 'RepositoryForm',
    data() {
        return {
            repository: {
            },
            layout: {
                labelCol: { span: 5 },
                wrapperCol: { span: 19 }
            }
        }
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault()
            this.$emit('formData', this.repository)
        },
        addMenu() {
            this.$emit('add')
        }
    }
}
</script>

<style scoped></style>
