<template>
    <div>
        <a-modal ref="ruleForm" v-model="editVisible" :title="title" cancel-text="关闭" :bodyStyle='bodyStyle'
            :footer="null" :destroyOnClose="true" @cancel="onClose">
            <div v-if='isCommit' class="fixed-mask">
                <a-spin tip="保存中...">
                </a-spin>
            </div>
            <a-row>
                <a-col :span="12" style="border-right: 1px solid #eee;padding: 0 15px 0 0;">
                    <a-form :form="pageInfo" class="form" v-bind="layout">
                        <a-row class="form-row" :gutter="24">
                            <a-col :xxl="16" :xl="24" :lg="24" :md="24" :sm="24">
                                <a-form-item label="角色名称">
                                    <a-input placeholder="角色名称" v-model="pageInfo.roleName" allowClear />
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col style="margin-bottom: 20px;float: right">
                                <a-button type="primary" @click="addRoleAuthFun"
                                    style="margin-right: 10px;">授权</a-button>
                                <a-button type="primary" @click="getRolesList">查询</a-button>
                            </a-col>
                        </a-row>
                    </a-form>
                    <div class="role-title"><a-icon type="appstore"
                            :style="{ fontSize: '16px', color: '#1F8839', paddingRight: '5px' }" />全部角色
                        <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange"
                            style="float: right">
                            全选
                        </a-checkbox>
                    </div>
                    <div class="role-content">
                        <div :style="{ borderBottom: '1px solid #E9E9E9' }">
                        </div>
                        <a-checkbox-group v-model="checkedList" :options="rolesList" @change="onChange" />
                    </div>
                </a-col>
                <a-col :span="12" style="padding: 0 0 0 15px;">
                    <a-form :form="rolecheckedInfo" class="form" v-bind="layout">
                        <a-row class="form-row" :gutter="24">
                            <a-col :xxl="16" :xl="24" :lg="24" :md="24" :sm="24">
                                <a-form-item label="角色名称">
                                    <a-input placeholder="角色名称" v-model="rolecheckedInfo.authRoleName" allowClear />
                                </a-form-item>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col style="margin-bottom: 20px;float: right">
                                <a-button type="primary" @click="deleteRoleAuthFun"
                                    style="margin-right: 10px;">取消授权</a-button>
                                <a-button type="primary" @click="doGetRoleAuthList">查询</a-button>
                            </a-col>
                        </a-row>
                    </a-form>
                    <div class="role-title"><a-icon type="appstore" theme="filled"
                            :style="{ fontSize: '16px', color: '#1F8839', paddingRight: '5px' }" />已授权角色
                        <a-checkbox :indeterminate="rightIndeterminate" :checked="rightcheckAll"
                            @change="onRightCheckAllChange" style="float: right">
                            全选
                        </a-checkbox>
                    </div>
                    <div class="role-content" v-if="roleCheckedList.length > 0">
                        <div :style="{ borderBottom: '1px solid #E9E9E9' }">
                        </div>
                        <a-checkbox-group v-model="cancelcheckedList" :options="roleCheckedList"
                            @change="onRightChange" />
                    </div>
                    <a-empty :image="simpleImage" description="暂无授权角色" v-if="roleCheckedList.length <= 0"
                        style="margin-top: 6%;" />
                </a-col>
            </a-row>
        </a-modal>
    </div>
</template>
<script>
import { Empty } from 'ant-design-vue';
export default {
    beforeCreate() {
        this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
    },
    data() {
        return {
            editVisible: false,
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            bodyStyle: {
                height: '600px',
                overflowY: 'scroll'
            },
            pageInfo: {
                roleName: ''
            },
            rolecheckedInfo: {
                authRoleName: ''
            },
            rolesList: [],
            roleCheckedList: [], // 已授权角色列表
            isCommit: false,
            title: '',
            // 左侧角色列表
            indeterminate: false,
            checkAll: false, // 是否全选
            checkedList: [], // 选中的角色
            // 右侧已授权角色列表
            rightIndeterminate: false,
            rightcheckAll: false, // 是否全选
            cancelcheckedList: [] // 选中的取消授权角色
        }
    },
    watch: {
        // 去掉表单验证信息二次打开残留
        editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
            // 监视dialog状态（打开、关闭）
            if (val) {
                try {
                    this.$refs.ruleForm.resetFields() // 重置编辑表单
                    // addForm 为ref指向的表单
                } catch (e) { }
            }
        }
    },
    methods: {
        getFormData(row) {
            this.editVisible = true
            this.rolecheckedInfo.roleId = row.id // 关联公司角色ID
            this.getRolesList()
            this.doGetRoleAuthList()
            this.title = '授权角色' + '（' + row.roleName + '）'
        },
        // 获取角色列表
        getRolesList() {
            this.isCommit = true
            this.$rolemanage.getRolesList(this.pageInfo.roleName).then((res) => {
                this.isCommit = false
                this.rolesList = res.data
                this.rolesList.forEach(item => {
                    item.label = item.roleName
                    item.value = item.id
                })
            })
        },
        // 获取授权角色列表
        doGetRoleAuthList() {
            this.isCommit = true
            this.$rolemanage.doGetRoleAuthList(this.rolecheckedInfo).then(res => {
                this.isCommit = false
                this.roleCheckedList = res.data
                this.roleCheckedList.forEach(item => {
                    item.label = item.authRoleName
                    item.value = item.id
                })
            })
        },
        // -------------------------------左侧全部角色start----------------------------
        // 新增授权角色
        addRoleAuthFun() {
            let arr = []
            if (this.checkedList.length > 0) {
                this.checkedList.forEach((item, index) => {
                    arr.push({
                        authRoleId: item, // app角色ID
                        roleId: this.rolecheckedInfo.roleId // 关联公司对应的角色ID
                    })
                    if (index === this.checkedList.length - 1) {
                        this.isCommit = true
                        this.$rolemanage.doAddRoleAuth(arr).then(res => {
                            this.isCommit = false
                            this.$message.success('授权成功')
                            this.doGetRoleAuthList()
                            this.checkedList = []
                        }).catch(() => {
                            this.isCommit = false
                        })
                    }
                })
            } else {
                this.$message.error('请选择授权角色')
            }
        },
        // 全选和取消全选
        onCheckAllChange(e) {
            Object.assign(this, {
                indeterminate: false,
                checkAll: e.target.checked
            });
            if (e.target.checked) {
                this.checkedList = this.rolesList.map(item => {
                    return item.id
                })
            } else {
                this.checkedList = []
            }
        },
        // 选择授权的角色
        onChange(checkedList) {
            this.indeterminate = !!checkedList.length && checkedList.length < this.rolesList.length;
            this.checkAll = checkedList.length === this.rolesList.length;
        },
        // -------------------------------左侧全部角色end----------------------------
        // -------------------------------右侧全部角色start----------------------------
        // 全选和取消全选
        onRightCheckAllChange(e) {
            Object.assign(this, {
                rightIndeterminate: false,
                rightcheckAll: e.target.checked
            });
            if (e.target.checked) {
                this.cancelcheckedList = this.roleCheckedList.map(item => {
                    return item.id
                })
            } else {
                this.cancelcheckedList = []
            }
        },
        // 选择取消授权的选项
        onRightChange(checkedList) {
            this.rightIndeterminate = !!checkedList.length && checkedList.length < this.roleCheckedList.length;
            this.rightcheckAll = checkedList.length === this.roleCheckedList.length;
        },
        // 取消选中角色
        deleteRoleAuthFun() {
            if (this.cancelcheckedList.length <= 0) {
                this.$message.error('请选择要取消授权的角色')
                return false
            }
            let value = JSON.stringify({
                ids: this.cancelcheckedList
            })
            this.$confirm({
                title: '取消授权',
                content: '真的取消授权吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$rolemanage.doDelRoleAuth(value).then(res => {
                        if (res.status === 200) {
                            this.doGetRoleAuthList()
                            this.cancelcheckedList = []
                            this.rightIndeterminate = false
                            this.$message.success('取消授权成功')
                        }
                    })
                }
            })
        },
        // -------------------------------右侧全部角色end----------------------------
        onClose() {
            this.editVisible = false
            this.indeterminate = false
            this.checkedList = []
            this.checkAll = false
            this.rightIndeterminate = false
            this.rightcheckAll = false
            this.cancelcheckedList = []
            this.pageInfo = {}
            this.rolecheckedInfo = {}
        }
    }
}
</script>
<style lang="less" scoped>
@deep: ~'>>>';

@{deep} {
    .ant-modal {
        width: 50% !important;

        .role-content {
            height: 400px;
            overflow-y: scroll;
            // ::-webkit-scrollbar {
            //     width: 10px;
            // }
        }

        .role-title {
            font-size: 16px;
            line-height: 30px;
        }

        .ant-checkbox-group-item {
            display: block;
            line-height: 30px;
        }
    }
}
</style>
