<template>
  <a-form @submit="handleSubmit" :form="repository" class="form" v-bind="layout">
    <div class="fixed-mask" v-if="fetching">
      <a-spin size="large" />
    </div>
    <a-row class="form-row" :gutter="24">
      <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="公司名称">
          <a-input v-model="repository.companyname" allowClear oninput="value=value.replace(/\s/g,'')"></a-input>
        </a-form-item>
      </a-col>
      <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="是否禁用">
          <a-radio-group v-model="repository.disable">
            <a-radio :value="1">
              禁用
            </a-radio>
            <a-radio :value="0">
              启用
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col style="margin-bottom: 20px;float: right">
        <a-button type="primary" style="margin-right: 20px" @click="exportData">导出</a-button>
        <a-button type="primary" @click="addUserData" style="margin-right: 20px">新增</a-button>
        <a-button htmlType="submit" type="primary">查询</a-button>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import Busscommutil from '../../../utils/busscommutil'
export default {
  name: 'RepositoryForm',
  data() {
    return {
      areaOptions: null,
      repository: {
        companyname: '',
        disable: ''
      },
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
      },
      fetching: false
    }
  },
  components: { },
  created() {
    // this.doGetFldmList()
    // this.getInsurCompanyList()
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.$emit('formData', this.repository)
    },
    addUserData() {
      this.$emit('add')
    },
    exportData(e) {
      e.preventDefault()
      let fidles = Busscommutil.checkParams(this.repository)
      this.fetching = true
      let _this = this
      setTimeout(function () {
        _this.fetching = false
      }, 5000);
      let _url = 'api/v1/manager/CompanyApi/doGetCompanyListByPage/DoExport'
      window.location.href = this.$axios.defaults.baseURL + _url + '?companyname=' + fidles.companyname + '&disable=' + fidles.disable + '&token=' + window.sessionStorage.getItem('token')
    }
  }
}
</script>

<style scoped lang="less">
@deep: ~'>>>';

.limit-width {
  @{deep} .ant-form-item-label {
    width: 16% !important;
  }

  @{deep} .ant-form-item-control-wrapper {
    width: 80% !important;
  }
}
</style>
