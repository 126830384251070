<template>
  <div class="page-right-head" :style="{ width: (collapsed ? 'calc(100% - 80px)' : 'calc(100% - 256px)') }">
    <div class="page-top">
      <div style="display: flex;align-items: center">
        <a-button type="primary" @click="toggleCollapsed">
          <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
        </a-button>
        <div class="pagetitle">
          <a-breadcrumb style="padding: 0 0px 0px 20px; font-size: 14px">
            <a-breadcrumb-item v-for="(item, index) in $route.meta.breadcrumb" :key="index">{{ item }}
            </a-breadcrumb-item>
          </a-breadcrumb>
        </div>
      </div>
      <div style="display: flex;justify-content: space-between;align-items: center;">
        <div class="page-head-right-avatar">
          <!-- <a-avatar size="small" icon="user" style="margin-right: 15px"/> -->
          <a-dropdown>
            <span style="cursor: pointer">{{ companyName }}{{ userName }},欢迎您</span>
            <a-menu slot="overlay">
              <a-menu-item @click="checkPM">
                <a-icon type="user" />查看个人信息
              </a-menu-item>
              <a-menu-item @click="changePsd">
                <a-icon type="setting" />重置密码
              </a-menu-item>
              <a-menu-item @click="refreshSession">
                <a-icon type="sync" />刷新缓存
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="page-head-link" @click="logout" style="margin-left:15px">
          <icon-font type="icon-tuichu" />退出登录
        </div>
        <!-- 版本号 -->
        <div class="version">版本号：{{ version }}</div>
      </div>
    </div>
    <PageTitle></PageTitle>
    <ChangePsd ref="changePsdref"></ChangePsd>
    <PersonalMsg ref="pmRef"></PersonalMsg>
  </div>
</template>
<script>
import store from '../../store'
import ChangePsd from '@/components/PersonalMessage/ChangePsd'
import PersonalMsg from '@/components/PersonalMessage/PersonalMsg'
import { Icon } from 'ant-design-vue';
import PageTitle from '@/components/PageTitle/index'

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js'
});
export default {
  name: 'page-right-head',
  props: ['collapsed'],
  components: {
    IconFont, ChangePsd, PersonalMsg, PageTitle
  },
  data() {
    return {
      visible: false,
      companyName: store.state.userInfo.companyName,
      userName: store.state.userInfo.username,
      version: '', // 版本号
      user: null
    };
  },
  created() {
    this.version = window.g.version
    this.user = this.$store.state.userInfo
  },
  methods: {
    hideModal() {
      this.visible = false
    },
    logout() {
      //  this.visible = true
      const that = this
      this.$confirm({
        title: '提示',
        content: '真的要退出登录吗 ?',
        cancelText: '取消',
        okText: '确定',
        onOk() {
          that.visible = false;
          that.$router.push({ path: '/login' });
          that.$store.commit('clearAllSession')
        },
        onCancel() {
        }
      });
    },
    toggleCollapsed() {
      this.$emit('changeStatus')
    },
    changePsd() {
      this.$refs.changePsdref.getFormData(this.user)
    },
    checkPM() {
      this.$refs.pmRef.getFormData()
    },
    // 刷新缓存
    refreshSession() {
      // 公司管理
      this.$company.doGetCompanyByDisable('', 0, '').then((res) => {
        this.$store.commit('saveCompanyList', res.data);
      }).catch(() => {
      })
      // 公司管理（包含禁用）
      this.$company.getAllCompanyList().then((res) => {
        this.$store.commit('saveCompanyAllList', res.data);
      }).catch(() => {
      })
    }
  }
};
</script>
<style scoped lang="less">
.page-right-head {
  // height: 112px;
  background: #fff;
  border-bottom: 1px solid #eee;
  padding: 0 10px;
  color: #1F8839;
  font-size: 14px;
  position: fixed;
  z-index: 99;

  .page-head-right-avatar {
    padding: 0 10px;
  }

  .page-head-link {
    margin-right: 12px;
    cursor: pointer;
  }

  .page-head-link .anticon {
    padding: 0 5px;
    color: #1F8839;
  }

  .page-top {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
  }
}
</style>
