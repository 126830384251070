<template>
  <a-modal v-model="editVisible" :title="title" ok-text="保存" cancel-text="关闭" @ok="saveModelData"
    :bodyStyle='bodyStyle'>
    <div class="fixed-mask" v-if="loading">
      <a-spin tip="保存中...">
      </a-spin>
    </div>
    <a-form-model class="editFormList" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
      :wrapper-col="wrapperCol">
      <a-form-model-item label="机构名称" prop="orgName">
        <a-input v-model="form.orgName" oninput="value=value.replace(/\s/g,'')" />
      </a-form-model-item>
      <a-form-model-item label="机构简称" prop="orgNameAbbr">
        <a-input v-model="form.orgNameAbbr" oninput="value=value.replace(/\s/g,'')" />
      </a-form-model-item>
      <a-form-model-item label="机构标识代码" prop="orgRemarkCode">
        <a-input v-model="form.orgRemarkCode" oninput="value=value.replace(/\s/g,'')" :disabled="form.level === 1 ? false : true"/>
      </a-form-model-item>
      <a-form-model-item label="机构级别" prop="level">
        <a-input v-model="form.level" disabled />
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status">
        <a-radio-group v-model="form.status">
          <a-radio :value="0">
            启用
          </a-radio>
          <a-radio :value="1">
            禁用
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="备注">
        <a-input v-model="form.remark" type="textarea"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
export default {
  name: 'editFormList',
  data() {
    // 请填写机构标识代码
    let validatororgRemarkCode = (rule, value, callback) => {
        const reg = /^[A-Z]+$/
        if (!reg.test(value)) {
            return callback(new Error('机构标识代码只能输入大写字母'));
        } else {
            callback();
        }
    }
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      editVisible: false,
      form: {
        orgName: '',
        orgNameAbbr: '',
        orgRemarkCode: '',
        level: '',
        status: '',
        oldOrgRemarkCode: ''
      },
      rules: {
        orgName: [{ required: true, message: '请填写机构名称', trigger: 'blur' }],
        level: [{ required: true, message: '请填写级别', trigger: 'blur' }],
        orgNameAbbr: [{ required: false, message: '请填写机构简称', trigger: 'blur' }],
        orgRemarkCode: [{ required: true, message: '请填写机构标识代码', trigger: 'blur' }, { validator: validatororgRemarkCode, trigger: 'blur' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }]
      },
      rolesList: [],
      bodyStyle: {
        height: '450px',
        overflowY: 'scroll'
      },
      title: '修改',
      loading: false
    };
  },
  watch: {
    // 去掉表单验证信息二次打开残留
    editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
      // 监视dialog状态（打开、关闭）
      if (val) {
        try {
          this.$refs.ruleForm.resetFields() // 重置编辑表单
          // addForm 为ref指向的表单
        } catch (e) { }
      }
    }
  },
  created() {
  },
  methods: {
    getFormData(val, title) {
      this.oldOrgRemarkCode = val.orgRemarkCode
      this.form = val
      this.editVisible = true
    },
    saveModelData() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.oldOrgRemarkCode = this.oldOrgRemarkCode
          this.$organization.doUpdateSysOrg(this.form).then((res) => {
            this.loading = false
            this.$message.success('修改成功')
            this.editVisible = false
            this.$emit('update')
          }).catch(() => {
            this.loading = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>
<style scoped lang="less">
@deep: ~'>>>';
</style>
