<template>
    <div class="dxxj public-bg">
        <div class="wsxm-content">
            <!-- 左侧部分 -->
            <LeftNav class="leftnav" ref="leftNavRef" @currentParentId="getCurrentParentId"></LeftNav>
            <!-- 右侧部分 -->
            <RightContent class="rightContent" ref="rightContentRef" @updateTree="updateTree" @goback="getFirstLevel"></RightContent>
        </div>
    </div>
</template>
<script>
import LeftNav from '@/components/DictionaryData/Organization/LeftNav'
import RightContent from '@/components/DictionaryData/Organization/RightContent'
export default {
    data() {
        return {
        }
    },
    components: {
        RightContent, LeftNav
    },
    created() {
    },
    methods: {
        // 根据左侧选择的父级获取右侧子级
        getCurrentParentId(val) {
            this.$refs.rightContentRef.getOrgData({
                id: val.id,
                level: val.level,
                orgName: '',
                orgRemarkCode: val.orgRemarkCode,
                type: 0
            })
        },
        // 右侧菜单更新后，更新左侧菜单
        updateTree(val) {
            // 新增一级
            if (val.id === 0) {
                this.$refs.leftNavRef.getAuthData('')
            } else {
                this.$refs.leftNavRef.updateTreeData(val.id)
            }
        },
        // 获取一级菜单
        getFirstLevel() {
            this.$refs.leftNavRef.goBackFirst()
        }
    }
}
</script>
<style scoped lang="less">
@deep: ~'>>>';

.atable {
    @{deep} .ant-table {
        tr {
            td {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }

        .ant-table-tbody>tr>td:first-child {
            text-align: left !important;
            padding-left: 36px !important;
        }

        .ant-upload {
            color: #168F41;
        }
    }
}

.wsxm-content {
    background: #eee;
    display: flex;
    align-content: center;
    justify-content: space-between;
}

.leftnav {
    padding: 15px;
    background: #fff;
    width: 20%;
    box-sizing: border-box;
}

.rightContent {
    padding: 15px;
    background: #fff;
    width: 79%;
    float: right;
}
</style>
