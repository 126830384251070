<template>
    <a-modal v-model="editVisible" :title="title" :footer="null" :bodyStyle='bodyStyle' :destroyOnClose="true">
        <!-- 搜索 -->
        <FlowStatusSearch @formData="getFormData"></FlowStatusSearch>
        <Table :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type='flowstatus'
            ref='tableRef'></Table>
        <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange"
            ref="paginationRef"></APagination>
    </a-modal>
</template>
<script>
import FlowStatusSearch from '@/components/Flow/DocumentType/Search/Flowstatus/index'
import Table from '@/components/Table'
import APagination from '@/components/Pagination/index'
export default {
    data() {
        return {
            columns: [
                {
                    title: '编号',
                    dataIndex: 'statusId'
                },
                {
                    title: '单据状态',
                    dataIndex: 'statusDesc',
                    scopedSlots: { customRender: 'rulemsg' }
                }
            ],
            bodyStyle: {
                height: '500px',
                overflowY: 'scroll'
            },
            modalTitle: '',
            pageInfo: {
                page: '',
                limit: ''
            },
            scroll: {
                y: 500
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            },
            editVisible: false,
            title: '',
            billType: '' // 单据类型
        }
    },
    components: {
        Table, APagination, FlowStatusSearch
    },
    methods: {
        show(id, title) {
            this.billType = id
            this.title = title
            this.pageInfo = {
                billTypeId: this.billType,
                page: 1,
                limit: 10
            }
            this.pagination = {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            }
            this.editVisible = true
        },
        // 查询表格
        getFormData(data) {
            if (data) {
                this.pageInfo = data
                this.pageInfo.billTypeId = this.billType
                this.$refs.paginationRef.onChange(1, 10)
                return
            }
            this.pageInfo.billTypeId = this.billType
            this.$refs.tableRef.getList(this.pageInfo)
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.$refs.tableRef.getList(this.pageInfo)
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        }
    }
}
</script>
<style lang="less" scoped>
@deep: ~'>>>';

@{deep} .ant-modal {
    width: 50% !important;
}
</style>
