<template>
    <div>
        <a-modal v-model="editVisible" title="选择切换公司" ok-text="保存" cancel-text="关闭" @ok="saveModelData"
            :bodyStyle='bodyStyle' @cancel="onClose">
            <div v-if='commit' class="fixed-mask">
                <a-spin tip="保存中...">
                </a-spin>
            </div>
            <a-row>
                <a-col span="24">
                    <a-select v-model="company" placeholder="选择公司" allowClear showSearch optionFilterProp="label" :labelInValue="true" style="width: 100%;">
                        <a-spin v-if="fetchingCm" slot="notFoundContent" size="small" />
                        <a-select-option v-for="(item) in companyList" :key="item.companyId" :value="item.companyId"
                            :label="item.companyName">{{ item.companyName }}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>
        </a-modal>
    </div>
</template>
<script>
import Busscommutil from '../../../utils/busscommutil.js'
export default {
    data() {
        return {
            editVisible: false,
            bodyStyle: {
                height: '200px',
                overflowY: 'scroll'
            },
            companyList: [],
            company: [], // 所选公司数据
            fetchingCm: false,
            commit: false
        }
    },
    methods: {
        show() {
            this.company = []
            this.editVisible = true
            this.getCompanyList()
        },
        //  获取公司列表
        getCompanyList() {
            this.$user.doGetClickSysUserCompany().then((res) => {
                this.fetchingCm = false
                this.companyList = res.data
            }).catch(() => {
                this.fetchingCm = false
            })
        },
        saveModelData() {
            if (this.company.length <= 0) {
                this.$message.error('请选择公司')
                return false
            }
            let userInfo = window.sessionStorage.getItem('userInfo')
            if (Busscommutil.checkNull(window.sessionStorage.getItem('userInfo'))) {
                userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
                userInfo.operateUserId = userInfo.id;
                userInfo.operateUserName = userInfo.username;
                userInfo.companyName = this.company.label
                userInfo.company = this.company.key + ''
                this.$user.doUpdateUser(userInfo).then(res => {
                    this.editVisible = false
                    window.sessionStorage.setItem('userInfo', JSON.stringify(userInfo)) // 更新用户信息缓存
                    window.sessionStorage.setItem('filedRuleList', null) // 清空字段规则缓存
                    this.$message.success('切换成功')
                    this.$router.push('/shome')
                    window.location.reload()
                })
            } else {
              this.$router.push('/login')
              this.$store.commit('clearAllSession')
            }
        },
        onClose() {
            this.editVisible = false
            this.company = []
        }
    }
}
</script>

<style scoped lang="less">
@deep: ~'>>>';

@{deep} .ant-modal {
    width: 450px !important;
}
</style>
