<template>
    <div class="left_nav">
        <a-input-search v-model="orgName" style="margin-bottom: 8px" placeholder="输入机构名称查询" allowClear @change="onChange" @search="onSearchOrgname" oninput="value=value.replace(/\s/g,'')"/>
        <a-tree showLine :selectedKeys="selectedKeys" :load-data="onLoadData" :loadedKeys="loadedKeys" :expandedKeys="expandedKeys" :treeData="treeData" :replaceFields="{
            title: 'orgName', key: 'id'
        }" @select="onSelect" @load="loadAfter" @expand="expandTree" v-if="type === 1">
        </a-tree>
        <a-tree showLine :treeData="treeData" :expandedKeys="expandedKeys1" :replaceFields="{
            title: 'orgName', key: 'id'
        }" @select="onSelect" @expand="expandTree" v-if="type === 2">
        </a-tree>
    </div>
</template>

<script src="">
const _ = require('lodash');
export default {
    data() {
        return {
            orgName: '', // 搜索条件
            totalData: [],
            treeData: [],
            expandedKeys: [], // 默认展开的数据
            expandedKeys1: [], // 默认展开的数据
            searchValue: '',
            pageInfo: {
                limit: 10,
                page: 1
            },
            currentParentId: '',
            type: 1, // 1: 树形结构 2：单级结构
            loadedKeys: [], // 已经加载的数据
            selectedKeys: [] // 选中的树节点
        }
    },
    created() {
        this.getAuthData()
    },
    methods: {
        // 获取数据
        getAuthData(orgName) {
            this.loading = true
            this.$organization.doGetSysOrgListByTree(orgName).then(res => {
                this.loading = false
                this.treeData = []
                this.totalData = res.data
                this.totalData.forEach(item => {
                    this.treeData.push({
                        id: item.id,
                        orgName: item.orgName,
                        orgNameAbbr: item.orgNameAbbr,
                        orgRemarkCode: item.orgRemarkCode,
                        level: item.level,
                        status: item.status,
                        children: []
                    })
                })
                this.totalData = this.treeData
            })
        },
        // 获取下一级数据
        onSelect(selectedKeys, info) {
            this.selectedKeys = selectedKeys
            this.currentParentId = selectedKeys[0]
            this.$emit('currentParentId', {
                id: this.currentParentId,
                level: info.selectedNodes[0].data.props.level,
                orgRemarkCode: info.selectedNodes[0].data.props.orgRemarkCode
            })
        },
        // 搜索数据
        onChange(e) {
            // this.orgName = e.target.value
            // if (this.orgName === '') {
            //     this.type = 1
            //     this.treeData = this.totalData
            //     this.expandedKeys1 = []
            // } else {
            //     this.type = 2
            // }
        },
        onSearchOrgname(e) {
            this.orgName = e
            if (this.orgName === '') {
                this.type = 1
                this.treeData = this.totalData
                this.expandedKeys1 = []
            } else {
                this.type = 2
                this.$organization.doGetSysOrgListByTree(this.orgName).then(res => {
                    this.treeData = res.data
                })
            }
        },
        // 根据父级获取下级数据
        doGetSysOrggList(level, parentId, callback) {
            this.$organization.doGetSysOrggList(level, parentId).then(res => {
                if (res.data === null) return false
                callback(res.data)
            })
        },
        // 获取当前节点id以及下面所有子孙节点的id
        getNodeAndChildrenids(data) {
            let temp = [];
            const loop = (data) => {
                data.map(item => {
                    temp.push(item.id)
                    if (item.children && item.children.length) {
                        loop(item.children);
                    }
                })
            }
            loop(data);
            return temp;
        },
        // 更新树组件的数据
        updateTreeData(id) {
            const temp = _.cloneDeep(this.treeData);
            let node;
            const loopFn = (data, id) => {
                if (node) {
                    return;
                }
                data.some((item) => {
                    if (item.id === id) {
                        node = _.cloneDeep(item.children);
                        item.children = [];
                        return true;
                    }
                    if (item.children && item.children.length) {
                        loopFn(item.children, id);
                    }
                });
            };
            // 获取A节点的子节点,并把A节点的children移除
            loopFn(temp, id);
            this.treeData = _.cloneDeep(temp);
            // 获取A节点下面所有子孙节点的id
            const nodeids = this.getNodeAndChildrenids(node);
            // loadedKeys中移除A节点的key
            let loadedKeys = _.cloneDeep(this.loadedKeys.filter(item => item !== id));
            // 去掉A下面已加载的子孙节点的key
            loadedKeys = _.cloneDeep(loadedKeys.filter(item => !nodeids.includes(item)));
            this.loadedKeys = _.cloneDeep(loadedKeys);
            // 去掉A下面已展开的子孙节点id
            this.expandedKeys = _.cloneDeep(this.expandedKeys.filter(item =>
                !nodeids.includes(item)
            ));
        },
        // 展开加载数据
        onLoadData(treeNode) {
            return new Promise(resolve => {
                if (!treeNode) {
                    return;
                }
                this.doGetSysOrggList(treeNode.dataRef.level + 1, treeNode.dataRef.id, (res) => {
                    res.forEach(item => {
                        treeNode.dataRef.children.push({
                            id: item.id,
                            orgName: item.orgName,
                            orgNameAbbr: item.orgNameAbbr,
                            orgRemarkCode: item.orgRemarkCode,
                            level: item.level,
                            status: item.status,
                            children: []
                        })
                    })
                })
                this.treeData = [...this.treeData];
                resolve();
            });
        },
        // 加载完成后触发
        loadAfter(loadedKeys) {
            this.loadedKeys = loadedKeys
        },
        // 展开、关闭节点触发
        expandTree(expandedKeys) {
            if (this.type === 1) {
                this.expandedKeys = expandedKeys
            }
            if (this.type === 2) {
                this.expandedKeys1 = expandedKeys
            }
        },
        // 返回一级
        goBackFirst() {
            this.orgName = ''
            this.type = 1
            this.expandedKeys = []
            this.selectedKeys = []
            this.loadedKeys = []
            this.getAuthData()
            this.$emit('currentParentId', {
                id: 0,
                level: 0,
                orgRemarkCode: ''
            })
        }
    }
}
</script>
<style scoped lang="less">
    .left_nav {
        height: 650px;
        overflow-y: scroll;
        position: relative;
        .fixed-bottom {
            position: absolute;
            bottom: 0;
            left: 43%;
        }
    }
</style>
