import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import sessionUtil from '../api/util/sessionutil';
import { constantRouterMap } from '@/config/router.config'
import { Modal } from 'ant-design-vue'
// breadcrumb: ['左侧1', '左侧1-1']
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes: constantRouterMap,
  // mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    // if (savedPosition) {
    //   return savedPosition // 按下 后退/前进 按钮时，类似浏览器的原生表现
    // } else {
    //   return {
    //     x: 0,
    //     y: 0
    //   } // 让页面滚动到顶部
    // }
    return {
      x: 0,
      y: 0
    } // 让页面滚动到顶部
  }
})
// 为路由对象添加 beforeEach,导航守卫
router.beforeEach((to, form, next) => {
  // to:将要到哪个路径
  // form:从哪个路径来
  // next():放行函数
  // next('/login') 强制跳转到login页面
  // officialaccounts 公众号页面
  // let toDepth = to.path.split('/login').length
  // let fromDepth = form.path.split('/login').length
  // if (toDepth < fromDepth) {
  //   // console.log('back...')
  //   form.meta.keepAlive = false
  //   to.meta.keepAlive = true
  // }
  const tokenStr = window.sessionStorage.getItem('token')
  const sessionTime = sessionUtil.getSession('sessionTime');
  const whiteList = ['/login', '/officialaccounts', './']; // 路由白名单，不需要校验
  let passWhite = whiteList.some(item => {
    return item === to.path
  })
  if (passWhite) return next()
  else {
    if (!tokenStr) {
      return next('/login')
    } else if (!sessionTime) {
      Modal.error({
        title: '登录已过期',
        content: '很抱歉，登录已过期，请重新登录',
        okText: '重新登录',
        mask: false,
        onOk: () => {
          next('/login');
          store.commit('clearAllSession')
        }
      })
    } else {
      next()
    }
  }
})
export default router
