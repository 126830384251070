<template>
  <!--  -->
  <div style="width: 246px;" class="leftmenu">
    <a-menu :mode="mode" :defaultOpenKeys="[$route.path]" :selectedKeys="[$route.path]" :inline-collapsed="collapsed"
      :openKeys="openKeys" @click="eventMenuClick" @openChange="onOpenChange">
      <!-- <template v-for="item in menuData">
        <a-sub-menu :key="item.path" v-if="item.auth">
          <span slot="title">
            <a-icon :type="item.icon" /><span>{{ item.name }}</span>
          </span>
          <template v-for="subItem in item.children">
            <a-menu-item :key="subItem.path" v-if="subItem.auth">{{ subItem.name }}</a-menu-item>
          </template>
        </a-sub-menu>
      </template> -->
      <a-sub-menu v-for="item in menuData" :key="item.menuMark">
        <span slot="title">
          <a-icon :type="item.icon" /><span>{{ item.menuName }}</span>
        </span>
          <a-menu-item v-for="subItem in item.children" :key="subItem.menuMark">{{ subItem.menuName }}</a-menu-item>
      </a-sub-menu>
    </a-menu>
  </div>
</template>
<script>
import { postAction } from '../../api/manage';
import qs from 'qs'
import store from '../../store'
export default {
  name: 'leftmenu',
  data() {
    return {
      collapsed: false,
      mode: 'inline',
      menuData: [],
      openKeys: [''],
      latestOpenKey: [],
      allCheckedBar: []
    };
  },
  created() {
    this.getMenuList()
  },
  mounted() {
  },
  methods: {
    // 获取菜单列表
    getMenuList() {
      let _this = this
      // postAction('/api/v1/manager/PermissionApi/doGetAdminMenu', qs.stringify({
      //   mobile: this.$store.state.userInfo.mobile
      // })).then((res) => {
      //   if (res.status === 200) {
      //     _this.menuData = res.data.menuData
      //     _this.xhmenu()
      //   }
      // })
      this.$pcmenu.doGetPcMenuAuthByArea().then(res => {
          _this.menuData = res.data
          _this.xhmenu()
      })
    },
    xhmenu() {
      const Menus = this.menuData
      for (let i = 0; i < Menus.length; i++) {
        if (Menus[i].children) {
          for (let y = 0; y < Menus[i].children.length; y++) {
            this.latestOpenKey.push(Menus[i].children[y].path)
            if (Menus[i].children[y].path === this.$route.path) {
              this.openKeys = [Menus[i].path]
            }
          }
        }
      }
    },
    onOpenChange(openKeys) { // 当菜单被展开时触发此处
      /*
      经测试传入的变量openKeys是数组 点击已经展开的菜单时传入的是空数组
      点击未展开的菜单时传入的是[当前展开菜单的key,点击的菜单key]
      下面的if判断用openKeys === [] 无效 所以我只能判断数组length是否等于0
      */
      if (openKeys.length !== 0) {
        this.openKeys = [openKeys[openKeys.length - 1]]
      } else {
        this.openKeys = ['']
      }
    },
    eventMenuClick({ item, key, keyPath }) {
      this.$router.push(key)
    },
    toggleCollapsed() {
      if (!this.collapsed) {
        this.openKeys = []
      } else {
        this.xhmenu()
      }
      this.collapsed = !this.collapsed;
    }
  }
}
</script>
<style scoped lang="less">
.leftmenu {
  .ant-menu-dark .ant-menu-item-selected .anticon {
    color: #fff;
  }

  .ant-menu-submenu-selected {
    color: #1F8839 !important;
  }
}
</style>
