<!-- 分类类别 -->
<template>
    <div class="public-bg category-type">
        <FllbFormList @formData="getFormData" @add="addCompanyType"></FllbFormList>
        <Table :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type='fllb' ref='tableRef' @edit="updateData" @delete="getFormData" @preserve="preserveData"></Table>
        <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange" ref="paginationRef"></APagination>
        <!-- 新增分类类别 -->
        <FllbFormModelList ref="CategoryTypeEditRef" :modalTitle="modalTitle" @updateList='getFormData'></FllbFormModelList>
        <!-- 维护分类字典 -->
        <FlzdModal ref="flzdlist"></FlzdModal>
    </div>
</template>
<script>
import FllbFormList from '@/components/DictionaryData/Fllb/Search/FllbFormList/index'
import FllbFormModelList from '@/components/DictionaryData/Fllb/Modal/FllbFormModelList/index'
import FlzdModal from '@/components/DictionaryData/Fllb/Modal/FlzdModal/index'
import Table from '@/components/Table'
import APagination from '@/components/Pagination/index'
export default {
    name: 'category-type',
    components: { FllbFormList, Table, APagination, FllbFormModelList, FlzdModal },
    data() {
        const columns = [
            {
                title: '类别编码',
                dataIndex: 'id'
            },
            {
                title: '类别名称',
                dataIndex: 'dictName'
            },
            {
                title: '创建时间',
                dataIndex: 'createTime',
                width: '18%'
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                width: '18%'
            },
            {
                title: '是否禁用',
                dataIndex: 'disableDesc',
                width: '18%'
            },
            {
                title: '操作',
                dataIndex: 'operation',
                scopedSlots: { customRender: 'fllb' }
            }
        ];
        return {
            columns,
            modalTitle: '',
            pageInfo: {
                page: '',
                limit: ''
            },
            scroll: {
                y: 500
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            }
        }
    },
    methods: {
        // 查询表格
        getFormData(data) {
            if (data) {
                this.pageInfo = data
                this.$refs.paginationRef.onChange(1, 10)
                return
            }
            this.$refs.tableRef.getList(this.pageInfo)
        },
        updateData(row) {
            this.modalTitle = '修改'
            let obj = { ...row }
            this.$refs.CategoryTypeEditRef.getFormData(obj)
        },
        addCompanyType() {
            let obj = {
                dictName: '',
                disable: 0
            }
            this.modalTitle = '新增'
            this.$refs.CategoryTypeEditRef.getFormData(obj)
        },
        // 维护
        preserveData(row) {
            this.$refs.flzdlist.show(row)
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.$refs.tableRef.getList(this.pageInfo)
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        }
    }
}
</script>
