<template>
    <div>
        <a-modal v-model="editVisible" title="复制" ok-text="保存" cancel-text="关闭" @ok="saveModelData"
            :bodyStyle='bodyStyle' @cancel="onClose">
            <div v-if='commit' class="fixed-mask">
                <a-spin tip="保存中...">
                </a-spin>
            </div>
            <a-row>
                <a-col span="24">
                    <a-select v-model="company" placeholder="选择公司" allowClear showSearch optionFilterProp="label"
                        :mode="modeCompany" style="width: 100%;">
                        <a-spin v-if="fetchingCm" slot="notFoundContent" size="small" />
                        <a-select-option v-for="(item) in companyList" :key="item.id" :value="item.id"
                            :label="item.companyname">{{ item.companyname }}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>
        </a-modal>
    </div>
</template>
<script>
import Busscommutil from '../../../../utils/busscommutil'
export default {
    data() {
        return {
            editVisible: false,
            bodyStyle: {
                height: '450px',
                overflowY: 'scroll'
            },
            companyList: [],
            id: '', // 复制信息id
            company: [], // 所选公司id
            fetchingCm: false,
            commit: false,
            modeCompany: 'multiple',
            companyid: '' // 复制公司id
        }
    },
    methods: {
        show(row) {
            this.editVisible = true
            this.id = row.id
            this.companyid = row.companyId
            this.getCompanyList()
        },
        //  获取公司列表
        getCompanyList() {
            let companylist = Busscommutil.checkNull(this.$store.state.companylist)
            if (companylist) {
                this.companyList = this.$store.state.companylist
            } else {
                this.fetchingCm = true
                this.$company.doGetCompanyByDisable('', 0, '').then((res) => {
                    this.fetchingCm = false
                    this.$store.commit('saveCompanyList', res.data);
                    this.companyList = res.data
                }).catch(() => {
                    this.fetchingCm = false
                })
            }
        },
        saveModelData() {
            if (this.company.length <= 0) {
                this.$message.error('请选择公司')
                return false
            }
            let company = JSON.stringify({
                companyIds: this.company
            })
            this.commit = true
            this.$pcmenu.doCopyPcMenuRole(company, this.id).then(res => {
                this.commit = false
                this.company = []
                this.editVisible = false
                this.$message.success('复制成功')
                this.$emit('update')
            }).catch(() => {
                this.commit = false
            })
        },
        onClose() {
            this.editVisible = false
            this.company = []
        }
    }
}
</script>
