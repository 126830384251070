<!-- 公司管理 -->
<template>
    <div class="dxxj public-bg">
        <GsglFormList @formData="getFormData" @add="addCompany"></GsglFormList>
        <ATable :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type="gsgl"
            ref="tableRef" @edit="editCompany" @disable="changeDisable"></ATable>
        <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange"
            ref="paginationRef"></APagination>
        <GsglFormModelList ref="rmModelref" @update="updateData"></GsglFormModelList>
    </div>
</template>
<script>
import GsglFormList from '@/components/FormList/GsglFormList/index'
import APagination from '@/components/Pagination/index'
import ATable from '@/components/Table/index'
import GsglFormModelList from '@/components/FormListModel/Gsgl/GsglFormModelList/index'

export default {
    data() {
        return {
            columns: [
                {
                    title: '公司ID',
                    dataIndex: 'id'
                },
                {
                    title: '公司名称',
                    dataIndex: 'companyname',
                    width: '20%',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '是否禁用',
                    dataIndex: 'disable',
                    scopedSlots: { customRender: 'comapnydisable' }
                }, {
                    title: '创建人',
                    dataIndex: 'createBy',
                    scopedSlots: { customRender: 'rulemsg' }
                }, {
                    title: '创建时间',
                    dataIndex: 'createTime'
                }, {
                    title: '更新人',
                    dataIndex: 'updateBy',
                    scopedSlots: { customRender: 'rulemsg' }
                }, {
                    title: '更新时间',
                    dataIndex: 'updateTime'
                }, {
                    title: '操作',
                    dataIndex: 'operation2',
                    scopedSlots: { customRender: 'gsgl' }
                }],
            pageInfo: {
                page: '',
                limit: '',
                companyName: ''
            },
            selectedRowKeys: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            },
            scroll: {
                y: 500
            }
        }
    },
    components: {
        GsglFormList, APagination, ATable, GsglFormModelList
    },
    created() {
    },
    methods: {
        // 查询表格数据
        getFormData(data) {
            if (data) {
                this.pageInfo = data
                this.$refs.paginationRef.onChange(1, 10)
                return
            }
            this.$refs.tableRef.getList(this.pageInfo)
        },
        addCompany() {
            let val = {
                companyname: '',
                disable: 0
            }
            let obj = { ...val }
            this.$refs.rmModelref.getFormData(obj, '新增')
        },
        // 修改
        editCompany(val) {
            let obj = { ...val }
            this.$refs.rmModelref.getFormData(obj, '修改')
        },
        // 更新
        updateData() {
            this.getFormData()
        },
        doSelect(val) {
            this.selectedRowKeys = val
        },
        // 修改是否禁用
        changeDisable(row) {
            row.updateBy = this.$store.state.userInfo.username
            this.$company.doUpdateCompany(row).then(res => {
              this.$message.success('修改成功')
            }).catch(() => {
            })
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.$refs.tableRef.getList(this.pageInfo)
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        }
    }
}
</script>
<style scoped lang="less">
@deep: ~'>>>';
</style>
