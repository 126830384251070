/**
  * axios封装
  * 请求拦截、响应拦截、错误统一处理
  */
import axios from 'axios';
import router from '../router';
import { Message } from 'ant-design-vue';
// 环境的切换
var CONFIG_ENV = window.g.CONFIG_ENV;
if (process.env.NODE_ENV === 'production') {
    if (CONFIG_ENV === 'production') {
        axios.defaults.baseURL = window.g.ApiUrl;
    }
    if (CONFIG_ENV === 'test') {
        axios.defaults.baseURL = window.g.TestApiUrl;
    }
}
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
}
/**
  * 提示函数
  * 禁止点击蒙层、显示一秒后关闭
  */
const tip = msg => {
    Message.error(msg)
}

/**
  * 跳转登录页
  * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
  */
const toLogin = () => {
    router.replace({
        path: '/login',
        query: {
            redirect: router.currentRoute.fullPath
        }
    });
}

/**
  * 请求失败后的错误统一处理
  * @param {Number} status 请求失败的状态码
  */
const errorHandle = (status, other) => {
    console.log(status)
    switch (status) {
        case 400:
        case 500:
        case 503:
            tip(other);
            break
        // 404请求不存在
        case 404:
            tip('接口不存在');
            break;
        case 502:
            tip('服务繁忙，请稍后再试');
            break;
        default:
            console.log('服务异常');
    }
}
// 设置响应超时时间
var timeout = window.g.AXIOS_TIMEOUT
// 创建axios实例
var instance = axios.create({ timeout: timeout });
// 设置post请求头
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
/**
  * 请求拦截器
  * 每次请求前，如果存在token则在请求头中携带token
  */
instance.interceptors.request.use(
    config => {
        // 登录流程控制中，根据本地是否存在token判断用户的登录情况
        // 但是即使token存在，也有可能token是过期的，所以在每次的请求头中携带token
        // 后台根据携带的token判断用户的登录情况，并返回给我们对应的状态码
        // 而后我们可以在响应拦截器中，根据状态码进行一些统一的操作。
        config.headers.token = window.sessionStorage.getItem('token')
        config.headers.userToken = window.sessionStorage.getItem('token')
         return config;
    },
    error => Promise.error(error))

// 响应拦截器
instance.interceptors.response.use(
    // 请求成功
    res => {
        if (res.data.code === 0) {
            return Promise.resolve(res)
        }
        if (res.data.code) {
            if (res.data.code === '4001') {
                tip(res.data.msg)
                toLogin()
            } else {
                return Promise.resolve(res)
            }
        } else {
            if (res.data.status === 200) {
                return Promise.resolve(res)
            } else {
                if (res.code && res.code !== 200) {
                    errorHandle(res.code, res.message);
                }
                errorHandle(res.data.status, res.data.msg);
                return Promise.reject(res)
            }
        }
    },
    // 请求失败
    error => {
        const { response } = error;
        if (response) {
            // 请求已发出，但是不在2xx的范围
            errorHandle(response.status, response.data.msg || response.data.message);
            return Promise.reject(response);
        } else {
            if (error.message.includes('timeout')) { // 判断请求异常信息中是否含有超时timeout字符串
                tip('请求超时，请稍后再试')
                return Promise.reject(error); // reject这个错误信息
            }
            console.log(error)
            tip('网络超时，请稍后再试')
            return Promise.reject(error);
        }
    });
export default instance
