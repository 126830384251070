<template>
<a-config-provider :locale="zhCN">
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');

export default {
  provide () {
    return {
      style: '',
      reload: this.reload
    }
  },
  mounted () {
    // document.getElementById('app').style.minHeight = window.screen.availHeight + 'px'
    // window.onresize = () => {
    //   document.getElementById('app').style.minHeight = window.screen.availHeight + 'px'
    // }
  },
  data () {
    return {
      style: '',
      isRouterAlive: true,
      zhCN
    }
  },
  created() {
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>
<style>
#app {
  background: #f2f2f2;
}
</style>
