import { getAction, postAction, postActionQs } from '@/api/manage'

/**
  * 物资审批
  */
const base = {
    matApi: '/api/v1/manager/matApi/'
}
//   获取物资列表
const doGetMatList = (params) => postActionQs(base.matApi + 'doGetMatList', params)
//   获取物资明细列表根据单号
const doGetMatDetailList = (params) => postActionQs(base.matApi + 'doGetMatDetailList', params)
export default {
    doGetMatList,
    doGetMatDetailList
}
