<template>
  <div class="pagetitle">
    <!-- update-begin- author:sunjianlei --- date:20191009 --- for: 提升右键菜单的层级 -->
    <contextmenu :itemList="menuItemList" :visible.sync="menuVisible" style="z-index: 9999;" @select="onMenuSelect" />
    <!-- update-end- author:sunjianlei --- date:20191009 --- for: 提升右键菜单的层级 -->
    <a-tabs :hide-add="true" type="editable-card" :active-key="activePage" style="height:52px" @change="changePage"
      @contextmenu.native="e => onContextmenu(e)" @edit="editPage" class="tab-layout-tabs">
      <a-tab-pane :id="page.fullPath" :key="page.fullPath" v-for="page in pageList"
        :closable="!(page.meta.breadcrumb[1]==='')">
        <span slot="tab" :pagekey="page.fullPath">{{ page.meta.breadcrumb[1] }}</span>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>
import Contextmenu from '@/components/menu/Contextmenu'
const indexKey = '/shome'
export default {
  name: 'pagetitle',
  components: {
    Contextmenu
  },
  data() {
    return {
      current: 0,
      pageList: [],
      linkList: [],
      activePage: '',
      menuVisible: false, // 右击显示卡片
      menuItemList: [
        { key: '1', icon: 'arrow-left', text: '关闭左侧' },
        { key: '2', icon: 'arrow-right', text: '关闭右侧' },
        { key: '3', icon: 'close', text: '关闭其它' }
      ],
      multipage: false, // 单页模式
      reloadFlag: false // 是否显示刷新
    };
  },
  watch: {
    $route: function (newRoute) {
      this.activePage = newRoute.fullPath
      if (this.linkList.indexOf(newRoute.fullPath) < 0) {
        this.linkList.push(newRoute.fullPath)
        this.pageList.push(Object.assign({}, newRoute))
      } else if (this.linkList.indexOf(newRoute.fullPath) >= 0) {
        let oldIndex = this.linkList.indexOf(newRoute.fullPath)
        let oldPositionRoute = this.pageList[oldIndex]
        this.pageList.splice(oldIndex, 1, Object.assign({}, newRoute, { meta: oldPositionRoute.meta }))
      }
    },
    activePage: function (key) {
      let index = this.linkList.lastIndexOf(key)
      let waitRouter = this.pageList[index]
      // 【TESTA-523】修复：不允许重复跳转路由异常
      if (waitRouter.fullPath !== this.$route.fullPath) {
        this.$router.push(Object.assign({}, waitRouter))
      }
      this.changeTitle(waitRouter.meta.breadcrumb[1])
    }
  },
  created() {
    // 复制一个route对象出来，不能影响原route
    let currentRoute = Object.assign({}, this.$route)
    currentRoute.meta = Object.assign({}, currentRoute.meta)
    this.pageList.push(currentRoute)
    this.linkList.push(currentRoute.fullPath)
    this.activePage = currentRoute.fullPath
  },
  methods: {
    changePage(key) {
      this.activePage = key
    },
    editPage(key, action) {
      this[action](key)
    },
    remove(key) {
      if (this.pageList.length === 1) {
        this.$message.warning('这是最后一页，不能再关闭了啦')
        return
      }
      this.pageList = this.pageList.filter(item => item.fullPath !== key)
      let index = this.linkList.indexOf(key)
      this.linkList = this.linkList.filter(item => item !== key)
      index = index >= this.linkList.length ? this.linkList.length - 1 : index
      this.activePage = this.linkList[index]
    },
    // update-begin-author:sunjianlei date:20200120 for: 动态更改页面标题
    changeTitle(title) {
      let projectTitle = '零星物资'
      // 首页特殊处理
      if (this.$route.path === indexKey) {
        document.title = projectTitle
      } else {
        document.title = title + ' · ' + projectTitle
      }
    },
    // update-end-author:sunjianlei date:20200120 for: 动态更改页面标题
    onContextmenu(e) {
      const pagekey = this.getPageKey(e.target)
      if (pagekey !== null) {
        e.preventDefault()
        this.menuVisible = true
      }
    },
    getPageKey(target, depth) {
      depth = depth || 0
      if (depth > 2) {
        return null
      }
      let pageKey = target.getAttribute('pagekey')
      pageKey = pageKey || (target.previousElementSibling ? target.previousElementSibling.getAttribute('pagekey') : null)
      return pageKey || (target.firstElementChild ? this.getPageKey(target.firstElementChild, ++depth) : null)
    },
    onMenuSelect(key, target) {
      let pageKey = this.getPageKey(target)
      switch (key) {
        case '1':
          this.closeLeft(pageKey)
          break
        case '2':
          this.closeRight(pageKey)
          break
        case '3':
          this.closeOthers(pageKey)
          break
        case '4':
          this.routeReload()
          break
        default:
          break
      }
    },
    /* update_begin author:wuxianquan date:20190828 for: 关闭当前tab页，供子页面调用->望菜单能配置外链，直接弹出新页面而不是嵌入iframe #428 */
    closeCurrent() {
      this.remove(this.activePage);
    },
    /* update_end author:wuxianquan date:20190828 for: 关闭当前tab页，供子页面调用->望菜单能配置外链，直接弹出新页面而不是嵌入iframe #428 */
    closeOthers(pageKey) {
      let index = this.linkList.indexOf(pageKey)
      if (pageKey.indexOf('?ticke=') >= 0) {
        this.linkList = this.linkList.slice(index, index + 1)
        this.pageList = this.pageList.slice(index, index + 1)
        this.activePage = this.linkList[0]
      } else {
        let indexContent = this.pageList.slice(0, 1)[0]
        this.linkList = this.linkList.slice(index, index + 1)
        this.pageList = this.pageList.slice(index, index + 1)
        // this.linkList.unshift(indexContent.fullPath)
        // this.pageList.unshift(indexContent)
        this.activePage = this.linkList[0]
      }
    },
    closeLeft(pageKey) {
      let tempList = [...this.pageList]
      let indexContent = tempList.slice(0, 1)[0]
      let index = this.linkList.indexOf(pageKey)
      this.linkList = this.linkList.slice(index)
      this.pageList = this.pageList.slice(index)
      // this.linkList.unshift(indexContent.fullPath)
      // this.pageList.unshift(indexContent)
      if (this.linkList.indexOf(this.activePage) < 0) {
        this.activePage = this.linkList[0]
      }
    },
    closeRight(pageKey) {
      let index = this.linkList.indexOf(pageKey)
      this.linkList = this.linkList.slice(0, index + 1)
      this.pageList = this.pageList.slice(0, index + 1)
      if (this.linkList.indexOf(this.activePage < 0)) {
        this.activePage = this.linkList[this.linkList.length - 1]
      }
    },
    // update-begin-author:taoyan date:20191008 for:路由刷新
    routeReload() {
      this.reloadFlag = false
      this.multipage = false
      this.$nextTick(() => {
        this.multipage = true
        this.reloadFlag = true
      })
    }
  }
};
</script>
<style lang="less" scoped>
.pagetitle {
  background: #fff;
  margin-top: 15px;
}
</style>
