<template>
  <a-modal v-model="editVisible" :title="modalTitle" ok-text="保存" cancel-text="关闭" @ok="saveModelData"
    :bodyStyle='bodyStyle' :destroyOnClose="true" :maskClosable="false">
    <div class="fixed-mask" v-if="fetching">
      <a-spin size="large" tip="保存中..." />
    </div>
    <a-form-model class="editFormList" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
      :wrapper-col="wrapperCol">
      <a-form-model-item label="单据类型" prop="billType">
        <a-select v-model="form.billType" placeholder="直接选择或搜索选择" allowClear showSearch optionFilterProp="label"
          @change="changeBillType">
          <a-select-option :label="item.billTypeDesc" v-for="(item) in billTypeList" :key="item.id" :value="item.id">{{ item.billTypeDesc }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="节点名称" prop="nodeId">
        <a-select v-model="form.nodeId" placeholder="直接选择或搜索选择" allowClear showSearch optionFilterProp="label"
         >
          <a-select-option :label="item.nodeName" v-for="(item) in nodeList" :key="item.id" :value="item.id">{{ item.nodeName }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="审批人" prop="userId">
        <a-select v-model="form.userId" placeholder="直接选择或搜索选择" allowClear showSearch optionFilterProp="label"
          :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }" @popupScroll="popupScroll" @search="searchChange"
          @change="changeUser" :defaultActiveFirstOption="false">
          <a-spin v-if="fetching1" slot="notFoundContent" size="small" />
          <a-select-option :value="item.id" v-for="(item) in subUsersList" :key="item.id" :label="item.username"> {{ item.username }} {{ item.mobile }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否禁用" prop="disable">
        <a-radio-group v-model="form.disable">
          <a-radio :value="1">
            禁用
          </a-radio>
          <a-radio :value="0">
            启用
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
export default {
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      editVisible: false,
      form: {
        billType: '',
        billTypeName: '',
        nodeId: '',
        nodeName: '',
        disable: ''
      },
      rules: {
        billType: [{ required: true, message: '请选择单据类型', trigger: 'change' }],
        nodeId: [{ required: true, message: '请选择节点名称', trigger: 'change' }],
        userId: [{ required: true, message: '请选择审批人', trigger: 'change' }],
        disable: [{ required: true, message: '请选择是否禁用', trigger: 'change' }]
      },
      bodyStyle: {
        height: '300px',
        overflowY: 'scroll'
      },
      modalTitle: '',
      fetching: false, // 是否加载完成
      billTypeList: [], // 单据类型列表
      nodeList: [], // 审批人列表
      usersList: [], // 审批人列表
      // 审批人加载start
      pageNum: 0, // 审批人分页数
      pageApi: {
        limit: 15,
        page: 1,
        username: '',
        userId: '',
        companyId: ''
      },
      fetching1: false,
      subUsersList: []
      // 审批人加载end
    };
  },
  components: {
  },
  watch: {
    // 去掉表单验证信息二次打开残留
    editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
      // 监视dialog状态（打开、关闭）
      if (val) {
        try {
          this.$refs.ruleForm.resetFields() // 重置编辑表单
          // addForm 为ref指向的表单
        } catch (e) { }
      }
    }
  },
  created() {
  },
  methods: {
    show(val, title) {
      this.doGetFlowBillTypeAll()
      this.modalTitle = title
      this.form = val
      this.editVisible = true
      // 用户列表初始化
      this.pageApi = {
        limit: 15,
        page: 1
      }
      this.usersList = []
      this.subUsersList = []
      // 节点初始化
      this.nodeList = []
      if (title === '新增') {
      }
      if (title === '修改') {
        // 用户列表初始化
        this.pageApi.userId = val.userid
        this.doGetFlowBillNodeByType(val.billType)
      }
      this.getUsersList()
    },
    // 获取单据类型列表
    doGetFlowBillTypeAll() {
      this.$flow.doGetFlowBillTypeAll().then(res => {
        this.billTypeList = res.data
      })
    },
    // 获取单据类型id
    getConnectBillType() {
      this.billTypeList.forEach(item => {
        if (item.id === this.form.billType) {
          this.form.billTypeName = item.billTypeDesc
        }
      })
    },
    // 获取流程模型根据单据类型
    doGetFlowBillNodeByType(billType) {
      this.$flow.doGetFlowBillNodeByType(billType).then(res => {
        this.nodeList = res.data
      })
    },
    // 获取流程模型id
    getConnectNode() {
      this.nodeList.forEach(item => {
        if (item.id === this.form.nodeId) {
          this.form.nodeName = item.nodeName
        }
      })
    },
    // 获取审批人列表
    getUsersList() {
      this.fetching1 = true
      this.$user.doGetUserListNoAuth(this.pageApi).then(res => {
        this.fetching1 = false
        this.usersList = res.data.rows
        this.subUsersList = this.subUsersList.concat(res.data.rows)
        this.pageNum = res.data.pages
      })
    },
    // 获取审批人id
    getConnectUser() {
      this.usersList.forEach(item => {
        if (item.id === this.form.userId) {
          this.form.userName = item.username
        }
      })
    },
    changeUser(value, options) {
      this.pageApi.userId = '' // 审批人id置空
      if (options) {
        this.pageApi.username = options.componentOptions.propsData.label
      } else {
        this.pageApi.username = ''
      }
      this.pageApi.page = 1
      this.subUsersList = []
      this.getUsersList()
    },
    searchChange(value) {
      this.pageApi.userId = '' // 审批人id置空
      this.pageApi.username = value
      this.subUsersList = []
      this.pageApi.page = 1
      this.getUsersList()
    },
    // 触底获取审批人数据
    popupScroll(e) {
      const { target } = e;
      const scrllHeight = target.scrollHeight - target.scrollTop; // scrollHeight: ul的实际高度
      const clientHeight = target.clientHeight; // 250px
      // 下拉框不下拉的时候
      if (scrllHeight === 0 && clientHeight === 0) {
        this.pageApi.page = 1;
      } else if (scrllHeight - clientHeight === 0) { // 下拉到底部时
        if (this.pageApi.page < this.pageNum) {
          // 如果滑到底部，则加载下一页
          this.pageApi.page++
          this.getUsersList()
        }
      }
    },
    // 更改单据类型
    changeBillType(e) {
      this.$set(this.form, 'nodeId', '')
      if (e) {
        this.doGetFlowBillNodeByType(e)
      } else {
        this.nodeList = []
      }
    },
    saveModelData() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.fetching = true
          this.getConnectUser()
          this.getConnectBillType()
          this.getConnectNode()
          if (this.modalTitle === '新增') {
            this.$flow.doAddFlowBillNodeUser(this.form).then(res => {
              this.$message.success('新增成功')
              this.fetching = false
              this.editVisible = false
              this.$emit('update')
            }).catch(() => {
              this.fetching = false
            })
          }
          if (this.modalTitle === '修改') {
            this.$flow.doUpdateFlowBillNodeUser(this.form).then(res => {
              this.$message.success('修改成功')
              this.fetching = false
              this.editVisible = false
              this.$emit('update')
            }).catch(() => {
              this.fetching = false
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>
<style scoped lang="less"></style>
