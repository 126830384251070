import { getAction, deleteAction, putAction, postAction, postActionQs, httpAction } from '@/api/manage'
import Vue from 'vue'

/**
  * 分类字典
  */
const base = {
    dictApi: '/api/v1/manager/dictApi/', // 分类类别
    dictItemApi: '/api/v1/manager/dictItemApi/' // 分类字典
}

// 获取分类类别列表
const doGetDictList = (params) => postActionQs(base.dictApi + 'doGetDictList', params)
// 获取分类类别全部
const doGetDictAll = (params) => postActionQs(base.dictApi + 'doGetDictAll', params)
// 新增分类类别
const doAddDict = (dictName, disable) => postActionQs(base.dictApi + 'doAddDict', {
    dictName: dictName, // 名称
    disable: disable // 是否禁用
 })
// 更新分类类别
const doUpdateDict = (id, dictName, disable) => postActionQs(base.dictApi + 'doUpdateDict', {
    id: id,
    dictName: dictName, // 名称
    disable: disable // 是否禁用
})
// 删除分类类别
const doDelDict = (id) => postActionQs(base.dictApi + 'doDelDict', {
    id: id
})
// 获取分类字典根据类别编码
const doGetDictItems = (dictId) => postActionQs(base.dictItemApi + 'doGetDictItems', {
    dictId: dictId
})
// 新增分类字典
const doAddDictItem = (params) => postAction(base.dictItemApi + 'doAddDictItem', params)
const doUpdateDictItem = (params) => postActionQs(base.dictItemApi + 'doUpdateDictItem', params)
// 获取分类字典列表
const doGetDictItemList = (params) => postActionQs(base.dictItemApi + 'doGetDictItemList', params)
// 删除分类字典
const doDelDictItem = (id) => postActionQs(base.dictItemApi + 'doDelDictItem', {
    id: id
})
export default {
    doGetDictList,
    doAddDict,
    doUpdateDict,
    doDelDict,
    doGetDictItems,
    doAddDictItem,
    doGetDictItemList,
    doDelDictItem,
    doUpdateDictItem,
    doGetDictAll
}
