<template>
  <div class="home">
    <div style="position: fixed;background: #fff;top: 0;height: 100%;overflow-y: scroll;overflow-x: hidden;"
      :style="{width:(collapsed ? '80px':'256px')}">
      <div class="page-head">
        <div style="font-size: 20px;font-weight:normal"><router-link to="./shome">
            <img src="../../../public/images/logo.png" alt="" width="30px">
            <span v-if="!collapsed">零星物资</span></router-link></div>
      </div>
      <LeftMenu ref="leftmenurefs"></LeftMenu>
    </div>
    <div style="height: 100%;position: absolute;right: 0;"
      :style="{width:(collapsed ? 'calc(100% - 80px)':'calc(100% - 256px)')}">
      <PageHead :collapsed='collapsed' @changeStatus="changeCollapsed"></PageHead>
      <div class="content"
        style="background: #f0f2f5; padding: 12px; height: calc(100% - 122px); min-height: 280px;margin-top: 122px">
        <router-view />
      </div>
      <a-layout-footer :style="{ textAlign: 'center' }">
        Lingxing ©2024 {{ version }}
      </a-layout-footer>
    </div>
  </div>
</template>
<script>
import PageHead from '@/components/PageHead/index'
import LeftMenu from '@/components/LeftMenu/index'
export default {
  components: {
    LeftMenu, PageHead
  },
  data() {
    return {
      collapsed: false,
      version: window.g.version
    };
  },
  mounted() {
    this.collapsed = this.$refs.leftmenurefs.collapsed
  },
  // computed: {
  //   collapsed() {
  //     this.collapsed = !this.collapsed
  //   }
  // },
  methods: {
    changeCollapsed() {
      this.$refs.leftmenurefs.toggleCollapsed()
      this.collapsed = !this.collapsed
    }
  }
};
</script>

<style lang="less" scoped>
body {
    margin: 0;
    overflow: hidden;
    background: linear-gradient(to bottom, #1F8839, #095f88);
    background-size:1400% 300%;
    animation: dynamics 6s ease infinite;
    -webkit-animation: dynamics 6s ease infinite;
    -moz-animation: dynamics 6s ease infinite;
    font-size: 14px;
    color: #ffffff;
    div{
        font-size: 0;
    }
}
.home {
 .page-head{
     width: 100%;
     display: flex;
     justify-content: space-between;
     align-items: center;
     height: 60px;
     padding: 0 15px 0 25px;
     box-sizing: border-box;
     color: #fff;
     font-weight: bold;
     a {
      line-height: 30px;
      display: flex;
      color: #1F8839;
      font-weight: bold;
      span {
        margin-left: 10px;
      }
     }
 }
  .ant-layout-content {
    height: 100%;
  }
}
</style>
