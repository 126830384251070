import Vue from 'vue'
import Vuex from 'vuex'
import jsecrypt from '../utils/jsecrypt'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: window.sessionStorage.getItem('token') || '',
    userInfo: JSON.parse(window.sessionStorage.getItem('userInfo')) || null,
    companylist: JSON.parse(window.sessionStorage.getItem('companylist')) || null,
    filedRuleList: JSON.parse(window.sessionStorage.getItem('filedRuleList')) || '', // 字段校验规则
    companyalllist: JSON.parse(window.sessionStorage.getItem('companyalllist')) || null // 公司列表（包含禁用）
  },
  mutations: {
    saveUserInfo(state, userInfo) {
      state.userInfo = userInfo
      window.sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    saveToken (state, authInfo) {
      state.token = authInfo.token
      window.sessionStorage.setItem('token', authInfo.token)
    },
    clearToken(state) {
      state.token = ''
      window.sessionStorage.removeItem('token')
    },
    clearAllSession(state) {
      state.token = ''
      state.userInfo = ''
      state.companylist = null
      state.filedRuleList = null
      state.companyalllist = null
      window.sessionStorage.removeItem('token')
      window.sessionStorage.removeItem('userInfo')
      window.sessionStorage.removeItem('companylist')
      window.sessionStorage.removeItem('filedRuleList')
      window.sessionStorage.removeItem('companyalllist')
      window.sessionStorage.removeItem('sessionTime')
    },
    changeNetwork(state, flag) {
      state.networkSuccess = flag
    },
    // 公司列表数据
    saveCompanyList(state, companylist) {
      state.companylist = companylist
      window.sessionStorage.setItem('companylist', JSON.stringify(companylist))
    },
    // 字段校验规则
    saveFiledRuleList(state, list) {
      state.filedRuleList = list
      window.sessionStorage.setItem('filedRuleList', JSON.stringify(list))
    },
    // 公司列表数据(包含禁用)
    saveCompanyAllList(state, companyalllist) {
      state.companyalllist = companyalllist
      window.sessionStorage.setItem('companyalllist', JSON.stringify(companyalllist))
    }
  },
  actions: {
    commitSaveUserInfo: ({ commit }, userInfo) => commit('saveUserInfo', userInfo),
    commitSaveToken: ({ commit }, authInfo) => commit('saveToken', authInfo),
    commitClearToken: ({ commit }) => commit('clearToken'),
    commitAllClearSession: ({ commit }) => commit('clearAllSession'),
    commitSaveCompanyList: ({ commit }) => commit('saveCompanyList'),
    commitSaveFiledRuleList: ({ commit }) => commit('saveFiledRuleList'),
    commitSaveCompanyAllList: ({ commit }) => commit('saveCompanyAllList')
    // commitSetAuth: ({ commit }) => commit('setAuth')
  },
  modules: {}
})
