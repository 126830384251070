<!-- 功能管理 -->
<template>
    <div class="dxxj public-bg">
        <AuthFormList @formData="getAuthData" @add="addMenuFirst"></AuthFormList>
        <a-table :columns="columns" :data-source="dxxjData" :expanded-row-keys.sync="expandedRowKeys"
            :rowKey='record => record.id' :scroll="scroll" :pagination='false' :loading="loading" ref="tableRef"
            class="atable">
            <template slot="action" slot-scope="text,record">
                <a slot="action">
                    <span class="edit" @click="addModal(record)" style="margin-right: 10px"
                        v-if="record.isLastlevel === '否'">新增</span>
                    <span class="edit" @click="editModel(record)" style="margin-right: 10px">修改</span>
                    <span class="edit" @click="deleteModel(record)">删除</span>
                </a>
            </template>
        </a-table>
        <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange"
            ref="paginationRef"></APagination>
        <AddFun ref="addfunRefs" @update="updateList"></AddFun>
        <EditFun ref="editfunRefs" @update="updateList"></EditFun>
    </div>
</template>
<script>
import APagination from '@/components/Pagination/index'
import AddFun from '@/components/AppletAuth/Model/BatchAddFun'
import EditFun from '@/components/AppletAuth/Model/EditFun'
import AuthFormList from '@/components/AppletAuth/FormList/SearchFun'
import Busscommutil from '../../../utils/busscommutil'
const columns = [
    {
        title: '菜单名称',
        dataIndex: 'menuName',
        width: '15%'
    }, {
        title: '父级名称',
        dataIndex: 'parentName'
    }, {
        title: '级别',
        dataIndex: 'level',
        width: '5%'
    }, {
        title: '是否末级',
        dataIndex: 'isLastlevel'
    }, {
        title: '菜单函数',
        dataIndex: 'menuFunction'
    }, {
        title: '菜单图标',
        dataIndex: 'menuIcon'
    },
    {
        title: '排序',
        dataIndex: 'sort'
    }, {
        title: '创建人',
        dataIndex: 'createBy',
        scopedSlots: { customRender: 'rulemsg' }
    }, {
        title: '创建时间',
        dataIndex: 'createTime'
    }, {
        title: '更新人',
        dataIndex: 'updateBy',
        scopedSlots: { customRender: 'rulemsg' }
    }, {
        title: '更新时间',
        dataIndex: 'updateTime'
    },
    {
        title: '操作ip',
        dataIndex: 'operateIp'
    },
    {
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width: '140',
        scopedSlots: { customRender: 'action' }
    }];
export default {
    data() {
        return {
            userType: 1,
            totalData: [],
            dxxjData: [],
            columns,
            expandedRowKeys: [],
            pageInfo: {
                menuName: '',
                parentName: ''
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            },
            scroll: {
                x: 2000,
                y: 500
            },
            loading: false
        }
    },
    components: {
        APagination, AddFun, AuthFormList, EditFun
    },
    created() {
        this.getAuthData()
    },
    methods: {
        group(array, subGroupLength) {
            let index = 0;
            let newArray = [];
            while (index < array.length) {
                newArray.push(array.slice(index, index += subGroupLength));
            }
            return newArray;
        },
        isExist(x) {
            return x !== null && x !== undefined;
        },
        lastTraversalTree(fn, children) {
            if (!Array.isArray(children)) return [];
            return children.map(child => {
                if (child && Array.isArray(child.children)) {
                    child.children = this.lastTraversalTree(fn, child.children);
                }
                return fn(child);
            }).filter(this.isExist);
        },
        // 获取数据
        getAuthData(val) {
            this.loading = true
            if (val) {
                this.pageInfo = val
                this.$refs.paginationRef.onChange(1, 10)
                return
            }
            this.$wechatFun.doGetAppMenuList(this.pageInfo).then((res) => {
                this.loading = false
                this.totalData = res.data
                this.pagination.total = this.totalData.length
                this.lastTraversalTree((item) => {
                    item.isLastlevel = Busscommutil.transIsShow(item.isLastlevel)
                    return item;
                }, this.totalData)
                this.dxxjData = this.group(this.totalData, this.pagination.pageSize)[this.pagination.current - 1]
            })
        },
        // 新增一级菜单
        addMenuFirst() {
            let val = {
                menuName: '',
                menuFunction: '',
                parentName: '',
                sort: 99,
                level: 1,
                isLastlevel: 0,
                parentId: '0',
                menuIcon: ''
            }
            let obj = { ...val }
            this.$refs.addfunRefs.getFormData(obj, 'addFirst')
        },
        // 新增
        addModal(val) {
            let obj = { ...val }
            this.$refs.addfunRefs.getFormData(obj, 'add')
        },
        // 修改
        editModel(val) {
            let obj = { ...val }
            this.$refs.editfunRefs.getFormData(obj, 'edit')
        },
        // 删除
        deleteModel(val) {
            let _this = this
            this.$confirm({
                title: '删除',
                content: '真的删除' + val.menuName + '吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    _this.$wechatFun.doDeleteAppMenu(val.id).then(res => {
                        this.$message.success('删除成功')
                        this.getAuthData()
                    })
                }
            })
        },
        updateList() {
            this.getAuthData()
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.getAuthData()
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        },
        onReCancel() {
            this.getAuthData()
        }
    }
}
</script>
<style scoped lang="less">
@deep: ~'>>>';

.atable {
    @{deep} .ant-table {
        .ant-table-scroll {
            tr {
                td:first-child {
                    text-align: left !important;
                    padding-left: 36px !important;
                }
            }
        }
    }
}
</style>
