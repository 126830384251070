<!-- 数据权限 -->
<template>
    <div class="dxxj public-bg">
        <UserDataFormList @formData="getFormData" @add="addUserData" @deleteAll="deleteAll"></UserDataFormList>
        <ATable :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type="userdata" ref="tableRef" @edit="editRoleMenu" @delete="doDelete" @doSelect="doSelect"></ATable>
        <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange" ref="paginationRef"></APagination>
        <UserDataModelList ref="rmModelref" @update="updateMenu"></UserDataModelList>
    </div>
</template>
<script>
    import UserDataFormList from '@/components/FormList/UserFormList/index'
    import APagination from '@/components/Pagination/index'
    import ATable from '@/components/Table/index'
    import UserDataModelList from '@/components/FormListModel/UserData/index'
    const columns = [
    {
        title: '用户名称',
        dataIndex: 'tUserName'
    },
    {
        title: '公司名称',
        dataIndex: 'tCompanyName'
    },
    {
        title: '省份',
        dataIndex: 'tProvince'
    },
    {
        title: '城市',
        dataIndex: 'tCity'
    },
    {
        title: '区县',
        dataIndex: 'tCounty'
    },
    {
        title: '状态',
        dataIndex: 'tStatusDesc'
    },
    {
        title: '操作时间',
        dataIndex: 'tOperateTime'
    }, {
        title: '操作ip',
        dataIndex: 'tOperateIp'
    }, {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'userdata' }
    }];
    export default {
        data() {
            return {
                userType: 1,
                dxxjData: [],
                columns,
                pageInfo: {
                    page: '',
                    limit: '',
                    tCompanyId: '',
                    tCity: '',
                    tCounty: '',
                    tProvince: '',
                    tUserId: ''
                },
                selectedRowKeys: [],
                pagination: {
                    current: 1,
                    pageSize: 10,
                    total: 0,
                    pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                    showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
                },
                scroll: {
                    y: 500
                }
            }
        },
        components: {
            UserDataFormList, APagination, ATable, UserDataModelList
        },
        created() {
        },
        methods: {
           // 查询表格数据
            getFormData(data) {
                if (data) {
                    this.pageInfo = data
                    this.$refs.paginationRef.onChange(1, 10)
                    return
                }
                this.$refs.tableRef.getList(this.pageInfo)
            },
            addUserData() {
                let val = {
                    tCompanyName: '',
                    tCompanyId: '',
                    tCity: '',
                    tCounty: '',
                    tProvince: '',
                    tStatus: '',
                    tStatusDesc: '',
                    tUserId: '',
                    tUserName: ''
                }
                let obj = { ...val }
                this.$refs.rmModelref.getFormData(obj, 'add')
            },
            // 修改
            editRoleMenu(val) {
                let obj = { ...val }
                this.$refs.rmModelref.getFormData(obj, 'edit')
            },
            // 更新
            updateMenu() {
                this.getFormData()
            },
            // 删除
            doDelete() {
                this.getFormData()
            },
             deleteAll() {
                 if (this.selectedRowKeys.length === 0) {
                    this.$message.warning('请选择要删除的数据')
                    return false
                }
                let value = JSON.stringify({
                    tIds: this.selectedRowKeys
                })
                let _this = this
                this.$confirm({
                    title: '删除',
                    content: '真的删除吗',
                    okText: '确定',
                    mask: false,
                    onOk: () => {
                        _this.$permission.doDeleteBatchUserDataAuth({
                            tIds: value
                        }).then(res => {
                            _this.$message.success('批量删除成功')
                            _this.selectedRowKeys = []
                            _this.$refs.tableRef.getList(this.pageInfo)
                        })
                    }
                })
            },
            doSelect(val) {
                this.selectedRowKeys = val
            },
            // 获取分页
            getShowSizeChange(page) {
                this.handleTableChange(page)
            },
            handleTableChange(pagination) {
                this.$refs.tableRef.getList(this.pageInfo)
            },
            onPageSizeChange(page) {
                this.handleTableChange(page)
            },
            onReCancel() {
               this.$refs.tableRef.getList()
            }
        }
    }
</script>
<style scoped lang="less">
    @deep: ~'>>>';
</style>
