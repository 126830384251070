<!-- 文件预览 -->
<template>
    <div class="content">
        <vue-office-pdf :src="pdf" style="margin-top: 40px;"></vue-office-pdf>
    </div>
</template>
<script>
import VueOfficePdf from '@vue-office/pdf'
export default {
    name: 'PreviewOffice',
    data() {
        return {
        }
    },
    created() {
        let invoiceUrl = JSON.parse(this.$Base64.decode(this.$route.query.invoiceUrl))
        this.loadPdf(invoiceUrl)
    },
    components: {
        VueOfficePdf
    },
    methods: {
        async loadPdf(url) {
            // const base = process.env.NODE_ENV === 'development' ? '/api' : window.g.ImgUrl
            // this.pdf = base + url
        }
    }
}
</script>
