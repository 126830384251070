import { getAction, postAction, postActionQs } from '@/api/manage'

/**
  * 接口域名的管理
  */
const base = {
    userApi: '/api/v1/Mp/UserApi/' // 公众号接口
}
//   获取openID
const doGetWxMpOpenId = (params) => postActionQs(base.userApi + 'doGetWxMpOpenId', {
    code: params
})
// 公众号绑定账号
const doWxMpBindAccount = (params) => postActionQs(base.userApi + 'doWxMpBindAccount', {
    mobile: params.mobile,
    password: params.password,
    openId: params.openId
})
//   检查公众号绑定
const doCheckWxMpBindAccount = (params) => postActionQs(base.userApi + 'doCheckWxMpBindAccount', {
    openId: params
})
//   解绑
const doRemoveWxMpBindAccount = (params) => postActionQs(base.userApi + 'doRemoveWxMpBindAccount', {
    mobile: params.mobile,
    password: params.password,
    openId: params.openId
})

export default {
    doGetWxMpOpenId, doWxMpBindAccount, doCheckWxMpBindAccount, doRemoveWxMpBindAccount
}
