<template>
    <!-- 批量新增分类字典 -->
    <a-modal v-model="editVisible" :title="title" on-ok="saveModelData" :footer="null" :maskClosable="false">
        <div class="fixed-mask" v-if="loading">
            <a-spin tip="保存中...">
            </a-spin>
        </div>
        <div class="top"><a-button @click='addFun' type="primary">新增</a-button></div>
        <a-table :columns="columns" :data-source="data" bordered :pagination="false">
            <div v-for="col in ['name', 'dictName', 'parentName']" :slot="col" slot-scope="text, record"
                :key="col">
                <a-input style="margin: -5px 0" :value="text"
                    @change="e => handleChange(e.target.value, record.key, col)" allowClear oninput="value=value.replace(/\s/g,'')" />
            </div>
            <div slot="isEndlevel" slot-scope="text, record">
                <a-radio-group v-model="record.isEndlevel" @change="e => changeEndLevel(e.target.value, record)">
                    <a-radio :value="1">
                        是
                    </a-radio>
                    <a-radio :value="0">
                        否
                    </a-radio>
                </a-radio-group>
            </div>
            <div slot="disable" slot-scope="text, record">
                <a-radio-group v-model="record.disable" @change="e => changedisable(e.target.value, record)">
                    <a-radio :value="1">
                        是
                    </a-radio>
                    <a-radio :value="0">
                        否
                    </a-radio>
                </a-radio-group>
            </div>
            <template slot="operation" slot-scope="text, record">
                <div class="editable-row-operations">
                    <span>
                        <a-popconfirm title="确定删除吗？" @confirm="() => onDelete(record.key)">
                            <span class="cancel">删除</span>
                        </a-popconfirm>
                    </span>
                </div>
            </template>
            <template slot="footer">
                <a-button key="back" @click="cancelModel">
                    关闭
                </a-button>
                <a-button key="submit" type="primary" @click="saveModelData" style="margin-left:15px">
                    保存
                </a-button>
            </template>
        </a-table>
    </a-modal>
</template>
<script>
const columns = [
    {
        title: '代码名称',
        dataIndex: 'name',
        width: '16%',
        scopedSlots: { customRender: 'name' }
    },
    {
        title: '级数',
        dataIndex: 'level',
        width: '15%',
        scopedSlots: { customRender: 'level' }
    },
    {
        title: '是否末级',
        dataIndex: 'isEndlevel',
        scopedSlots: { customRender: 'isEndlevel' }
    },
    {
        title: '是否禁用',
        dataIndex: 'disable',
        scopedSlots: { customRender: 'disable' }
    },
    {
        title: '类别名称',
        dataIndex: 'dictName'
    },
    {
        title: '父级名称',
        dataIndex: 'parentName'
    },
    {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' }
    }
];
export default {
    name: 'editFormList',
    data() {
        // this.cacheData = data.map(item => ({ ...item }));
        return {
            editVisible: false,
            form: {
                dictName: '',
                level: '',
                disable: '',
                isEndlevel: '',
                name: ''
            },
            count: 1,
            title: '',
            data: [],
            columns,
            loading: false
        };
    },
    watch: {
        // 去掉表单验证信息二次打开残留
        editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
            // 监视dialog状态（打开、关闭）
            if (val) {
                try {
                    this.$refs.ruleForm.resetFields() // 重置编辑表单
                    // addForm 为ref指向的表单
                } catch (e) { }
            }
        }
    },
    created() {
    },
    methods: {
        getFormData(val) {
            this.data = []
            this.form.dictName = val.dictName
            this.form.dictId = val.id
            this.form.parentName = val.dictName
            this.form.parentId = val.id
            this.form.name = ''
            this.form.level = 1
            this.form.key = 0
            this.form.disable = 0
            this.form.isEndlevel = 0
            this.data.push(this.form)
            this.$forceUpdate()
            this.editVisible = true
            this.title = '批量新增'
        },
        handleChange(value, key, column) {
            const newData = [...this.data];
            const target = newData.filter(item => key === item.key)[0];
            if (target) {
                target[column] = value;
                this.data = newData;
            }
        },
        onDelete(key) {
            const dataSource = [...this.data];
            this.data = dataSource.filter(item => item.key !== key);
            this.count--
        },
        // 新增行
        addFun() {
            const { count, data } = this;
            const newData = {
                key: count,
                dictName: this.form.dictName,
                dictId: this.form.dictId,
                parentName: this.form.dictName,
                parentId: this.form.parentId,
                level: 1,
                disable: 0,
                isEndlevel: 0,
                name: ''
            };
            this.data = [...data, newData];
            this.count = count + 1;
        },
        // 选择是否禁用
        changedisable(value, row) {
            const newData = [...this.data];
            const target = newData.filter(item => row.key === item.key)[0];
            if (target) {
                target.disable = value;
                this.data = newData;
            }
        },
        // 选择是否末级
        changeEndLevel(value, row) {
            const newData = [...this.data];
            const target = newData.filter(item => row.key === item.key)[0];
            if (target) {
                target.isEndlevel = value;
                this.data = newData;
            }
        },
        saveModelData() {
            if (this.data.length <= 0) {
                this.$message.warning('请添加分类字典')
                return false
            }
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].name === '') {
                    this.$message.warning('请填写代码名称')
                    return
                }
                if (this.data[i].level === '') {
                    this.$message.warning('请填写级数')
                    return
                }
                if (this.data[i].isEndlevel === '') {
                    this.$message.warning('请选择是否末级')
                    return
                }
                if (this.data[i].disable === '') {
                    this.$message.warning('请选择是否禁用')
                    return
                }
            }
            this.loading = true
            this.$category.doAddDictItem(this.data).then((res) => {
                this.loading = false
                this.editVisible = false
                this.$message.success('新增成功')
                this.$emit('update')
            }).catch(() => {
                this.loading = false
            })
        },
        cancelModel() {
            this.editVisible = false
        }
    }
};
</script>
<style scoped lang="less">
@deep: ~'>>>';

@{deep} .ant-modal {
    width: 70% !important;

    .cancel:hover {
        color: red;
        opacity: .8;
        cursor: pointer;
    }

    .ant-table-footer {
        text-align: right;
    }
}

.top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;

    span {
        display: block;
        line-height: 42px;
    }
}
</style>
