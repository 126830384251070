<template>
  <div class="login">
    <div class="fixed-mask" v-if="fetching">
      <a-spin size="large" />
    </div>
    <div class="content">
      <div class="login-box">
        <div class="title">零星物资</div>
        <div class="user-layout-login">
          <a-tabs v-model="activeKey" @change="changeTab">
            <a-tab-pane :key="0" tab="手机号登录">
            </a-tab-pane>
            <a-tab-pane :key="1" tab="账号登录">
            </a-tab-pane>
          </a-tabs>
          <LoginAccount ref="alogin" :tab="activeKey" @submit="handleSubmit"></LoginAccount>
          <div class="remember-pwd">
            <a-checkbox v-model="checked">
              记住密码
            </a-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="beian"> ICP备案号 ：<a href="https://beian.miit.gov.cn/" style="color: #FFFFFF;">沪ICP备2024100305号-1</a></div>
    <div class="version">2021-{{ currentYear }} 版本号：{{ version }}</div>

  </div>
</template>

<script>
// import PhoneLogin from '@/components/PhoneLogin/index'
import LoginAccount from '@/components/LoginAccount/index'
import cookUtil from '../../api/util/sessioncook'
import sessionUtil from '../../api/util/sessionutil'
export default {
  name: 'login',
  data() {
    return {
      checked: true,
      currentYear: '',
      activeKey: 0, // 当前tab
      fetching: false,
      version: window.g.version // 版本号
    }
  },
  components: {
    LoginAccount
  },
  mounted() {
    // 获取当前年份
    let currentYear = new Date().getFullYear()
    this.currentYear = currentYear
  },
  created() {
    let _this = this
    document.onkeydown = function (e) {
      var key = window.event.keyCode
      if (key === 13) {
        _this.handleSubmit()
      }
    }
  },
  methods: {
    changeTab(key) {
    },
    // 手机号登录
    onPhoneSubmit(val) {
      this.fetching = true
      this.$user.login(val.phone, '', val.password).then(res => {
        this.fetching = false
        this.$store.commit('saveUserInfo', res.data.user);
        this.$store.commit('saveToken', {
          token: res.data.token
        });
        sessionUtil.setSession('sessionTime', '2');
        document.onkeydown = undefined;
        this.$router.push('./shome')
      }).catch(() => {
        this.fetching = false
      })
    },
    // 账号登录
    onAccountSubmit(val) {
      this.fetching = true
      this.$user.login('', val.accountCode, val.password).then(res => {
        this.fetching = false
        this.$store.commit('saveUserInfo', res.data.user);
        this.$store.commit('saveToken', {
          token: res.data.token
        });
        sessionUtil.setSession('sessionTime', '2');
        document.onkeydown = undefined;
        this.$router.push('./shome')
      }).catch(() => {
        this.fetching = false
      })
    },
    // 登录
    handleSubmit(formInline) {
      // 清空Cookie
      cookUtil.clearCookie();
      if (this.activeKey === 1) {
        if (this.checked) {
          cookUtil.setCookie('', formInline.accountCode, formInline.password, 7);
        } else {
          // 清空Cookie
          cookUtil.clearCookie();
        }
        this.onAccountSubmit(formInline)
      } else if (this.activeKey === 0) {
        if (this.checked) {
          cookUtil.setCookie(formInline.phone, '', formInline.password, 7);
        } else {
          // 清空Cookie
          cookUtil.clearCookie();
        }
        this.onPhoneSubmit(formInline)
      }
    }
  }
}
</script>

<style scoped lang="less">
@deep: ~'>>>';

.login {
  background: url(../../../public/images/bg.jpg) no-repeat center;
  background-size: 100%;
  position: fixed;
  width: 100%;
  height: 100%;

  .content {
    width: 1200px;
    margin: 13% auto 0;

    .user-layout-login {
      background: #fff;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 10% 5%;
    }
  }

  .login-box {
    width: 500px;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;
    float: right;

    .title {
      font-size: 40px;
      color: #fff;
      width: 100%;
      text-align: center;
      font-weight: 400;
      padding-bottom: 5%;
      letter-spacing: 35px;
    }

    .remember-pwd {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 15px;
      color: #fff;
      opacity: .8;

      @{deep} span {
        color: #1F8839;
      }
    }

    @{deep} .ant-tabs {
      text-align: center;
    }

    @{deep}.ant-tabs-nav .ant-tabs-tab {
      font-size: 16px;
    }
  }
  .beian {
    color: #fff;
    position: fixed;
    bottom: 30px;
    text-align: center;
    width: 100%;
  }
  .version {
    color: #fff;
    position: fixed;
    bottom: 10px;
    text-align: center;
    width: 100%;
  }
}
</style>
