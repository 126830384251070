<template>
  <a-modal v-model="editVisible" :title="title" on-ok="saveModelData" :footer="null" :maskClosable="false">
    <div class="fixed-mask" v-if="loading">
      <a-spin tip="保存中...">
      </a-spin>
    </div>
    <div class="top"><a-button @click='addOrgName' type="primary">新增</a-button></div>
    <a-table :columns="columns" :data-source="data" bordered :pagination="false">
      <div v-for="col in ['orgName', 'orgNameAbbr', 'orgRemarkCode', 'remark']" :slot="col" slot-scope="text, record" :key="col">
        <a-input style="margin: -5px 0" :value="text" oninput="value=value.replace(/\s/g,'')"
          @change="e => handleChange(e.target.value, record.key, col)"
          :disabled="col === 'orgRemarkCode' && record.parentId !== 0" />
      </div>
      <div slot="status" slot-scope="text, record">
        <a-radio-group v-model="record.status" @change="e => changestatus(e.target.value, record)">
          <a-radio :value="0">
            启用
          </a-radio>
          <a-radio :value="1">
            禁用
          </a-radio>
        </a-radio-group>
      </div>
      <template slot="operation" slot-scope="text, record">
        <div class="editable-row-operations">
          <span>
            <a-popconfirm title="确定删除吗？" @confirm="() => onDelete(record.key)">
              <span class="cancel">删除</span>
            </a-popconfirm>
          </span>
        </div>
      </template>
      <template slot="footer">
        <a-button key="back" @click="cancelModel">
          关闭
        </a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="saveModelData" style="margin-left:15px">
          批量新增
        </a-button>
      </template>
    </a-table>
  </a-modal>
</template>
<script>
const columns = [
  {
    title: '机构名称',
    dataIndex: 'orgName',
    scopedSlots: { customRender: 'orgName' }
  },
  {
    title: '机构简称',
    dataIndex: 'orgNameAbbr',
    scopedSlots: { customRender: 'orgNameAbbr' }
  },
  {
    title: '机构标识代码',
    dataIndex: 'orgRemarkCode',
    scopedSlots: { customRender: 'orgRemarkCode' }
  },
  {
    title: '机构级别',
    dataIndex: 'level',
    scopedSlots: { customRender: 'level' },
    width: '100px'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    width: '100px'
  },
  {
    title: '备注',
    dataIndex: 'remark',
    scopedSlots: { customRender: 'remark' }
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
];
export default {
  name: 'editFormList',
  data() {
    // this.cacheData = data.map(item => ({ ...item }));
    return {
      editVisible: false,
      form: {
        orgName: '',
        parentId: '',
        level: '',
        status: '',
        orgNameAbbr: '',
        orgRemarkCode: ''
      },
      count: 1,
      title: '',
      data: [],
      columns,
      editingKey: '',
      loading: false
    };
  },
  watch: {
    // 去掉表单验证信息二次打开残留
    editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
      // 监视dialog状态（打开、关闭）
      if (val) {
        try {
          this.$refs.ruleForm.resetFields() // 重置编辑表单
          // addForm 为ref指向的表单
        } catch (e) { }
      }
    }
  },
  created() {
  },
  methods: {
    getFormData(val) {
      this.form = val
      this.data = [];
      this.data.push({
        key: 0,
        orgName: '',
        orgNameAbbr: '',
        orgRemarkCode: val.orgRemarkCode,
        level: val.level,
        parentId: val.parentId,
        status: 0
      });
      this.editVisible = true
      this.title = '批量新增'
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter(item => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    onDelete(key) {
      const dataSource = [...this.data];
      this.data = dataSource.filter(item => item.key !== key);
    },
    addOrgName() {
      const { count, data } = this;
      const newData = {
        key: count,
        orgName: '',
        orgNameAbbr: '',
        level: this.form.level,
        parentId: this.form.parentId,
        orgRemarkCode: this.form.orgRemarkCode,
        status: 0
      };
      this.data = [...data, newData];
      this.count = count + 1;
    },
    // 选择状态
    changestatus(value, row) {
      const newData = [...this.data];
      const target = newData.filter(item => row.key === item.key)[0];
      if (target) {
        target.status = value;
        this.data = newData;
      }
    },
    saveModelData() {
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].orgName === '') {
          this.$message.warning('请填写机构名称')
          return
        }
        if (this.data[i].orgRemarkCode === '') {
          this.$message.warning('请填写机构标识代码')
          return
        }
        if (this.data[i].orgRemarkCode) {
          const reg = /^[A-Z]+$/
          if (!reg.test(this.data[i].orgRemarkCode)) {
            this.$message.warning('机构标识代码只能输入大写字母')
            return false
          }
        }
      }
      this.loading = true
      this.$organization.doAddBatchSysOrg(this.data).then((res) => {
        this.loading = false
        this.editVisible = false
        this.$message.success('新增成功')
        this.$emit('update')
      }).catch(() => {
        this.loading = false
      })
    },
    cancelModel() {
      this.editVisible = false
    }
  }
};
</script>
<style scoped lang="less">
@deep: ~'>>>';

@{deep} .ant-modal {
  width: 70% !important;

  .cancel:hover {
    color: red;
    opacity: .8;
    cursor: pointer;
  }

  .ant-table-footer {
    text-align: right;
  }
}

.top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 15px;

  span {
    display: block;
    line-height: 42px;
  }
}
</style>
