<template>
  <div>
    <a-form @submit="handleSubmit" :form="repository" class="form" v-bind="layout">
      <a-row class="form-row" :gutter="24">
        <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
          <a-form-item label="机构名称">
            <a-input placeholder="机构名称" v-model="repository.orgName" allowClear oninput="value=value.replace(/\s/g,'')"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col style="margin-bottom: 40px;float: right">
          <a-button style="margin-right: 20px" type="primary" @click="goBackFirst">返回一级</a-button>
          <a-button style="margin-right: 20px" type="primary" @click="addOrg">新增</a-button>
          <a-button style="margin-right: 20px" type="primary" @click="deleteOrg">批量删除</a-button>
          <a-button htmlType="submit" type="primary">查询</a-button>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
export default {
  name: 'RepositoryForm',
  props: ['id'],
  data() {
    return {
      repository: {
        orgName: ''
      },
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
      }
    }
  },
  components: {
  },
  created() {
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.repository.id = this.id
      this.repository.type = 1
      this.$emit('formData', this.repository)
    },
    addOrg() {
      this.$emit('add')
    },
    deleteOrg() {
      this.$emit('delete')
    },
    // 返回一级
    goBackFirst() {
      this.$emit('goback')
    }
  }
}
</script>

<style scoped>

</style>
