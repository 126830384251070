<!-- 功能管理 -->
<template>
    <div class="dxxj public-bg">
        <SearchRoleFun @add="addRoleFun" @delete="deleteRoleFun" @formData="getFormData"></SearchRoleFun>
        <AddRoleFun ref="addrolefunRef" @update="updateList"></AddRoleFun>
        <EditRoleFun ref="editrolefunRef" @update="updateList"></EditRoleFun>
        <ATable :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type="rolefun"
            ref="tableRef" @delete="updateList" @onArea="onArea" @onRoleFun="onRoleFun" @edit="editRolefun" @copy="copyInfo" @doSelect="doSelect"></ATable>
        <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange"
            ref="paginationRef"></APagination>
        <!-- 覆盖区域 -->
        <AddRegion ref="regionRef"></AddRegion>
        <!-- 授权功能 -->
        <AddAuth ref="addAreaRef"></AddAuth>
        <!-- 复制 -->
        <SelectComapny ref="companySelectRef" @update="updateList"></SelectComapny>
    </div>
</template>
<script>
import SearchRoleFun from '@/components/AppletAuth/FormList/SearchRoleFun'
import AddRoleFun from '@/components/AppletAuth/Model/AddRoleFun'
import EditRoleFun from '@/components/AppletAuth/Model/EditRoleFun'
import AddRegion from '@/components/AppletAuth/RoleFunRegion/AddRegion'
import AddAuth from '@/components/AppletAuth/RoleFunAuth/AddAuth'
import SelectComapny from '@/components/AppletAuth/Model/SelectComapny'
import APagination from '@/components/Pagination/index'
import ATable from '@/components/Table/index'
const columns = [
    {
        title: '公司名称',
        dataIndex: 'companyName',
        width: '20%',
        scopedSlots: { customRender: 'rulemsg' }
    }, {
        title: '角色名称',
        dataIndex: 'roleName',
        width: '10%',
        scopedSlots: { customRender: 'rulemsg' }
    }, {
        title: '功能和区域',
        dataIndex: 'RoleFunAuth',
        width: '150px',
        scopedSlots: { customRender: 'RoleFunAuth' }
    }, {
        title: '创建人',
        dataIndex: 'createBy',
        scopedSlots: { customRender: 'rulemsg' }
    }, {
        title: '创建时间',
        dataIndex: 'createTime',
        width: '10%'
    }, {
        title: '更新人',
        dataIndex: 'updateBy',
        scopedSlots: { customRender: 'rulemsg' }
    }, {
        title: '更新时间',
        dataIndex: 'updateTime',
        width: '10%'
    },
    {
        title: '操作ip',
        dataIndex: 'operateIp'
    },
    {
        title: '操作',
        dataIndex: 'operation',
        fixed: 'right',
        width: '150',
        scopedSlots: { customRender: 'rolefun' }
    }];
export default {
    data() {
        return {
            columns,
            pageInfo: {
                limit: '',
                page: ''
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            },
            scroll: {
                x: 1500,
                y: 500
            },
            selectedRowKeys: [] // 选中的数据
        }
    },
    components: {
        APagination, ATable, SearchRoleFun, AddRoleFun, AddRegion, AddAuth, SelectComapny, EditRoleFun
    },
    created() {
    },
    methods: {
        // 查询表格数据
        getFormData(data) {
            if (data) {
                this.pageInfo = data
                this.$refs.paginationRef.onChange(1, 10)
                return
            }
            this.$refs.tableRef.getList(this.pageInfo)
        },
        // 新增角色功能
        addRoleFun() {
            let val = {
                company: [],
                role: []
            }
            this.$refs.addrolefunRef.getFormData(val, 'add')
        },
        // 修改角色功能
        editRolefun(row) {
            let val = { ...row }
            this.$refs.editrolefunRef.getFormData(val, 'edit')
        },
        updateList() {
            this.$refs.tableRef.getList(this.pageInfo)
        },
        // 覆盖地市
        onArea(row) {
            this.$refs.regionRef.getFormData(row)
        },
        // 角色授权
        onRoleFun(row) {
            this.$refs.addAreaRef.getFormData(row)
        },
        // 复制xinxi
        copyInfo(row) {
            this.$refs.companySelectRef.show(row)
        },
        // 获取选中角色功能
        doSelect(row) {
            this.selectedRowKeys = row
        },
        // 删除角色功能
        deleteRoleFun() {
            if (this.selectedRowKeys.length <= 0) {
                this.$message.warning('请选择需要删除的数据')
                return false
            }
            let value = JSON.stringify({
                ids: this.selectedRowKeys
            })
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$wechatFun.doDeleteAppMenuRole(value).then(res => {
                        this.$message.success('删除成功')
                        this.selectedRowKeys = []
                        this.$refs.tableRef.getList(this.pageInfo)
                    })
                }
            })
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.$refs.tableRef.getList(this.pageInfo)
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        }
    }
}
</script>
<style scoped lang="less">
@deep: ~'>>>';
</style>
