<template>
    <div>
        <a-modal v-model="editVisible" title="物资明细"  cancel-text="关闭" :bodyStyle='bodyStyle'
            @cancel="onClose">
            <div class="fixed-mask" v-if="loading">
                <a-spin size="large" />
            </div>
            <a-form @submit="doGetMatDetailList" :form="pageApi" class="form" v-bind="layout">
                <a-row class="form-row" :gutter="24">
                    <a-col :xxl="8" :xl="8" :lg="8" :md="12" :sm="24">
                        <a-form-item label="电商平台物料描述">
                            <a-input v-model="pageApi.matDesc" allowClear placeholder='电商平台物料描述'></a-input>
                        </a-form-item>
                    </a-col>
                    <a-col style="margin-bottom: 40px;float: right">
                        <a-button htmlType="submit" type="primary" style="margin-right: 20px">查询</a-button>
                    </a-col>
                </a-row>
            </a-form>
            <a-table :columns='columns' :data-source='matDetailsList' bordered :pagination='false' :scroll='scroll'
                :loading="loading" rowKey="id">
                <template slot="options" slot-scope="text,record">
                    <a>
                        <span @click="checkDetail(record)">查看明细</span>
                    </a>
                </template>
                <template slot="msg" slot-scope="text">
                    <a-tooltip placement="topLeft">
                        <template slot="title">
                            {{ text }}
                        </template>
                        {{ text }}
                    </a-tooltip>
                </template>
            </a-table>
        </a-modal>
    </div>
</template>
<script>
export default {
    data() {
        return {
            editVisible: false,
            layout: {
                labelCol: { span: 9 },
                wrapperCol: { span: 15 }
            },
            columns: [
                {
                    title: '序号',
                    dataIndex: 'serialNumber',
                    width: '100px'
                },
                {
                    title: '电商平台物料描述',
                    dataIndex: 'matDesc',
                    scopedSlots: { customRender: 'msg' }
                },
                {
                    title: '电商物料号',
                    dataIndex: 'matCode'
                },
                {
                    title: '单位',
                    dataIndex: 'unit',
                    width: '100px'
                },
                {
                    title: '数量',
                    dataIndex: 'number',
                    width: '100px'
                },
                {
                    title: '预算单价（含税）',
                    dataIndex: 'budgetUnitPrice',
                    width: '150px'
                },
                {
                    title: '预算总价（含税）',
                    dataIndex: 'budgetTotalPrice',
                    width: '150px'
                },
                {
                    title: '拟匹配平台商',
                    dataIndex: 'matchPlatformVendors',
                    scopedSlots: { customRender: 'msg' }
                },
                {
                    title: '电商商品号',
                    dataIndex: 'goodsId',
                    scopedSlots: { customRender: 'msg' }
                },
                {
                    title: '电商商品描述',
                    dataIndex: 'goodsName',
                    scopedSlots: { customRender: 'msg' }
                },
                {
                    title: '单价（含税）',
                    dataIndex: 'unitPrice'
                },
                {
                    title: '总价（含税）',
                    dataIndex: 'totalPrice'
                }],
            bodyStyle: {
                height: '500px',
                overflowY: 'scroll'
            },
            pageApi: {
                matDesc: '',
                matId: ''
            },
            matDetailsList: [],
            loading: false,
            scroll: {
                x: 1800,
                y: 500
            }
        }
    },
    created() { },
    watch: {},
    methods: {
        show(id) {
            this.editVisible = true
            this.pageApi.matId = id // 主单号
            this.pageApi.matDesc = ''
            this.doGetMatDetailList()
        },
        doGetMatDetailList() {
            this.loading = true
            this.$mat.doGetMatDetailList(this.pageApi).then(res => {
                this.loading = false
                this.matDetailsList = res.data
            })
        },
        onClose() { }
    }
}
</script>
<style lang='less' scoped>
@deep: ~'>>>';
@{deep} .ant-modal {
    width: 70% !important;
}
</style>
