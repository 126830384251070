import { getAction, deleteAction, putAction, postAction, postActionQs, httpAction } from '@/api/manage'
/**
  * 公司管理
  */
const base = {
    checkRule: '/api/v1/manager/FieldCheckRuleApi/', // 校验规则
    companyManage: '/api/v1/manager/CompanyApi/', // 公司管理
    SysCovernantApi: '/api/v1/manager/SysCovernantApi/', // 服务商协议
    SysImageApi: '/api/v1/manager/SysImageApi/' // 影像资料
}
// 公司管理
// const getCompanyList = (params) => postActionQs(base.companyManage + 'getCompanyList', params); // 获取公司列表
// 根据是否禁用获取公司
const doGetCompanyByDisable = (name, disable, typeId) => postActionQs(base.companyManage + 'doGetCompanyByDisable', {
  companyName: name,
  disable: disable,
  typeId: typeId
});
// 获取公司列表(不包含禁用)
const doGetCompanyByPage = (params) => getAction(base.companyManage + 'doGetCompanyByPage', params);
// 获取公司列表(包含禁用)
const doGetCompanyListByPage = (params) => getAction(base.companyManage + 'doGetCompanyListByPage', params);
// 分页获取公司列表(不包含禁用)
const doGetAllCompanyByPage = (params) => getAction(base.companyManage + 'doGetAllCompanyByPage', params);
const getAllCompanyList = () => getAction(base.companyManage + 'doGetAllCompany'); // 获取全部公司
const doAddCompany = (params) => postActionQs(base.companyManage + 'doAddCompany', params); // 新增公司
const doUpdateCompany = (params) => postActionQs(base.companyManage + 'doUpdateCompany', params); // 修改公司

// 公司校验规则
const doGetFieldCheckRuleList = (params) => postActionQs(base.checkRule + 'doGetFieldCheckRuleList', params)// 获取校验规则
const doAddFieldCheckRule = (params) => postActionQs(base.checkRule + 'doAddFieldCheckRule', params)// 获取校验规则
const doUpdateFieldCheckRule = (params) => postActionQs(base.checkRule + 'doUpdateFieldCheckRule', params)// 获取校验规则
const doDelBatchFieldCheckRule = (params) => postActionQs(base.checkRule + 'doDelBatchFieldCheckRule', params)// 获取校验规则
export default {
    doGetCompanyListByPage,
    getAllCompanyList,
    doGetCompanyByDisable,
    doGetFieldCheckRuleList,
    doAddFieldCheckRule,
    doUpdateFieldCheckRule,
    doDelBatchFieldCheckRule,
    doUpdateCompany,
    doAddCompany,
    doGetCompanyByPage,
    doGetAllCompanyByPage
}
