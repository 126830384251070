<template>
    <div class="public-bg">
        <!-- 搜索 -->
         <DocTypeSearch @add="addType" @formData="getFormData"></DocTypeSearch>
        <!-- 新增单据类型 -->
        <DocTypeModal ref="typeRef" @update="getFormData"></DocTypeModal>
        <Table :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type='documenttype' @edit="editType" @update="getFormData" @disable="disableData"
        @check="checkDoc" ref='tableRef'></Table>
        <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange"
            ref="paginationRef"></APagination>
        <!-- 流程模型 -->
         <FlowModal ref="flowmodal"></FlowModal>
         <!-- 流程状态 -->
          <FlowStatus ref="flowstatus"></FlowStatus>
    </div>
</template>
<script>
import DocTypeSearch from '@/components/Flow/DocumentType/Search/index'
import DocTypeModal from '@/components/Flow/DocumentType/Modal/index'
import FlowModal from '@/components/Flow/DocumentType/FlowModal/index'
import FlowStatus from '@/components/Flow/DocumentType/FlowStatus/index'
import Table from '@/components/Table'
import APagination from '@/components/Pagination/index'
export default {
    data() {
        return {
            columns: [
                {
                    title: '编号',
                    dataIndex: 'id'
                },
                {
                    title: '单据类型',
                    dataIndex: 'billTypeDesc',
                    width: '15%',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '流程模型',
                    dataIndex: 'flowmodal',
                    scopedSlots: { customRender: 'flowmodal' }
                },
                {
                    title: '流程状态',
                    dataIndex: 'flowstatus',
                    scopedSlots: { customRender: 'flowstatus' }
                },
                {
                    title: '是否禁用',
                    dataIndex: 'disable',
                    scopedSlots: { customRender: 'doctypedisable' }
                }, {
                    title: '创建人',
                    dataIndex: 'createBy'
                }, {
                    title: '创建时间',
                    dataIndex: 'createTime'
                }, {
                    title: '更新人',
                    dataIndex: 'updateBy'
                }, {
                    title: '更新时间',
                    dataIndex: 'updateTime'
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    scopedSlots: { customRender: 'typeRef' }
                }
            ],
            modalTitle: '',
            pageInfo: {
                page: '',
                limit: ''
            },
            scroll: {
                y: 500
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            }
        }
    },
    components: {
        Table, APagination, DocTypeSearch, DocTypeModal, FlowModal, FlowStatus
    },
    methods: {
        // 查询表格
        getFormData(data) {
            if (data) {
                this.pageInfo = data
                this.$refs.paginationRef.onChange(1, 10)
                return
            }
            this.$refs.tableRef.getList(this.pageInfo)
        },
        // 新增
        addType() {
            let obj = {
                billTypeDesc: '',
                disable: 0
            }
            this.$refs.typeRef.show(obj, '新增')
        },
        // 修改
        editType(val) {
            let obj = { ...val }
            this.$refs.typeRef.show(obj, '修改')
        },
        // 更新是否禁用
        disableData(val) {
            this.$flow.doUpdateFlowBillType(val).then(res => {
              this.$message.success('修改成功')
            }).catch(() => {
            })
        },
        // 查看流程模型/流程状态
        checkDoc(val) {
            if (val.type === 'flowmodal') {
                this.$refs.flowmodal.show(val.row.id, val.row.billTypeDesc)
            }
            if (val.type === 'flowstatus') {
                this.$refs.flowstatus.show(val.row.id, val.row.billTypeDesc)
            }
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.$refs.tableRef.getList(this.pageInfo)
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        }
    }
}
</script>
<style lang="less" scoped></style>
