// 保留自定义小数num  计算的值，count 保留的位数
const fixedNum = (num, count) => {
    var decimal = 2
    if (count === undefined || count === null) {
        decimal = 2
    } else {
        decimal = count
    }
    var numbers = '';
    // 保留几位小数后面添加几个0
    for (var i = 0; i < decimal; i++) {
        numbers += '0';
    }
    var s = 1 + numbers;
    // 如果是整数需要添加后面的0
    var spot = '.' + numbers;
    // Math.round四舍五入
    //  parseFloat() 函数可解析一个字符串，并返回一个浮点数。
    var value = Math.round(parseFloat(num) * s) / s;
    // 从小数点后面进行分割
    var d = value.toString().split('.');
    if (d.length === 1) {
        value = value.toString() + spot;
        return value;
    }
    if (d.length > 1) {
        if (d[1].length < 2) {
            value = value.toString() + '0';
        }
        return value;
    }
}
module.exports = {
    fixedNum
}
