<template>
    <div class="public-bg">
        <!-- 搜索 -->
         <GoodsListSearch @add="addWz" @formData="getFormData" @update="getFormData" @delete="deleteAll"></GoodsListSearch>
        <!-- 新增物资清单 -->
        <GoodsListModal ref="goodslist" @update="getFormData"></GoodsListModal>
        <Table :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type='goodslist' @edit="editGoodsList" @update="getFormData"
        @doSelect="doSelect" ref='tableRef'></Table>
        <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange"
            ref="paginationRef"></APagination>
    </div>
</template>
<script>
import GoodsListSearch from '@/components/BusinessData/GoodsList/Search/index'
import GoodsListModal from '@/components/BusinessData/GoodsList/Modal/index'
import Table from '@/components/Table'
import APagination from '@/components/Pagination/index'
export default {
    data() {
        return {
            columns: [
                {
                    title: '序号',
                    dataIndex: 'sn',
                    width: '100',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '批次',
                    dataIndex: 'batch',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '分标顺序',
                    dataIndex: 'scaleOrder',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '分标名称',
                    dataIndex: 'scaleName',
                    width: '4%',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '分包名称',
                    dataIndex: 'subpackageName',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '包号',
                    dataIndex: 'packageNum',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '网省采购申请号',
                    dataIndex: 'proOnlineApplyNum',
                    width: '4%',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '商品ID',
                    dataIndex: 'goodsId',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '物料大类',
                    dataIndex: 'matBigType',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '物料中类',
                    dataIndex: 'matCentreType',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '物料小类',
                    dataIndex: 'matSmallType',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '物料编码',
                    dataIndex: 'matCode',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '物料描述',
                    dataIndex: 'matDesc',
                    width: '6%',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '扩展码',
                    dataIndex: 'expandCode',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '扩展描述',
                    dataIndex: 'expandDesc',
                    width: '6%',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '参考品牌',
                    dataIndex: 'refBrand',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '参考规格型号',
                    dataIndex: 'refModelSpec',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '参考品牌制造商',
                    dataIndex: 'refBrandManufacturer',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '功能描述',
                    dataIndex: 'funcDesc',
                    width: '6%',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '国网计量单位',
                    dataIndex: 'stateGridUnit',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '成交人',
                    dataIndex: 'trader',
                    width: '5%',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '商品名称',
                    dataIndex: 'tradeName',
                    width: '6%',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '响应品牌',
                    dataIndex: 'respBrand',
                    width: '5%',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '响应规格型号',
                    dataIndex: 'respModelSpec',
                    width: '5%',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '响应品牌制造商',
                    dataIndex: 'respBrandManufacturer',
                    width: '5%',
                    scopedSlots: { customRender: 'rulemsg' }
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    fixed: 'right',
                    width: 125,
                    scopedSlots: { customRender: 'goodslist' }
                }
            ],
            selectedRowKeys: [],
            modalTitle: '',
            pageInfo: {
                page: '',
                limit: ''
            },
            scroll: {
                x: 4000,
                y: 500
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            }
        }
    },
    components: {
        Table, APagination, GoodsListSearch, GoodsListModal
    },
    methods: {
        // 查询表格
        getFormData(data) {
            if (data) {
                this.pageInfo = data
                this.$refs.paginationRef.onChange(1, 10)
                return
            }
            this.$refs.tableRef.getList(this.pageInfo)
        },
        // 新增
        addWz() {
            this.$refs.goodslist.show({}, '新增')
        },
        // 修改
        editGoodsList(val) {
            let obj = { ...val }
            this.$refs.goodslist.show(obj, '修改')
        },
        // 批量修改选择数据
        doSelect(vals) {
            this.selectedRowKeys = vals
        },
        // 删除操作
        deleteAll() {
            if (this.selectedRowKeys.length <= 0) {
                this.$message.warning('请选择需要删除的数据')
                return false
            }
            let value = JSON.stringify({
                ids: this.selectedRowKeys
            })
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$businessdata.doDelMatInventory(value).then(res => {
                        this.$message.success('删除成功')
                        this.selectedRowKeys = []
                        this.$refs.tableRef.getList(this.pageInfo)
                    })
                }
            })
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.$refs.tableRef.getList(this.pageInfo)
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        }
    }
}
</script>
<style lang="less" scoped></style>
