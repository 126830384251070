import Vue from 'vue'

import {
    Button, Icon, Input, FormModel, Menu, Badge, Avatar,
    Layout, Breadcrumb, Table, Tabs, Col, Row, Form, Select,
    DatePicker, Modal, message, notification, ConfigProvider,
    Switch, Radio, Checkbox, Pagination, Cascader, Dropdown, Spin, TreeSelect, Upload, popconfirm, tooltip, Result, InputNumber,
    Steps, Drawer, Timeline, Tag, Descriptions, Collapse, Card, Carousel, Popover, Empty, Statistic, Divider, Tree
} from 'ant-design-vue';
Vue.use(Button);
Vue.use(Icon);
Vue.use(Input);
Vue.use(FormModel);
Vue.use(Menu);
Vue.use(Badge);
Vue.use(Avatar);
Vue.use(Layout);
Vue.use(Breadcrumb);
Vue.use(Table);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Row);
Vue.use(Form);
Vue.use(Select);
Vue.use(DatePicker);
Vue.use(Modal);
Vue.use(ConfigProvider);
Vue.use(Switch);
Vue.use(Radio);
Vue.use(Checkbox);
Vue.use(Pagination);
Vue.use(Cascader);
Vue.use(Dropdown);
Vue.use(Spin);
Vue.use(TreeSelect);
Vue.use(Upload);
Vue.use(popconfirm);
Vue.use(tooltip);
Vue.use(Result);
Vue.use(InputNumber);
Vue.use(Steps);
Vue.use(Drawer);
Vue.use(Timeline);
Vue.use(Descriptions);
Vue.use(Collapse);
Vue.use(Card);
Vue.use(Carousel);
Vue.use(Tag);
Vue.use(Popover);
Vue.use(Empty);
Vue.use(Statistic);
Vue.use(Divider);
Vue.use(Tree);

Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
