<template>
    <div>
        <a-modal v-model="editVisible" title="选择授权公司" :bodyStyle='bodyStyle' :footer="null" @cancel="onClose">
            <div v-if='commit' class="fixed-mask">
                <a-spin tip="保存中...">
                </a-spin>
            </div>
            <div v-if='loading' class="fixed-mask">
                <a-spin tip="加载中...">
                </a-spin>
            </div>
            <a-row>
                <a-col :span="24" style="border-right: 1px solid #eee;padding: 0 15px 0 0;">
                    <a-form :form="repository" class="form" v-bind="layout">
                        <a-row class="form-row" :gutter="24">
                            <a-form-item label="公司名称">
                                <a-select v-model="repository.companyId" placeholder="选择公司" allowClear showSearch
                                    optionFilterProp="label" style="width: 100%;">
                                    <a-spin v-if="fetchingCm" slot="notFoundContent" size="small" />
                                    <a-select-option v-for="(item) in companyList" :key="item.id" :value="item.id"
                                        :label="item.companyname">{{ item.companyname }}</a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-row>
                        <a-row>
                            <a-col style="margin-bottom: 10px;float: right">
                                <a-button style="margin-right: 20px" type="primary" @click="addCompany">授权</a-button>
                                <a-button style="margin-right: 20px" type="primary"
                                    @click="deleteCompany">取消授权</a-button>
                                <a-button type="primary" @click="doGetUserAuthDataList">查询</a-button>
                            </a-col>
                        </a-row>
                    </a-form>
                    <div class="menu-title"><a-icon type="appstore" theme="filled"
                            :style="{ fontSize: '16px', color: '#1F8839', paddingRight: '5px' }" />已授权公司</div>
                    <div class="menu-content">
                        <a-tree v-model="checkedKeys" :replaceFields="{ title: 'companyName', key: 'companyId' }"
                            checkable :selected-keys="selectedKeys" :tree-data="authcompanyList" @select="onSelect"
                            @check="onCheck" />
                    </div>
                </a-col>
            </a-row>

        </a-modal>
        <!-- 授权公司 -->
        <AddCompany ref="addcompany" @update="doGetUserAuthDataList"></AddCompany>
    </div>
</template>
<script>
import areaJson from '../../../../assets/js/area.json'
import AddCompany from '@/components/UserManage/Modal/RangeCompany/AddCompany/index'
export default {
    data() {
        return {
            areaOptions: areaJson,
            editVisible: false,
            bodyStyle: {
                height: '650px',
                overflowY: 'scroll'
            },
            authcompanyList: [], // 授权公司
            companyList: [],
            loading: false,
            fetchingCm: false,
            commit: false,
            userId: '', // 用户id
            layout: {
                labelCol: { span: 3 },
                wrapperCol: { span: 10 }
            },
            repository: {
                companyId: '' // 公司查询
            },
            checkedKeys: [], // 已选中的节点
            selectedKeys: [] // 当前选中的节点id
        }
    },
    components: { AddCompany },
    created() {
    },
    methods: {
        show(row) {
            this.editVisible = true
            this.userId = row.id
            this.getCompanyList()
            this.doGetUserAuthDataList()
        },
        //  获取保险公司列表
        getCompanyList() {
            this.$company.doGetCompanyByDisable('', 0, '').then((res) => {
                this.fetchingCm = false
                this.companyList = res.data
            }).catch(() => {
                this.fetchingCm = false
            })
        },
        // 获取授权公司
        doGetUserAuthDataList() {
            this.loading = true
            this.$user.doGetSysUserCompanyList(this.userId, this.repository.companyId).then(res => {
                this.loading = false
                this.authcompanyList = res.data
                this.selectedKeys = []
                this.checkedKeys = []
            }).catch(() => {
                this.loading = false
            })
        },
        // 新增公司
        addCompany() {
            this.$refs.addcompany.show(this.userId)
        },
        // 删除公司
        deleteCompany() {
            if (this.checkedKeys.length <= 0) {
                this.$message.error('请选择要取消授权的公司')
                return false
            }
            let checkedCompany = []
            for (let i = 0; i < this.authcompanyList.length; i++) {
                for (let j = 0; j < this.checkedKeys.length; j++) {
                    if (this.checkedKeys[j] === this.authcompanyList[i].companyId) {
                        checkedCompany.push(this.authcompanyList[i].id)
                        break
                    }
                }
            }
            let value = JSON.stringify({
                ids: checkedCompany
            })
            this.$confirm({
                title: '删除',
                content: '真的要取消授权吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$user.doDelSysUserCompany({
                        ids: value
                    }).then(res => {
                        this.$message.success('公司取消授权成功')
                        this.doGetUserAuthDataList()
                        this.checkedKeys = []
                        this.selectedKeys = []
                    })
                }
            })
        },
        // 选择公司
        onCheck(checkedKeys, e) {
            this.checkedKeys = checkedKeys // 选中的id
        },
        // 当前选择公司
        onSelect(selectedKeys, e) {
            if (selectedKeys.length > 0) {
                this.selectedKeys = selectedKeys // 当前选中的id
                this.currentCompanyId = selectedKeys[0]
            }
        },
        onClose() {
            this.editVisible = false
            this.repository = {}
            this.checkedKeys = []
            this.selectedKeys = []
        }
    }
}
</script>
<style scoped lang="less">
@deep: ~'>>>';

@{deep} .ant-modal {
    width: 50% !important;

    .menu-content {
        height: 400px;
        overflow-y: scroll;
        // ::-webkit-scrollbar {
        //     width: 10px;
        // }
    }

    .menu-title {
        font-size: 16px;
        line-height: 30px;
        padding-bottom: 5px;
    }
}
</style>
