<template>
    <div>
        <a-modal v-model="editVisible" title="授权公司" ok-text="保存" cancel-text="关闭" @ok="saveModelData"
            :bodyStyle='bodyStyle' @cancel="onClose">
            <div v-if='commit' class="fixed-mask">
                <a-spin tip="保存中...">
                </a-spin>
            </div>
            <a-row>
                <a-col span="24">
                    <a-select v-model="company" placeholder="选择公司" allowClear showSearch optionFilterProp="label"
                        :mode="modeCompany" :labelInValue="true" style="width: 100%;">
                        <a-spin v-if="fetchingCm" slot="notFoundContent" size="small" />
                        <a-select-option v-for="(item) in companyList" :key="item.id" :value="item.id"
                            :label="item.companyname">{{ item.companyname }}</a-select-option>
                    </a-select>
                </a-col>
            </a-row>
        </a-modal>
    </div>
</template>
<script>
import Busscommutil from '../../../../../utils/busscommutil'
export default {
    data() {
        return {
            editVisible: false,
            bodyStyle: {
                height: '450px',
                overflowY: 'scroll'
            },
            companyList: [],
            company: [], // 所选公司数据
            fetchingCm: false,
            commit: false,
            modeCompany: 'multiple',
            userId: '', // 用户id
            sysUserAuthDatas: [] // 选择的公司
        }
    },
    methods: {
        show(userId) {
            this.sysUserAuthDatas = []
            this.company = []
            this.editVisible = true
            this.userId = userId
            this.getCompanyList()
        },
        //  获取公司列表
        getCompanyList() {
            let companylist = Busscommutil.checkNull(this.$store.state.companylist)
            if (companylist) {
                this.companyList = this.$store.state.companylist
            } else {
                this.fetchingCm = true
                this.$company.doGetCompanyByDisable('', 0, '').then((res) => {
                    this.fetchingCm = false
                    this.$store.commit('saveCompanyList', res.data);
                    this.companyList = res.data
                }).catch(() => {
                    this.fetchingCm = false
                })
            }
        },
        saveModelData() {
            if (this.company.length <= 0) {
                this.$message.error('请选择公司')
                return false
            }
            this.sysUserAuthDatas = []
            for (let i = 0; i < this.company.length; i++) {
                this.sysUserAuthDatas.push({
                    companyId: this.company[i].key,
                    companyName: this.company[i].label,
                    userId: this.userId
                })
                if (i === this.company.length - 1) {
                    this.commit = true
                    this.$user.doAddUserAuthData(this.sysUserAuthDatas).then(res => {
                        this.commit = false
                        this.$message.success('新增成功')
                        this.$emit('update')
                        this.company = []
                        this.editVisible = false
                    }).catch(() => {
                        this.commit = false
                    })
                }
            }
        },
        onClose() {
            this.editVisible = false
            this.company = []
        }
    }
}
</script>

<style scoped lang="less">
@deep: ~'>>>';

.limit-width {

    @{deep} .ant-form-item-label {
        width: 9.5% !important;
    }
}
</style>
