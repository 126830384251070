<template>
  <a-form @submit="handleSubmit" :form="repository" class="form" v-bind="layout">
    <a-row class="form-row" :gutter="24">
      <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="用户名称">
          <a-select v-model="repository.tUserId" placeholder="选择用户" allowClear showSearch optionFilterProp="label">
            <a-spin v-if="fetching" slot="notFoundContent" size="small"></a-spin>
            <a-select-option :value="item.id" v-for="(item) in userList" :key="item.id" :label="item.username">
              {{item.username}}{{item.mobile}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="公司名称">
           <a-select v-model="repository.tCompanyId" placeholder="选择公司" allowClear showSearch optionFilterProp="label">
             <a-spin v-if="fetchingCm" slot="notFoundContent" size="small" />
            <a-select-option :value="item.id" v-for="(item) in companyList" :key="item.id" :label="item.companyname">
              {{item.companyname}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
       <a-col :xxl="12" :xl="24" :lg="24" :md="24" :sm="24" class="limit-width">
        <a-form-item label="城市">
          <Address ref="addressRef" @site="getSite" :isCity='true' :isCounty="false"></Address>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
        <a-col style="margin-bottom: 40px;float: right">
            <a-button type="primary" @click="addUserData" style="margin-right: 20px">新增</a-button>
            <a-button htmlType="submit" type="primary" style="margin-right: 20px">查询</a-button>
            <a-button type="primary" @click="deleteAll">批量删除</a-button>
        </a-col>
    </a-row>
  </a-form>
</template>

<script>
  import Busscommutil from '../../../utils/busscommutil'
  import Address from '@/components/Address/index'
  export default {
    name: 'RepositoryForm',
    data () {
      return {
        repository: {
          tUserId: '',
          tCompanyId: '',
          tProvince: '',
          tCity: '',
          tCounty: ''
        },
        layout: {
            labelCol: { span: 5 },
            wrapperCol: { span: 19 }
        },
        companyList: [],
        userList: [],
        currentSite: '',
        fetching: false,
        fetchingCm: false
      }
    },
    components: {
      Address
    },
    created() {
      this.getCompanyList()
      this.getuserList()
    },
    methods: {
      //  获取公司列表
      getCompanyList() {
        let companylist = Busscommutil.checkNull(this.$store.state.companylist)
        if (companylist) {
          this.companyList = this.$store.state.companylist
        } else {
          this.fetchingCm = true
          this.$company.doGetCompanyByDisable('', 0, '').then((res) => {
            this.fetchingCm = false
            this.$store.commit('saveCompanyList', res.data);
            this.companyList = res.data
          }).catch(() => {
            this.fetchingCm = false
          })
        }
      },
      // 获取用户列表
      getuserList() {
        this.fetching = true
        this.$user.getUsersList().then((res) => {
          this.fetching = false
          this.userList = res.data
        })
      },
      getSite(val) {
        if (val) {
          if (val.length >= 2) {
            if (val.lastIndexOf(' ') === val.length - 1) {
              val = val.substr(0, val.length - 1)
            }
            this.currentSite = val
            let arr = this.currentSite.split(' ')
            if (arr.length === 1) {
              this.repository.tProvince = arr[0]
              this.repository.tCity = ''
              this.repository.tCounty = ''
            }
            if (arr.length === 2) {
              this.repository.tProvince = arr[0]
              this.repository.tCity = arr[1]
              this.repository.tCounty = ''
            }
            if (arr.length === 3) {
              this.repository.tProvince = arr[0]
              this.repository.tCity = arr[1]
              this.repository.tCounty = arr[2]
            }
          } else {
            this.repository.tProvince = ''
            this.repository.tCity = ''
            this.repository.tCounty = ''
          }
        } else {
            this.repository.tProvince = ''
            this.repository.tCity = ''
            this.repository.tCounty = ''
        }
      },
      handleSubmit (e) {
        e.preventDefault()
        this.$emit('formData', this.repository)
      },
      addUserData() {
        this.$emit('add')
      },
      deleteAll() {
        this.$emit('deleteAll')
      }
    }
  }
</script>

<style scoped>

</style>
