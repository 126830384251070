import { getAction, deleteAction, putAction, postAction, postActionQs, httpAction } from '@/api/manage'
import Vue from 'vue'

/**
  * 业务数据
  */
const base = {
    matInventoryApi: '/api/v1/manager/matInventoryApi/', // 物资清单
    dictItemApi: '/api/v1/manager/dictItemApi/' // 分类字典
}
// 获取物资清单列表
const doGetMatInventoryList = (params) => postActionQs(base.matInventoryApi + 'doGetMatInventoryList', params)
// 新增物资清单
const doAddMatInventory = (params) => postActionQs(base.matInventoryApi + 'doAddMatInventory', params)
// 更新物资清单
const doUpdateMatInventory = (params) => postActionQs(base.matInventoryApi + 'doUpdateMatInventory', params)
// 删除物资清单
const doDelMatInventory = (ids) => postActionQs(base.matInventoryApi + 'doDelMatInventory', {
  ids: ids
})

export default {
    doGetMatInventoryList,
    doAddMatInventory,
    doUpdateMatInventory,
    doDelMatInventory
}
