import { getAction, deleteAction, putAction, postAction, postActionQs, httpAction } from '@/api/manage'

/**
  * 接口域名的管理
  */
const base = {
  PcMenuApi: '/api/v1/manager/PcMenuApi/', // 小程序权限
  PcMenuRoleApi: '/api/v1/manager/PcMenuRoleApi/', // 功能授权
  PcMenuRegionApi: '/api/v1/manager/PcMenuRegionApi/', // 授权区域
  PcMenuAuthApi: '/api/v1/manager/PcMenuAuthApi/' // 授权功能
}

const doGetPcMenuList = (params) => postActionQs(base.PcMenuApi + 'doGetPcMenuList', {
  menuName: params.menuName,
  parentName: params.parentName
})// 获取全部菜单
const doAddPcMenu = (params) => postAction(base.PcMenuApi + 'doAddPcMenu', params)// 新增菜单
const doUpdatePcMenu = (params) => postActionQs(base.PcMenuApi + 'doUpdatePcMenu', params)// 更新菜单
const doDeletePcMenu = (id) => postActionQs(base.PcMenuApi + 'doDeletePcMenu', {
  id: id
})// 删除菜单

// 菜单授权
const doGetPcMenuRoleList = (params) => postActionQs(base.PcMenuRoleApi + 'doGetPcMenuRoleList', params)// 获取菜单授权列表
const doAddPcMenuRole = (params) => postAction(base.PcMenuRoleApi + 'doAddPcMenuRole', params)// 新增菜单授权
const doUpdatePcMenuRole = (params) => postActionQs(base.PcMenuRoleApi + 'doUpdatePcMenuRole', params)// 更新菜单授权
const doDeletePcMenuRole = (ids) => postActionQs(base.PcMenuRoleApi + 'doDeletePcMenuRole', {
  ids: ids
})// 删除菜单授权
const doCopyPcMenuRole = (companyIds, id) => postActionQs(base.PcMenuRoleApi + 'doCopyPcMenuRole', {
  companyIds: companyIds, // 所选公司id
  id: id // 复制信息id
})// 复制菜单授权

// 授权区域
const doGetPcMenuRegionListByPage = (params) => getAction(base.PcMenuRegionApi + 'doGetPcMenuRegionListByPage', params)// 获取全部授权区域数据
const doAddPcMenuRegion = (params) => postAction(base.PcMenuRegionApi + 'doAddPcMenuRegion', params)// 新增授权区域
const doDelPcMenuRegion = (ids) => postActionQs(base.PcMenuRegionApi + 'doDelPcMenuRegion', ids)// 删除授权区域

// 授权功能
const doGetPcMenuAuthList = (params) => postActionQs(base.PcMenuAuthApi + 'doGetPcMenuAuthList', params)// 获取授权功能列表
const doAddPcMenuAuth = (params) => postAction(base.PcMenuAuthApi + 'doAddPcMenuAuth', params)// 新增
const doDeletePcMenuAuth = (ids) => postActionQs(base.PcMenuAuthApi + 'doDeletePcMenuAuth', ids)// 删除
const doGetPcMenuAuthParent = (appMenuId) => postActionQs(base.PcMenuAuthApi + 'doGetPcMenuAuthParent', {
  appMenuId: appMenuId
})// 查询父级id
// 获取授权菜单列表
const doGetPcMenuAuthByArea = () => postActionQs(base.PcMenuApi + 'doGetPcMenuAuthByArea')
export default {
  doGetPcMenuList,
  doAddPcMenu,
  doDeletePcMenu,
  doUpdatePcMenu,
  doGetPcMenuRoleList,
  doAddPcMenuRole,
  doUpdatePcMenuRole,
  doDeletePcMenuRole,
  doGetPcMenuRegionListByPage,
  doAddPcMenuRegion,
  doDelPcMenuRegion,
  doGetPcMenuAuthList,
  doAddPcMenuAuth,
  doDeletePcMenuAuth,
  doGetPcMenuAuthParent,
  doCopyPcMenuRole,
  doGetPcMenuAuthByArea
}
