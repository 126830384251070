<template>
  <a-modal v-model="editVisible" :title="modalTitle" ok-text="保存" cancel-text="关闭" @ok="saveModelData"
    :bodyStyle='bodyStyle' :destroyOnClose="true" :maskClosable="false">
    <div class="fixed-mask" v-if="fetching">
      <a-spin size="large" tip="保存中..." />
    </div>
    <a-form-model class="editFormList" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
    :wrapper-col="wrapperCol">
      <a-form-model-item label="单据类型" prop="billTypeDesc">
        <a-input v-model="form.billTypeDesc" allowClear placeholder="请填写单据类型" oninput="value=value.replace(/\s/g,'')"></a-input>
      </a-form-model-item>
      <a-form-model-item label="是否禁用" prop="disable">
        <a-radio-group v-model="form.disable">
          <a-radio :value="1">
            是
          </a-radio>
          <a-radio :value="0">
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
export default {
  name: 'editFormList',
  components: {
  },
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      editVisible: false,
      form: {
        billTypeDesc: '',
        disable: ''
      },
      rules: {
        billTypeDesc: [{ required: true, message: '请填写单据类型', trigger: 'blur' }],
        disable: [{ required: true, message: '请选择是否禁用', trigger: 'change' }]
      },
      bodyStyle: {
        height: '300px',
        overflowY: 'scroll'
      },
      modalTitle: '',
      fetching: false, // 是否加载完成
      isCommit: false // 保存状态
    };
  },
  watch: {
    // 去掉表单验证信息二次打开残留
    editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
      // 监视dialog状态（打开、关闭）
      if (val) {
        try {
          this.$refs.ruleForm.resetFields() // 重置编辑表单
          // addForm 为ref指向的表单
        } catch (e) { }
      }
    }
  },
  created() {
  },
  methods: {
    show(val, title) {
      this.modalTitle = title
      this.form = val
      this.editVisible = true
    },
    saveModelData() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.fetching = true
          if (this.modalTitle === '新增') {
            this.$flow.doAddFlowBillType(this.form).then(res => {
              this.$message.success('新增成功')
              this.fetching = false
              this.editVisible = false
              this.$emit('update')
            }).catch(() => {
              this.fetching = false
            })
          }
          if (this.modalTitle === '修改') {
            this.$flow.doUpdateFlowBillType(this.form).then(res => {
              this.$message.success('修改成功')
              this.fetching = false
              this.editVisible = false
              this.$emit('update')
            }).catch(() => {
              this.fetching = false
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>
<style scoped lang="less">
</style>
