<template>
    <a-table :columns='columns' :data-source='dxxjData' bordered :pagination='false' :scroll='scroll' :loading="loading"
        :row-selection="rowSelection">
        <!-- 用户管理 -->
        <template slot="usermanage" slot-scope="text,record">
            <a slot="usermanage">
                <span class="edit" @click="EditModal(record)">修改</span>
                <span class="edit" @click="EditPassModal(record)" v-if="roleid === 99 || roleid === 1"
                    style="margin-left: 20px">重置密码</span>
            </a>
        </template>
        <!-- 用户管理 -- 小程序 -->
        <template slot="wxApplet" slot-scope="text">
            <a slot="wxApplet" :style="[{ color: text === '已绑定' ? 'rgba(0, 0, 0, .65)' : 'red' }]">
                {{ text }}
            </a>
        </template>
        <!-- 用户管理 -- 公众号 -->
        <template slot="official" slot-scope="text">
            <a slot="official" :style="[{ color: text === '已绑定' ? 'rgba(0, 0, 0, .65)' : 'red' }]">
                {{ text }}
            </a>
        </template>
        <!-- 用户管理 -- 数据权限 -->
        <template slot="userauth" slot-scope="text, record">
            <a slot="userauth">
                <span class="edit" @click="authUserData(record)">授权</span>
            </a>
        </template>
        <!-- 用户管理 -- 切换公司范围 -->
        <template slot="rangecompany" slot-scope="text, record">
            <a slot="rangecompany">
                <span class="edit" @click="rangeCompany(record)">授权</span>
            </a>
        </template>
        <!-- 数据权限 -->
        <template slot="userdata" slot-scope="text,record">
            <a slot="userdata">
                <span class="edit" @click="editRoleMenu(record)" style="margin-right: 10px">修改</span>
                <span class="cancel" @click="deleteUserData(record)">删除</span>
            </a>
        </template>
        <!-- 公司管理 -->
        <template slot="gsgl" slot-scope="text,record">
            <a slot="gsgl">
                <span v-if="record.typeId === '10390001'" @click="checkRuleList(record)"
                    style="margin-right: 10px">校验规则</span>
                <span class="edit" @click="editHzgxMenu(record)">修改</span>
            </a>
        </template>
        <!-- 公司管理 - 是否禁用-->
        <template slot="comapnydisable" slot-scope="text,record">
            <a slot="comapnydisable">
                <a-switch checked-children="启用" un-checked-children="禁用"
                    :default-checked="record.disable === 0 ? true : false"
                    @change="value => onChangeDisable(value, record)" />
            </a>
        </template>
        <!-- 公告管理 -->
        <template slot="notice" slot-scope="text,record">
            <a slot="notice">
                <span class="edit" @click="editNotice(record)" style="margin-right: 10px">修改</span>
                <span class="cancel" @click="deleteNotice(record)" style="margin-right: 10px">删除</span>
            </a>
        </template>
        <!-- 分类类别 -->
        <template slot="fllb" slot-scope="text,record">
            <a slot="fllb">
                <span class="edit" @click="fllbPreserve(record)" style="margin-right: 10px;color: #fe7e02">维护</span>
                <span class="edit" @click="fllbEdit(record)" style="margin-right: 10px">修改</span>
                <span class="cancel" @click="fllbDel(record)">删除</span>
            </a>
        </template>
        <!-- 分类字典 -->
        <template slot="flzd" slot-scope="text,record">
            <a slot="flzd">
                <span class="edit" @click="flzdEdit(record)" style="margin-right: 10px">修改</span>
                <span class="cancel" @click="flzdDel(record)">删除</span>
            </a>
        </template>
        <!-- 用户管理 -- 用户状态 -->
        <template slot="userstatus" slot-scope="text,record">
            <a slot="userstatus">
                <a-switch checked-children="启用" un-checked-children="禁用"
                    :default-checked="record.status === '10020002' ? false : true"
                    @change="value => onChangeUserStatus(value, record)" />
            </a>
        </template>
        <!-- 用户管理 -- 是否开启短信通知 -->
        <template slot="smsOffon" slot-scope="text,record">
            <a slot="smsOffon">
                <a-switch checked-children="是" un-checked-children="否"
                    :default-checked="record.smsOffon === 0 ? false : true"
                    @change="value => onChangeNote(value, record)" />
            </a>
        </template>
        <!-- 公司管理 -- 校验规则 -->
        <template slot="checkrule" slot-scope="text,record">
            <a slot="checkrule">
                <span class="edit" @click="ruleEdit(record)" style="margin-right: 10px">编辑</span>
                <span @click="doTest(record)">功能测试</span>
            </a>
        </template>
        <!-- 公司管理 -- 校验规则描述 -->
        <template slot="rulemsg" slot-scope="text">
            <a-tooltip placement="topLeft">
                <template slot="title">
                    {{ text }}
                </template>
                {{ text }}
            </a-tooltip>
        </template>
        <!-- 小程序权限 -- 角色功能 -->
        <template slot="rolefun" slot-scope="text,record">
            <a slot="rolefun">
                <span @click="copyData(record)" style="margin-right: 10px">复制</span>
                <span @click="onEdit(record)" style="margin-right: 10px">修改</span>
                <span @click="deleteRoleFun(record)">删除</span>
            </a>
        </template>
        <!-- 小程序权限 - 角色功能 - 功能和区域-->
        <template slot="RoleFunAuth" slot-scope="text,record">
            <a slot="RoleFunAuth">
                <span class="edit" @click="onRoleFun(record)" style="margin-right: 10px">功能授权</span>
                <span class="edit" @click="onArea(record)">授权区域</span>
            </a>
        </template>
        <!-- 小程序权限 - 角色功能 - 授权区域-->
        <template slot="rolefunregion" slot-scope="text,record">
            <a slot="rolefunregion">
                <span class="edit" @click="onDeleteRoleFunRegion(record)">删除</span>
            </a>
        </template>
        <!-- PC权限 - 菜单授权-->
        <template slot="pcmenuauth" slot-scope="text,record">
            <a slot="pcmenuauth">
                <span @click="copyData(record)" style="margin-right: 10px">复制</span>
                <span @click="onEdit(record)" style="margin-right: 10px">修改</span>
                <span @click="deletePcMenuAuth(record)">删除</span>
            </a>
        </template>
        <!-- PC权限 - 菜单授权 - 授权区域-->
        <template slot="menuregion" slot-scope="text,record">
            <a slot="menuregion">
                <span class="edit" @click="onDeleteMenuRegion(record)">删除</span>
            </a>
        </template>
        <!-- PC权限 - 菜单授权 - 菜单和区域-->
        <template slot="RolemenuAuth" slot-scope="text,record">
            <a slot="RolemenuAuth">
                <span class="edit" @click="onRoleFun(record)" style="margin-right: 10px">菜单授权</span>
                <span class="edit" @click="onArea(record)">授权区域</span>
            </a>
        </template>
        <!-- 用户管理 - 授权区域-->
        <template slot="userregionauth" slot-scope="text,record">
            <a slot="userregionauth">
                <span class="edit" @click="onDeleteUserRegion(record)">取消授权</span>
            </a>
        </template>
        <!-- 角色管理 -->
        <template slot="jsgl" slot-scope="text,record">
            <a slot="jsgl">
                <span class="edit" @click="onEdit(record)" style="margin-right: 10px">修改</span>
                <span class="cancel" @click="deleteRole(record)">删除</span>
            </a>
        </template>
        <!-- 角色管理 -- 是否禁用 -->
        <template slot="rolesmsOffon" slot-scope="text,record">
            <a slot="rolesmsOffon">
                <a-switch checked-children="启用" un-checked-children="禁用"
                    :default-checked="record.disable === 0 ? true : false"
                    @change="value => onChangeRoleDisable(value, record)" />
            </a>
        </template>
        <!-- 角色管理 -- 授权 -->
        <template slot="authLoginPc" slot-scope="text,record">
            <a slot="authLoginPc">
                <a-switch checked-children="已授权" un-checked-children="未授权"
                    :default-checked="record.authLoginPc === 0 ? false : true"
                    @change="value => onChangeRoleAuthPC(value, record)" />
            </a>
        </template>
        <!-- 角色管理 -- 授权角色 -->
        <template slot="rolemsgauth" slot-scope="text,record">
            <a slot="rolemsgauth">
                <span class="edit" @click="onRoleEdit(record)">授权</span>
            </a>
        </template>
        <!-- 业务数据 - 物资清单 -->
        <template slot="goodslist" slot-scope="text,record">
            <a slot="goodslist">
                <span @click="onEdit(record)" style="margin-right: 10px;">修改</span>
                <span @click="deleteGoodsList(record)" class="cancel">删除</span>
            </a>
        </template>
        <!-- 流程管理 - 单据类型 -->
        <template slot="typeRef" slot-scope="text,record">
            <a slot="typeRef">
                <span @click="onEdit(record)" style="margin-right: 10px;">修改</span>
                <span @click="deleteDocType(record)" class="cancel">删除</span>
            </a>
        </template>
        <!-- 流程管理 - 单据类型 -- 流程模型-->
        <template slot="flowmodal" slot-scope="text,record">
            <a slot="">
                <span class="edit" @click="onCheckDocument(record, 'flowmodal')">查看</span>
            </a>
        </template>
        <!-- 流程管理 - 单据类型 -- 流程状态-->
        <template slot="flowstatus" slot-scope="text,record">
            <a slot="">
                <span class="edit" @click="onCheckDocument(record, 'flowstatus')">查看</span>
            </a>
        </template>
        <!-- 流程管理 - 单据类型 - 是否禁用-->
        <template slot="doctypedisable" slot-scope="text,record">
            <a slot="doctypedisable">
                <a-switch checked-children="启用" un-checked-children="禁用"
                    :default-checked="record.disable === 0 ? true : false"
                    @change="value => onChangeDisable(value, record)" />
            </a>
        </template>
        <!-- 流程管理 - 流程管理 -->
        <template slot="docapprove" slot-scope="text,record">
            <a slot="docapprove">
                <span @click="onEdit(record)" style="margin-right: 10px;">修改</span>
                <span @click="deleteApprove(record)" class="cancel">删除</span>
            </a>
        </template>
        <!-- 字典数据 - 组织机构 -->
        <template slot="org" slot-scope="text,record">
            <a slot="org">
                <span @click="onEdit(record)" style="margin-right: 10px;">修改</span>
                <span @click="deleteOrgData(record)" class="cancel">删除</span>
            </a>
        </template>
        <!-- 字典数据 - 组织机构 -- 状态 -->
        <template slot="orgstatus" slot-scope="text,record">
            <a slot="">
                <a-switch checked-children="启用" un-checked-children="禁用"
                    :default-checked="record.status === 0 ? true : false"
                    @change="value => onChangeStatus(value, record)" />
            </a>
        </template>
    </a-table>
</template>
<script>
import { getAction, postActionQs } from '../../api/manage.js'
import Busscommutil from '../../utils/busscommutil'
import Util from '../../utils/util'
export default {
    props: ['columns', 'pagination', 'pageInfo', 'scroll', 'type', 'roleid'],
    data() {
        return {
            user: null,
            dxxjData: [],
            loading: false,
            rowSelection: undefined,
            selectedRowKeys: []
        }
    },
    created() {
        this.getList()
    },
    methods: {
        getList(val) {
            let pageApi
            if (val) {
                pageApi = val
            } else {
                pageApi = this.pageInfo
            }
            this.dxxjData = []
            pageApi.page = this.pagination.current
            pageApi.limit = this.pagination.pageSize
            let _this = this
            this.loading = true
            if (this.type === 'usermanage') {
                this.$user.doGetUserList(pageApi).then((res) => {
                    _this.loading = false
                    _this.temp = res.data
                    _this.pagination.total = res.count
                    _this.temp.forEach((item, index) => {
                        item.isMobileAuthDesc = item.isMobileAuth === 0 ? '否' : '是'
                        let openId = Busscommutil.checkNull(item.openid)
                        let mpOpenId = Busscommutil.checkNull(item.mpOpenId)
                        if (openId) {
                            item.openId = '已绑定'
                        } else {
                            item.openId = '未绑定'
                        }
                        if (mpOpenId) {
                            item.mpOpenid = '已绑定'
                        } else {
                            item.mpOpenid = '未绑定'
                        }
                        Object.assign(_this.temp[index], { key: item.id })
                    })
                    _this.dxxjData = _this.temp
                }).catch(() => {
                    _this.loading = false
                })
            }
            if (this.type === 'userdata') {
                this.rowSelection = {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: function (selectedRowKeys) {
                        this.selectedRowKeys = selectedRowKeys;
                        _this.$emit('doSelect', this.selectedRowKeys)
                    }
                }
                this.$permission.doGetUserDataAuthList(pageApi).then((res) => {
                    _this.loading = false
                    _this.temp = res.data
                    _this.pagination.total = res.count
                    _this.temp.forEach((item, index) => {
                        Object.assign(_this.temp[index], { key: item.tId })
                    })
                    _this.dxxjData = _this.temp
                }).catch(() => {
                    _this.loading = false
                })
            }
            if (this.type === 'company') {
                this.rowSelection = {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: function (selectedRowKeys) {
                        this.selectedRowKeys = selectedRowKeys;
                        _this.$emit('doSelect', this.selectedRowKeys)
                    },
                    fixed: true
                }
                this.$ywpz.doGetBusiConfPostList(pageApi).then((res) => {
                    _this.loading = false
                    if (res.status === 200) {
                        _this.temp = res.data.rows
                        _this.pagination.total = res.data.total
                        _this.temp.forEach((item, index) => {
                            item.isOpenDutyRule = Busscommutil.transIsShow(item.isOpenDutyRule)
                            Object.assign(_this.temp[index], { key: item.id })
                        })
                        _this.dxxjData = _this.temp
                    }
                })
            }
            if (this.type === 'gsgl') {
                this.$company.doGetCompanyListByPage(pageApi).then((res) => {
                    _this.loading = false
                    if (res.status === 200) {
                        _this.temp = res.data.rows
                        _this.pagination.total = res.data.total
                        _this.temp.forEach((item, index) => {
                            Object.assign(_this.temp[index], { key: item.id })
                        })
                        _this.dxxjData = _this.temp
                    }
                })
            }
            if (this.type === 'notice') {
                getAction('/api/v1/admin/BasicDataApi/getGgnrList', pageApi).then((res) => {
                    _this.loading = false
                    if (res.code === 0) {
                        _this.temp = res.data
                        _this.pagination.total = res.count
                        _this.temp.forEach((item, index) => {
                            item.tSfzs = Busscommutil.transIsShow(item.tSfzs)
                            Object.assign(_this.temp[index], { key: item.tBh })
                        })
                        _this.dxxjData = _this.temp
                    }
                })
            }
            if (this.type === 'fllb') {
                this.$category.doGetDictList(pageApi).then((res) => {
                    _this.loading = false
                    _this.temp = res.data.rows
                    _this.pagination.total = res.data.total
                    _this.temp.forEach((item, index) => {
                        item.disableDesc = Busscommutil.transIsShow(item.disable)
                        Object.assign(_this.temp[index], { key: item.id })
                    })
                    _this.dxxjData = _this.temp
                })
            }
            if (this.type === 'flzd') {
                this.$category.doGetDictItemList(pageApi).then((res) => {
                    _this.loading = false
                    _this.temp = res.data.rows
                    _this.pagination.total = res.data.total
                    _this.temp.forEach((item, index) => {
                        item.disableDesc = Busscommutil.transIsShow(item.disable)
                        item.isEndlevelDesc = Busscommutil.transIsShow(item.isEndlevel)
                        Object.assign(_this.temp[index], { key: item.id })
                    })
                    _this.dxxjData = _this.temp
                })
            }
            if (this.type === 'checkrule') {
                this.rowSelection = {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: function (selectedRowKeys) {
                        this.selectedRowKeys = selectedRowKeys;
                        _this.$emit('doSelect', this.selectedRowKeys)
                    },
                    fixed: true
                }
                this.$company.doGetFieldCheckRuleList(pageApi).then(res => {
                    // 执行某些操作
                    _this.loading = false
                    _this.temp = res.data.rows
                    _this.pagination.total = res.data.total
                    _this.temp.forEach((item, index) => {
                        Object.assign(_this.temp[index], { key: item.id })
                    })
                    _this.dxxjData = _this.temp
                })
            }
            if (this.type === 'rolefun') {
                this.rowSelection = {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: function (selectedRowKeys) {
                        this.selectedRowKeys = selectedRowKeys;
                        _this.$emit('doSelect', this.selectedRowKeys)
                    },
                    fixed: true
                }
                this.$wechatFun.doGetAppMenuRoleList(pageApi).then(res => {
                    _this.loading = false
                    _this.totalData = res.data.rows
                    _this.pagination.total = res.data.total
                    if (res.data.rows.length > 0) {
                        _this.totalData.forEach((item, index) => {
                            Object.assign(_this.totalData[index], { key: item.id })
                        })
                    }
                    _this.dxxjData = _this.totalData
                })
            }
            // 获取全部授权区域
            if (this.type === 'rolearea') {
                this.rowSelection = {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: function (selectedRowKeys) {
                        this.selectedRowKeys = selectedRowKeys;
                        _this.$emit('doSelect', this.selectedRowKeys)
                    },
                    fixed: true
                }
                this.$wechatFun.doGetAppMenuRegionListByPage(pageApi).then(res => {
                    _this.loading = false
                    _this.totalData = res.data.rows
                    _this.pagination.total = res.data.total
                    if (res.data.rows.length > 0) {
                        _this.totalData.forEach((item, index) => {
                            Object.assign(_this.totalData[index], { key: item.id })
                        })
                    }
                    _this.dxxjData = _this.totalData
                })
            }
            // 获取PC权限菜单授权列表
            if (this.type === 'pcmenuauth') {
                this.rowSelection = {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: function (selectedRowKeys) {
                        this.selectedRowKeys = selectedRowKeys;
                        _this.$emit('doSelect', this.selectedRowKeys)
                    },
                    fixed: true
                }
                this.$pcmenu.doGetPcMenuRoleList(pageApi).then(res => {
                    _this.loading = false
                    _this.totalData = res.data.rows
                    _this.pagination.total = res.data.total
                    if (res.data.rows.length > 0) {
                        _this.totalData.forEach((item, index) => {
                            Object.assign(_this.totalData[index], { key: item.id })
                        })
                    }
                    _this.dxxjData = _this.totalData
                })
            }
            // 获取PC权限菜单授权-区域列表
            if (this.type === 'menuarea') {
                this.rowSelection = {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: function (selectedRowKeys) {
                        this.selectedRowKeys = selectedRowKeys;
                        _this.$emit('doSelect', this.selectedRowKeys)
                    },
                    fixed: true
                }
                this.$pcmenu.doGetPcMenuRegionListByPage(pageApi).then(res => {
                    _this.loading = false
                    _this.totalData = res.data.rows
                    _this.pagination.total = res.data.total
                    if (res.data.rows.length > 0) {
                        _this.totalData.forEach((item, index) => {
                            Object.assign(_this.totalData[index], { key: item.id })
                        })
                    }
                    _this.dxxjData = _this.totalData
                })
            }
            // 用户管理-授权区域
            if (this.type === 'userregionauth') {
                this.rowSelection = {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: function (selectedRowKeys) {
                        this.selectedRowKeys = selectedRowKeys;
                        _this.$emit('doSelect', this.selectedRowKeys)
                    },
                    fixed: true
                }
                this.$user.doGetUserAuthDataRegionList(pageApi).then(res => {
                    _this.loading = false
                    _this.totalData = res.data.rows
                    _this.pagination.total = res.data.total
                    if (res.data.rows.length > 0) {
                        _this.totalData.forEach((item, index) => {
                            Object.assign(_this.totalData[index], { key: item.id })
                        })
                    }
                    _this.dxxjData = _this.totalData
                })
            }
            if (this.type === 'jsgl') {
                this.$rolemanage.doGetRoleList(pageApi).then(res => {
                    _this.loading = false
                    _this.temp = res.data.rows
                    _this.pagination.total = res.data.total
                    _this.temp.forEach((item, index) => {
                        // item.authLoginPcStatus = Busscommutil.transIsShow(item.authLoginPc)
                        // item.disableStatus = Busscommutil.transIsShow(item.disable)
                        Object.assign(_this.temp[index], { key: item.id })
                    })
                    _this.dxxjData = _this.temp
                })
            }
            // 公司管理-服务商协议
            if (this.type === 'covenants') {
                this.$company.doGetSysCovernantList(pageApi).then(res => {
                    // 执行某些操作
                    _this.loading = false
                    _this.temp = res.data.rows
                    _this.pagination.total = res.data.total
                    if (res.data.total > 0) {
                        _this.temp.forEach((item, index) => {
                            item.startTime = item.covenantStartTime.split(' ')[0]
                            item.endTime = item.covernantEndTime.split(' ')[0]
                            Object.assign(_this.temp[index], { key: item.id })
                        })
                    }
                    _this.dxxjData = _this.temp
                }).catch((err) => {
                    _this.loading = false
                    console.log(err)
                })
            }
            // 公司管理-影像资料
            if (this.type === 'companyImages') {
                this.$company.doGetSysImageList(pageApi).then(res => {
                    // 执行某些操作
                    _this.loading = false
                    _this.temp = res.data.rows
                    _this.pagination.total = res.data.total
                    if (res.data.total > 0) {
                        _this.temp.forEach((item, index) => {
                            Object.assign(_this.temp[index], { key: item.id })
                        })
                    }
                    _this.dxxjData = _this.temp
                }).catch((err) => {
                    _this.loading = false
                    console.log(err)
                })
            }
            // 业务数据-物资清单
            if (this.type === 'goodslist') {
                this.rowSelection = {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: function (selectedRowKeys) {
                        this.selectedRowKeys = selectedRowKeys;
                        _this.$emit('doSelect', this.selectedRowKeys)
                    },
                    fixed: true
                }
                this.$businessdata.doGetMatInventoryList(pageApi).then(res => {
                    // 执行某些操作
                    _this.loading = false
                    _this.temp = res.data.rows
                    _this.pagination.total = res.data.total
                    if (res.data.total > 0) {
                        _this.temp.forEach((item, index) => {
                            Object.assign(_this.temp[index], { key: item.id })
                        })
                    }
                    _this.dxxjData = _this.temp
                }).catch((err) => {
                    _this.loading = false
                    console.log(err)
                })
            }
            // 流程管理-单据类型
            if (this.type === 'documenttype') {
                this.$flow.doGetFlowBillTypeList(pageApi).then(res => {
                    // 执行某些操作
                    _this.loading = false
                    _this.temp = res.data.rows
                    _this.pagination.total = res.data.total
                    if (res.data.total > 0) {
                        _this.temp.forEach((item, index) => {
                            Object.assign(_this.temp[index], { key: item.id })
                        })
                    }
                    _this.dxxjData = _this.temp
                }).catch((err) => {
                    _this.loading = false
                    console.log(err)
                })
            }
            // 流程管理-单据类型-流程模型
            if (this.type === 'flowmodal') {
                this.$flow.doGetFlowBillNodeList(pageApi).then(res => {
                    // 执行某些操作
                    _this.loading = false
                    _this.temp = res.data.rows
                    _this.pagination.total = res.data.total
                    if (res.data.total > 0) {
                        _this.temp.forEach((item, index) => {
                            Object.assign(_this.temp[index], { key: item.id })
                        })
                    }
                    _this.dxxjData = _this.temp
                }).catch((err) => {
                    _this.loading = false
                    console.log(err)
                })
            }
            // 流程管理-单据类型-流程状态
            if (this.type === 'flowstatus') {
                this.$flow.doGetFlowBillStatusList(pageApi).then(res => {
                    // 执行某些操作
                    _this.loading = false
                    _this.temp = res.data.rows
                    _this.pagination.total = res.data.total
                    if (res.data.total > 0) {
                        _this.temp.forEach((item, index) => {
                            Object.assign(_this.temp[index], { key: item.id })
                        })
                    }
                    _this.dxxjData = _this.temp
                }).catch((err) => {
                    _this.loading = false
                    console.log(err)
                })
            }
            // 字典数据-组织机构
            if (this.type === 'org') {
                this.rowSelection = {
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: function (selectedRowKeys) {
                        this.selectedRowKeys = selectedRowKeys;
                        _this.$emit('doSelect', this.selectedRowKeys)
                    },
                    fixed: true
                }
                if (!pageApi.parentId) {
                    pageApi.parentId = 0
                }
                this.$organization.doGetSysOrgListByPage(pageApi).then(res => {
                    // 执行某些操作
                    _this.loading = false
                    _this.temp = res.data.rows
                    _this.pagination.total = res.data.total
                    if (res.data.total > 0) {
                        _this.temp.forEach((item, index) => {
                            Object.assign(_this.temp[index], { key: item.id })
                        })
                    }
                    _this.dxxjData = _this.temp
                }).catch((err) => {
                    _this.loading = false
                    console.log(err)
                })
            }
            // 流程配置-审批配置
            if (this.type === 'approve') {
                this.$flow.doGetFlowBillNodeUserList(pageApi).then(res => {
                    // 执行某些操作
                    _this.loading = false
                    _this.temp = res.data.rows
                    _this.pagination.total = res.data.total
                    if (res.data.total > 0) {
                        _this.temp.forEach((item, index) => {
                            Object.assign(_this.temp[index], { key: item.id })
                        })
                    }
                    _this.dxxjData = _this.temp
                }).catch((err) => {
                    _this.loading = false
                    console.log(err)
                })
            }
        },
        onEdit(row) {
            this.$emit('edit', row)
        },
        EditModal(row) {
            this.$emit('editModel', row)
        },
        EditPassModal(row) {
            this.$emit('editPassModel', row)
        },
        cancelPjjj(row) {
            this.$emit('cancel', row)
        },
        cancelModel(row) {
            this.$emit('cancel', row)
        },
        editRoleMenu(row) {
            this.$emit('edit', row)
        },
        editCompanyMenu(row) {
            this.$emit('edit', row)
        },
        editHzgxMenu(row) {
            this.$emit('edit', row)
        },
        deleteUserData(row) {
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    postActionQs('/api/v1/manager/PermissionApi/doDeleteUserDataAuth', {
                        tId: row.tId
                    }).then(res => {
                        if (res.status === 200) {
                            this.$message.success('删除成功')
                            this.$emit('delete')
                        }
                    })
                }
            })
        },
        editNotice(row) {
            this.$emit('edit', row)
        },
        deleteNotice(row) {
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    postActionQs('/api/v1/admin/BasicDataApi/doDeleteGgnr', {
                        tBh: row.tBh
                    }).then(res => {
                        if (res.status === 200) {
                            this.$message.success('删除成功')
                            this.$emit('delete')
                        }
                    })
                }
            })
        },
        flzdEdit(row) {
            this.$emit('edit', row)
        },
        flzdDel(row) {
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$category.doDelDictItem(row.id).then(res => {
                        this.$message.success('删除成功')
                        this.$emit('delete')
                    })
                }
            })
        },
        fllbEdit(row) {
            this.$emit('edit', row)
        },
        fllbDel(row) {
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$category.doDelDict(row.id).then(res => {
                        this.$message.success('删除成功')
                        this.$emit('delete')
                    })
                }
            })
        },
        // 分类字典维护
        fllbPreserve(row) {
            this.$emit('preserve', row)
        },
        // 用户管理- 修改用户状态
        onChangeUserStatus(checked, row) {
            let newChecked = checked === true ? '10020001' : '10020002'
            let newCheckedDesc = checked === true ? '启用' : '禁用'
            var res = this.dxxjData.map(function (item) { // 改变数据
                if (item.id === row.id) {
                    item.status = newChecked
                    item.statusDesc = newCheckedDesc
                }
                return item
            })
            this.dxxjData = res
            this.$emit('changeUser', {
                row: row
            })
        },
        // 用户管理- 是否开启短信通知
        onChangeNote(checked, row) {
            let newChecked = checked === true ? 1 : 0
            var res = this.dxxjData.map(function (item) { // 改变数据
                if (item.id === row.id) {
                    item.smsOffon = newChecked
                }
                return item
            })
            this.dxxjData = res
            this.$emit('changeUser', {
                row: row
            })
        },
        // 修改是否禁用功能
        onChangeDisable(checked, row) {
            let newChecked = checked === true ? 0 : 1
            var res = this.dxxjData.map(function (item) { // 改变数据
                if (item.id === row.id) {
                    item.disable = newChecked
                }
                return item
            })
            this.dxxjData = res
            this.$emit('disable', row)
        },
        // 公司管理 - 校验规则
        checkRuleList(row) {
            this.$emit('checkRule', row)
        },
        // 编辑校验规则
        ruleEdit(row) {
            this.$emit('ruleEdit', row)
        },
        // 功能测试
        doTest(row) {
            this.$emit('test', row)
        },
        // 角色功能
        deleteRoleFun(row) {
            let value = JSON.stringify({
                ids: [row.id]
            })
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$wechatFun.doDeleteAppMenuRole(value).then(res => {
                        this.$message.success('删除成功')
                        this.$emit('delete')
                    })
                }
            })
        },
        // 覆盖区域
        onArea(row) {
            this.$emit('onArea', row)
        },
        // 角色授权
        onRoleFun(row) {
            this.$emit('onRoleFun', row)
        },
        // 删除授权区域
        onDeleteRoleFunRegion(row) {
            let value = JSON.stringify({
                ids: [row.id]
            })
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$wechatFun.doDelAppMenuRegion({
                        ids: value
                    }).then(res => {
                        this.$message.success('删除成功')
                        this.$emit('delete')
                    })
                }
            })
        },
        copyData(row) {
            this.$emit('copy', row)
        },
        // 删除pc权限菜单授权
        deletePcMenuAuth(row) {
            let value = JSON.stringify({
                ids: [row.id]
            })
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$pcmenu.doDeletePcMenuRole(value).then(res => {
                        this.$message.success('删除成功')
                        this.$emit('delete')
                    })
                }
            })
        },
        // 删除pc权限菜单授权-授权区域
        onDeleteMenuRegion(row) {
            let value = JSON.stringify({
                ids: [row.id]
            })
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$pcmenu.doDelPcMenuRegion({
                        ids: value
                    }).then(res => {
                        this.$message.success('删除成功')
                        this.$emit('delete')
                    })
                }
            })
        },
        // 用户增加数据权限
        authUserData(row) {
            this.$emit('auth', row)
        },
        // 切换公司范围
        rangeCompany(row) {
            this.$emit('range', row)
        },
        // 删除角色
        deleteRole(row) {
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$rolemanage.doDelRole(row.id).then(res => {
                        this.$message.success('删除成功')
                        this.$emit('delete')
                    })
                }
            })
        },
        // 删除用户授权区域
        onDeleteUserRegion(row) {
            let value = JSON.stringify({
                ids: [row.id]
            })
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$user.doDeleteUserAuthDataRegion({
                        ids: value
                    }).then(res => {
                        this.$message.success('删除成功')
                        this.$emit('delete')
                    })
                }
            })
        },
        // 角色管理-授权角色
        onRoleEdit(row) {
            this.$emit('roleauth', row)
        },
        // 角色管理-是否禁用
        onChangeRoleDisable(checked, row) {
            let newChecked = checked === false ? 1 : 0
            var res = this.dxxjData.map(function (item) { // 改变数据
                if (item.id === row.id) {
                    item.disable = newChecked
                }
                return item
            })
            this.dxxjData = res
            this.$emit('update', {
                row: row
            })
        },
        // 角色管理-是否授权
        onChangeRoleAuthPC(checked, row) {
            let newChecked = checked === true ? 1 : 0
            var res = this.dxxjData.map(function (item) { // 改变数据
                if (item.id === row.id) {
                    item.authLoginPc = newChecked
                }
                return item
            })
            this.dxxjData = res
            this.$emit('update', {
                row: row
            })
        },
        // 删除物资清单
        deleteGoodsList(row) {
            let value = JSON.stringify({
                ids: [row.id]
            })
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$businessdata.doDelMatInventory(value).then(res => {
                        this.$message.success('删除成功')
                        this.$emit('update')
                    })
                }
            })
        },
        // 删除单据类型
        deleteDocType(row) {
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$flow.doDelFlowBillType(row.id).then(res => {
                        this.$message.success('删除成功')
                        this.$emit('update')
                    })
                }
            })
        },
        // 删除组织机构
        deleteOrgData(row) {
            let value = JSON.stringify({
                ids: [row.id]
            })
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$organization.doDelBatchSysOrg(value).then(res => {
                        this.$message.success('删除成功')
                        this.$emit('update')
                    })
                }
            })
        },
        // 修改状态
        onChangeStatus(checked, row) {
            let newChecked = checked === true ? 0 : 1
            var res = this.dxxjData.map(function (item) { // 改变数据
                if (item.id === row.id) {
                    item.status = newChecked
                }
                return item
            })
            this.dxxjData = res
            this.$emit('status', row)
        },
        // 字典数据-单据类型-查看流程模型/流程状态
        onCheckDocument(row, type) {
            this.$emit('check', {
                row: row,
                type: type
            })
        },
        // 删除流程配置
        deleteApprove(row) {
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$flow.doDelFlowBillNodeUser(row.id).then(res => {
                        this.$message.success('删除成功')
                        this.$emit('update')
                    })
                }
            })
        }
    }
}
</script>
<style lang='less'>
@deep: ~'>>>';

.recancel {
    color: #666 !important;
    cursor: not-allowed;

    &:hover {
        opacity: 1;
    }
}

th,
td {
    text-align: center !important;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
    padding: 8px !important;
    word-break: break-word;
}

@{deep} .ant-table-placeholder {
    padding: 0 !important;
    border-top: none !important;
}

.ant-table td {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
}
</style>
