<template>
<a-modal v-model="editVisible" title="个人信息" @ok="() => setModal1Visible()">
  <a-form-model
    class="editFormList"
    ref="ruleForm"
    :model="form"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-model-item label="姓名">
      <span>{{form.username}}</span>
    </a-form-model-item>
    <a-form-model-item label="所属公司">
      <span>{{form.companyName}}</span>
      <a-button type="primary" style="float: right" @click="rangCompany">切换</a-button>
    </a-form-model-item>
    <a-form-model-item label="所属地市">
      <span>{{form.site}}</span>
    </a-form-model-item>
    <a-form-model-item label="手机号">
      <span>{{form.mobile}}</span>
    </a-form-model-item>
    <a-form-model-item label="当前角色">
      <span>{{form.roleName}}</span>
    </a-form-model-item>
  </a-form-model>
  <RangeCompany ref="rangeRef"></RangeCompany>
</a-modal>
</template>
<script>
import RangeCompany from '../RangeCompany'
export default {
  name: 'editFormList',
  props: ['type'],
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      editVisible: false,
      form: {}
    };
  },
  created() {
  },
  components: {
    RangeCompany
  },
  methods: {
    getFormData() {
      this.form = this.$store.state.userInfo
      this.editVisible = true
    },
    // 切换公司
    rangCompany() {
      this.$refs.rangeRef.show()
    },
    setModal1Visible() {
      this.editVisible = false
    }
  }
};
</script>
<style scoped lang="less">

  @deep: ~'>>>';
  .ant-form-item {
    margin-bottom: 10px;
  }
  @{deep} .ant-btn:first-child {
    display: none;
  }
</style>
