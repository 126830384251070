<template>
  <a-modal v-model="editVisible" :title="title" ok-text="保存" cancel-text="关闭" @ok="saveModelData"
    :bodyStyle='bodyStyle'>
    <div style="display: flex;align-items: center;
    justify-content: center;height: 300px" v-if="fetching">
      <a-spin size="large" />
    </div>
    <a-form-model class="editFormList" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
      :wrapper-col="wrapperCol" v-if="!fetching">
      <a-form-model-item label="公司名称" prop="tCompanyId">
        <a-select v-model="form.tCompanyId" placeholder="选择公司" allowClear showSearch optionFilterProp="label">
          <a-select-option :value="item.id" v-for="(item) in companyList" :key="item.id" :label="item.companyname">
            {{ item.companyname }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="用户名称" prop="tUserId">
        <a-select v-model="form.tUserId" placeholder="直接选择或搜索选择" allowClear showSearch optionFilterProp="label"
          :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }" @popupScroll="popupScroll" @search="searchChange" @change="changeUser">
          <a-spin v-if="fetching1" slot="notFoundContent" size="small" :defaultActiveFirstOption="false" />
          <a-select-option :value="item.id" v-for="(item) in subUsersList" :key="item.id" :label="item.username">
            {{ item.username }} {{ item.mobile }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="城市" required>
        <a-cascader :options="areaOptions" v-model="defaultValue" placeholder="请选择城市" @change="onChange"
          :allowClear="false" :showSearch="true" />
      </a-form-model-item>
      <a-form-model-item label="是否启用" prop="tStatus">
        <a-radio-group v-model="form.tStatus">
          <a-radio :value="1">
            启用
          </a-radio>
          <a-radio :value="0">
            禁用
          </a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
  import areaJson from '../../../assets/js/area.json'
  import Busscommutil from '../../../utils/busscommutil'
export default {
  name: 'editFormList',
  props: ['type'],
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      editVisible: false,
      areaOptions: areaJson,
      form: {
        tCompanyName: '',
        tCompanyId: '',
        tCity: '',
        tCounty: '',
        tProvince: '',
        tStatus: '',
        tStatusDesc: '',
        tUserId: '',
        tUserName: ''
      },
      rules: {
        tCompanyId: [{ required: true, message: '请选择公司', trigger: 'change' }],
        tUserId: [{ required: true, message: '请选择用户', trigger: 'change' }],
        tStatus: [{ required: true, message: '请选择是否展示', trigger: 'change' }],
        tStatusDesc: [{ required: false, message: '', trigger: 'blur' }]
      },
      companyList: [],
      usersList: [],
      defaultValue: [],
      bodyStyle: {
        height: '300px',
        overflowY: 'scroll'
      },
      title: '',
      currentSite: '',
      fetching: false,
      pageNum: 0, // 用户分页数
      pageApi: {
        limit: 10,
        page: 1,
        username: ''
      },
      fetching1: false,
      subUsersList: []
    };
  },
  watch: {
    // 去掉表单验证信息二次打开残留
    editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
      // 监视dialog状态（打开、关闭）
      if (val) {
        try {
          this.$refs.ruleForm.resetFields() // 重置编辑表单
          // addForm 为ref指向的表单
        } catch (e) { }
      }
    }
  },
  created() {
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getFormData(val, title) {
      this.getCompanyList()
      this.editVisible = true
      this.form = val
      if (title === 'add') {
        this.title = '新增'
        this.defaultValue = []
        // 用户列表初始化
        this.pageApi.username = ''
        this.usersList = []
        this.subUsersList = []
      }
      if (title === 'edit') {
        this.title = '修改'
        this.defaultValue = [val.tProvince, val.tCity, val.tCounty]
        this.form.tCompanyId = parseInt(this.form.tCompanyId)
        this.form.tUserId = parseInt(this.form.tUserId)
        // 用户列表初始化
        this.pageApi.username = val.tUserName
        this.usersList = []
        this.subUsersList = []
      }
      if (this.usersList.length === 0) {
        this.getUsersList()
      }
    },
    //  获取公司列表
    getCompanyList() {
      let companylist = Busscommutil.checkNull(this.$store.state.companylist)
      if (companylist) {
        this.companyList = this.$store.state.companylist
      } else {
        this.fetchingCm = true
        this.$company.doGetCompanyByDisable('', 0, '').then((res) => {
          this.fetchingCm = false
          this.$store.commit('saveCompanyList', res.data);
          this.companyList = res.data
        }).catch(() => {
          this.fetchingCm = false
        })
      }
    },
    // 获取用户列表
    getUsersList() {
      this.fetching1 = true
      this.$user.doGetUserListNoAuth(this.pageApi).then(res => {
        this.fetching1 = false
        this.usersList = res.data.rows
        this.subUsersList = this.subUsersList.concat(res.data.rows)
        this.pageNum = res.data.pages
      })
    },
    // 获取匹配公司
    getConnectCompany() {
      this.companyList.forEach(item => {
        if (item.id === this.form.tCompanyId) {
          this.form.tCompanyName = item.companyname
        }
      })
    },
    // 获取匹配的用户id
    getConnectUser() {
      this.usersList.forEach(item => {
        if (item.id === this.form.tUserId) {
          this.form.tUserName = item.username
        }
      })
    },
    onChange(val) {
      this.form.tProvince = val[0]
      this.form.tCity = val[1]
      this.form.tCounty = val[2]
    },
    changeUser(value, options) {
      if (options) {
        this.pageApi.username = options.componentOptions.propsData.label
      } else {
        this.pageApi.username = ''
      }
      this.pageApi.page = 1
      this.subUsersList = []
      this.getUsersList()
    },
    searchChange(searchValue) {
      this.pageApi.username = searchValue
      this.subUsersList = []
      this.pageApi.page = 1
      this.getUsersList()
    },
    // 触底获取用户数据
    popupScroll(e) {
      const { target } = e;
      const scrllHeight = target.scrollHeight - target.scrollTop; // scrollHeight: ul的实际高度
      const clientHeight = target.clientHeight; // 250px
      // 下拉框不下拉的时候
      if (scrllHeight === 0 && clientHeight === 0) {
        this.pageApi.page = 1;
      } else if (scrllHeight - clientHeight === 0) { // 下拉到底部时
        if (this.pageApi.page < this.pageNum) {
          // 如果滑到底部，则加载下一页
          this.pageApi.page++
          this.getUsersList()
        }
      }
    },
    saveModelData() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // this.form.tUserName = ''
          // this.form.tCompanyName = ''
          this.getConnectUser()
          this.getConnectCompany()
          if (this.form.tStatus === 0) {
            this.form.tStatusDesc = '禁用'
          }
          if (this.form.tStatus === 1) {
            this.form.tStatusDesc = '启用'
          }
          if (this.title === '新增') {
            this.$permission.doAddUserDataAuth(this.form).then((res) => {
              this.$message.success('新增成功')
              this.editVisible = false
              this.$emit('update')
            })
          }
          if (this.title === '修改') {
            this.$permission.doUpdateUserDataAuth(this.form).then((res) => {
              this.$message.success('修改成功')
              this.editVisible = false
              this.$emit('update')
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>
<style scoped lang="less">
@deep: ~'>>>';
</style>
