<template>
    <a-modal v-model="editVisible" :title="title" ok-text="保存" cancel-text="关闭" @ok="saveModelData"
        :bodyStyle='bodyStyle'>
        <a-form-model class="editFormList" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
            :wrapper-col="wrapperCol">
            <a-form-model-item label="菜单名称" prop="menuName">
                <a-input v-model="form.menuName" />
            </a-form-model-item>
            <a-form-model-item label="父级名称" prop="parentName">
                <a-input v-model="form.parentName" />
            </a-form-model-item>
            <a-form-model-item label="级别" prop="level">
                <a-input v-model="form.level" disabled />
            </a-form-model-item>
            <a-form-model-item label="是否末级" prop="isLastlevel">
                <a-radio-group v-model="form.isLastlevel">
                    <a-radio :value="1">
                        是
                    </a-radio>
                    <a-radio :value="0">
                        否
                    </a-radio>
                </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="菜单标识" prop="menuMark">
                <a-input v-model="form.menuMark" />
            </a-form-model-item>
            <a-form-model-item label="图标" prop="icon">
                <a-input v-model="form.icon" />
            </a-form-model-item>
            <a-form-model-item label="排序" prop="sort">
                <a-input v-model="form.sort" />
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>
<script>
import Busscommutil from '../../../../utils/busscommutil'
export default {
    menuName: 'editFormList',
    props: ['type'],
    data() {
        return {
            labelCol: { span: 8 },
            wrapperCol: { span: 14 },
            editVisible: false,
            form: {
                menuName: '',
                parentName: '',
                menuMark: '',
                sort: '',
                level: '',
                isLastlevel: '',
                parentId: '',
                icon: ''
            },
            rules: {
                menuName: [{ required: true, message: '请填写菜单名称', trigger: 'blur' }],
                isLastlevel: [{ required: true, message: '请选择是否末级', trigger: 'change' }],
                menuMark: [{ required: true, message: '请填写菜单标识', trigger: 'blur' }],
                level: [{ required: true, message: '请填写级别', trigger: 'blur' }],
                parentName: [{ required: true, message: '请填写父级名称', trigger: 'blur' }],
                icon: [{ required: true, message: '请填写图标', trigger: 'blur' }]
            },
            rolesList: [],
            bodyStyle: {
                height: '450px',
                overflowY: 'scroll'
            },
            title: ''
        };
    },
    watch: {
        // 去掉表单验证信息二次打开残留
        editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
            // 监视dialog状态（打开、关闭）
            if (val) {
                try {
                    this.$refs.ruleForm.resetFields() // 重置编辑表单
                    // addForm 为ref指向的表单
                } catch (e) { }
            }
        }
    },
    created() {
    },
    methods: {
        getFormData(val, title) {
            this.editVisible = true
            this.form = val
            if (title === 'edit') {
                this.title = '修改'
                this.form.isLastlevel = Busscommutil.reverseTransIsShow(this.form.isLastlevel)
                if (this.form.level === 1) {
                    this.rules.icon = {
                        required: true, message: '请填写图标', trigger: 'blur'
                    }
                    this.rules.parentName = {
                        required: false, message: '请填写父级名称', trigger: 'blur'
                    }
                } else {
                    this.rules.icon = {
                        required: false, message: '请填写图标', trigger: 'blur'
                    }
                    this.rules.parentName = {
                        required: true, message: '请填写父级名称', trigger: 'blur'
                    }
                }
            }
        },
        saveModelData() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    let list
                    list = this.form
                    list.menuName = this.form.menuName
                    if (this.title === '修改') {
                        this.$pcmenu.doUpdatePcMenu(list).then((res) => {
                            this.$message.success('修改成功')
                            this.editVisible = false
                            this.$emit('update')
                        })
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>
<style scoped lang="less">
@deep: ~'>>>';
</style>
