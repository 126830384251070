import { getAction, deleteAction, putAction, postAction, postActionQs, httpAction } from '@/api/manage'

/**
  * 接口域名的管理
  */
const base = {
  AppMenuApi: '/api/v1/manager/AppMenuApi/', // 小程序权限
  AppMenuRoleApi: '/api/v1/manager/AppMenuRoleApi/', // 功能授权
  AppMenuRegionApi: '/api/v1/manager/AppMenuRegionApi/', // 授权区域
  AppMenuAuthApi: '/api/v1/manager/AppMenuAuthApi/' // 授权功能
}

const doGetAppMenuList = (params) => postActionQs(base.AppMenuApi + 'doGetAppMenuList', {
  menuName: params.menuName,
  parentName: params.parentName
})// 获取全部菜单
const doAddAppMenu = (params) => postAction(base.AppMenuApi + 'doAddAppMenu', params)// 新增功能管理菜单
const doUpdateAppMenu = (params) => postActionQs(base.AppMenuApi + 'doUpdateAppMenu', params)// 更新功能管理菜单
const doDeleteAppMenu = (id) => postActionQs(base.AppMenuApi + 'doDeleteAppMenu', {
  id: id
})// 删除功能管理菜单

// 角色功能
const doGetAppMenuRoleList = (params) => postActionQs(base.AppMenuRoleApi + 'doGetAppMenuRoleList', params)// 获取角色功能列表
const doAddAppMenuRole = (params) => postAction(base.AppMenuRoleApi + 'doAddAppMenuRole', params)// 新增角色功能
const doUpdateAppMenuRole = (params) => postActionQs(base.AppMenuRoleApi + 'doUpdateAppMenuRole', params)// 更新角色功能
const doDeleteAppMenuRole = (ids) => postActionQs(base.AppMenuRoleApi + 'doDeleteAppMenuRole', {
  ids: ids
})// 删除角色功能
const doCopyAppMenuRole = (companyIds, id) => postActionQs(base.AppMenuRoleApi + 'doCopyAppMenuRole', {
  companyIds: companyIds, // 所选公司id
  id: id // 复制信息id
})// 复制角色功能

// 授权区域
const doGetAppMenuRegionListByPage = (params) => getAction(base.AppMenuRegionApi + 'doGetAppMenuRegionListByPage', params)// 获取全部授权区域数据
const doAddAppMenuRegion = (params) => postAction(base.AppMenuRegionApi + 'doAddAppMenuRegion', params)// 新增授权区域
const doDelAppMenuRegion = (ids) => postActionQs(base.AppMenuRegionApi + 'doDelAppMenuRegion', ids)// 删除授权区域

// 授权功能
const doGetAppMenuAuthList = (params) => postActionQs(base.AppMenuAuthApi + 'doGetAppMenuAuthList', params)// 获取授权功能列表
const doAddAppMenuAuth = (params) => postAction(base.AppMenuAuthApi + 'doAddAppMenuAuth', params)// 新增
const doDeleteAppMenuAuth = (ids) => postActionQs(base.AppMenuAuthApi + 'doDeleteAppMenuAuth', ids)// 删除
const doGetAppMenuAuthParent = (appMenuId) => postActionQs(base.AppMenuAuthApi + 'doGetAppMenuAuthParent', {
  appMenuId: appMenuId
})// 查询父级id
export default {
  doGetAppMenuList,
  doAddAppMenu,
  doDeleteAppMenu,
  doUpdateAppMenu,
  doGetAppMenuRoleList,
  doAddAppMenuRole,
  doUpdateAppMenuRole,
  doDeleteAppMenuRole,
  doGetAppMenuRegionListByPage,
  doAddAppMenuRegion,
  doDelAppMenuRegion,
  doGetAppMenuAuthList,
  doAddAppMenuAuth,
  doDeleteAppMenuAuth,
  doGetAppMenuAuthParent,
  doCopyAppMenuRole
}
