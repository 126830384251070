<template>
    <div>
        <a-modal v-model="editVisible" :title="title" cancel-text="关闭" :bodyStyle='bodyStyle' :footer="null"
            :destroyOnClose="true" ref="BusiForm" @cancel="onClose">
            <!-- 搜索 -->
            <RegionFormList @add="AddComRegion" @formData="searchData" @delete="deleteRegion"></RegionFormList>
            <!-- 新增弹框 -->
            <AddComRegion ref="addAreaRef" @update="updateData"></AddComRegion>
            <ATable :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type="menuarea"
                @doSelect="doSelect" ref="tableRef" @delete="searchData" @onArea="AddComRegion"></ATable>
            <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange"
                ref="paginationRef"></APagination>
        </a-modal>
    </div>
</template>
<script>
import AddComRegion from '@/components/SelectArea/index'
import RegionFormList from '@/components/PCAuth/RoleMenuRegion/RegionFormList/index'
import ATable from '@/components/Table/index'
import APagination from '@/components/Pagination/index'
export default {
    name: 'editFormList',
    components: { APagination, ATable, AddComRegion, RegionFormList },
    data() {
        return {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
            editVisible: false,
            companyId: {},
            pageInfo: {
                limit: 10,
                page: 1
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            },
            bodyStyle: {
                height: '600px',
                overflowY: 'scroll'
            },
            columns: [{
                title: '省份',
                dataIndex: 'provinces',
                scopedSlots: { customRender: 'rulemsg' }
            }, {
                title: '地市',
                dataIndex: 'city',
                scopedSlots: { customRender: 'rulemsg' }
            }, {
                title: '区县',
                dataIndex: 'area',
                scopedSlots: { customRender: 'rulemsg' }
            }, {
                title: '操作',
                dataIndex: 'action',
                fixed: 'right',
                scopedSlots: { customRender: 'menuregion' },
                width: '100px'
            }],
            BusiData: [],
            scroll: {
                y: 500
            },
            selectedRowKeys: [],
            title: '',
            companyRoleId: ''
        };
    },
    watch: {
        // 去掉表单验证信息二次打开残留
        editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
            // 监视dialog状态（打开、关闭）
            if (val) {
                try {
                    this.$refs.BusiForm.resetFields() // 重置编辑表单
                    // addForm 为ref指向的表单
                } catch (e) { }
            }
        }
    },
    created() {
    },
    methods: {
        getFormData(val) {
            this.editVisible = true
            this.companyRoleId = val.id // 角色功能id
            this.pageInfo.companyRoleId = val.id
            this.title = '授权区域' + '（' + val.companyName + '-' + val.roleName + '）'
        },
        // 搜索数据
        searchData(val) {
            if (val) {
                this.pageInfo = val
                this.pageInfo.companyRoleId = this.companyRoleId
                this.pagination.pageSize = 10
                this.pagination.current = 1
            }
            this.$refs.tableRef.getList(this.pageInfo)
        },
        // 新增
        AddComRegion() {
            this.$refs.addAreaRef.getFormData(this.companyId, '新增')
        },
        editBusiness(row) {
            let val = { ...row }
            this.$refs.addBusiRef.getFormData(val, '修改')
        },
        // 更新
        updateData(val) {
            let arr = []
            let checkedCityList = val.checkedCityList
            if (checkedCityList && checkedCityList.length > 0) {
                checkedCityList.forEach((item1, index1) => {
                    if (item1.checked) {
                        item1.checkedCountyList.forEach((item2, index2) => {
                            if (item2.checked) {
                                arr.push({
                                    provinces: val.provinces,
                                    provincesCode: val.provincesCode,
                                    city: item1.city,
                                    cityCode: item1.cityCode,
                                    area: item2.county,
                                    areaCode: item2.countyCode,
                                    companyRoleId: this.companyRoleId
                                })
                            }
                        })
                    }
                })
                this.$refs.addAreaRef.fetching = true
                if (arr.length > 0) {
                    this.$pcmenu.doAddPcMenuRegion(arr).then(res => {
                        this.$message.success('新增成功')
                        this.$refs.addAreaRef.editVisible = false
                        this.$refs.addAreaRef.fetching = false
                        this.$refs.tableRef.getList()
                    }).catch(() => {
                        this.fetching = false
                    })
                } else {
                    this.$message.error('请选择省市区')
                }
            } else {
                this.$message.error('请选择省市区')
            }
        },
        // 选择数据
        doSelect(val) {
            this.selectedRowKeys = val
        },
        deleteRegion() {
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning('请选择要删除的数据')
                return false
            }
            let value = JSON.stringify({
                ids: this.selectedRowKeys
            })
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    this.$pcmenu.doDelPcMenuRegion({
                        ids: value
                    }).then(res => {
                        if (res.status === 200) {
                            this.$refs.tableRef.getList()
                            this.selectedRowKeys = []
                            this.$message.success('删除成功')
                        }
                    })
                }
            })
        },
        onClose() {
            this.pageInfo = {}
            this.pagination.pageSize = 10
            this.pagination.current = 1
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.searchData()
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        }
    }
};
</script>
<style scoped lang="less">
@deep: ~'>>>';

@{deep} .ant-modal {
    width: 50% !important
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0;
}
</style>
