import Login from '../views/Login/index'
import Home from '../views/Home'
import OfficialAccounts from '../views/OfficialAccounts'
import PreviewOffice from '../views/PreviewOffice'
import { UserData } from '@/components/AuthManage'
import { Gsgl, Fllb, RoleManage, Organization } from '@/components/DictionaryData' // 字典数据
import { NoticeManage } from '@/components/SysManage'
import SHome from '@/components/Home'
import UserManage from '@/components/UserManage' // 用户管理
import { FunManage, RoleFun } from '@/components/AppletAuth' // 小程序菜单
import { PCMenuManage, RoleMenu } from '@/components/PCAuth' // PC端菜单
import { GoodsList } from '@/components/BusinessData' // 业务数据
import { DocumentType, Approve } from '@/components/Flow' // 流程管理
import MatList from '../views/MatList' // 物资审批表

export const constantRouterMap = [{
  path: '/',
  redirect: '/login'
}, {
    path: '/login',
    component: Login
  }, {
    path: '/officialaccounts',
    name: 'officialaccounts',
    component: OfficialAccounts
  }, {
    path: '/previewoffice',
    name: 'PreviewOffice',
    component: PreviewOffice
  }, {
    path: '/home',
    component: Home,
    children: [{
      path: '/shome',
      component: SHome,
      meta: {
        keepalive: true,
        breadcrumb: ['业务首页', '工作台']
      }
    }, {
      path: '/yhgl',
      component: UserManage,
      meta: {
        keepalive: true,
        breadcrumb: ['人员管理', '用户管理']
      }
    }, {
      path: '/newcdgl',
      component: PCMenuManage,
      meta: {
        keepalive: true,
        breadcrumb: ['权限管理', '菜单管理(新版)']
      }
    }, {
      path: '/menuauth',
      component: RoleMenu,
      meta: {
        keepalive: true,
        breadcrumb: ['权限管理', '菜单授权']
      }
    }, {
      path: '/sjqx',
      component: UserData,
      meta: {
        keepalive: true,
        breadcrumb: ['权限管理', '数据权限']
      }
    }, {
      path: '/fllb',
      component: Fllb,
      meta: {
        keepalive: true,
        breadcrumb: ['字典数据', '分类字典']
      }
    }, {
      path: '/rolemanage',
      component: RoleManage,
      meta: {
        keepalive: true,
        breadcrumb: ['字典数据', '角色管理']
      }
    }, {
      path: '/gsgl',
      component: Gsgl,
      meta: {
        keepalive: true,
        breadcrumb: ['字典数据', '公司管理']
      }
    },
    {
      path: '/funmanage',
      component: FunManage,
      meta: {
        keepalive: true,
        breadcrumb: ['小程序权限', '功能管理(新版)']
      }
    }, {
      path: '/funauth',
      component: RoleFun,
      meta: {
        keepalive: true,
        breadcrumb: ['小程序权限', '功能授权']
      }
    },
    {
      path: '/gggl',
      component: NoticeManage,
      meta: {
        keepalive: true,
        breadcrumb: ['系统管理', '公告管理']
      }
    },
    {
      path: '/goodslist',
      component: GoodsList,
      meta: {
        keepalive: true,
        breadcrumb: ['业务数据', '物资清单']
      }
    },
    {
      path: '/documenttype',
      component: DocumentType,
      meta: {
        keepalive: true,
        breadcrumb: ['流程管理', '单据类型']
      }
    },
    {
      path: '/organization',
      component: Organization,
      meta: {
        keepalive: true,
        breadcrumb: ['字典数据', '组织机构']
      }
    },
    {
      path: '/approve',
      component: Approve,
      meta: {
        keepalive: true,
        breadcrumb: ['流程管理', '审批配置']
      }
    },
    {
      path: '/matlist',
      component: MatList,
      meta: {
        keepalive: true,
        breadcrumb: ['业务报表', '物资审批表']
      }
    }]
}]
