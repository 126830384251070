<template>
    <a-modal v-model="editVisible" :title="title" ok-text="保存" cancel-text="关闭" @ok="saveModelData"
        :bodyStyle='bodyStyle'>
        <div v-if='isCommit' class="fixed-mask">
            <a-spin tip="保存中...">
            </a-spin>
        </div>
        <a-form-model class="editFormList" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol"
            :wrapper-col="wrapperCol">

            <a-form-model-item label="公司名称" prop="company">
                <a-select v-model="form.company" placeholder="选择公司" allowClear showSearch optionFilterProp="label"
                    :mode="modeCompany" :labelInValue="true">
                    <a-spin v-if="fetchingCm" slot="notFoundContent" size="small" />
                    <a-select-option v-for="(item) in companyList" :key="item.id" :value="item.id"
                        :label="item.companyname">{{ item.companyname }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="角色名称" prop="role">
                <a-select v-model="form.role" placeholder="选择角色" allowClear showSearch optionFilterProp="label"
                    :mode="modeRole" :labelInValue="true">
                    <a-select-option v-for="(item) in rolesList" :key="item.id" :value="item.id"
                        :label="item.roleName">{{ item.roleName }}</a-select-option>
                </a-select>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>
<script>
import Busscommutil from '../../../../utils/busscommutil'
export default {
    data() {
        return {
            labelCol: { span: 8 },
            wrapperCol: { span: 14 },
            editVisible: false,
            modeRole: '', // 角色名称选择模式
            modeCompany: '', // 所属公司选择模式
            form: {
                company: [],
                role: []
            },
            rules: {
                company: [{ required: true, message: '请选择公司', trigger: 'change' }],
                role: [{ required: true, message: '请选择角色', trigger: 'change' }]
            },
            rolesList: [],
            bodyStyle: {
                height: '450px',
                overflowY: 'scroll'
            },
            title: '',
            fetchingCm: false,
            isCommit: false, // 保存状态
            companyList: [],
            appMenuRoles: [] // 所有选中的数据集合
        };
    },
    watch: {
        // 去掉表单验证信息二次打开残留
        editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
            // 监视dialog状态（打开、关闭）
            if (val) {
                try {
                    this.$refs.ruleForm.resetFields() // 重置编辑表单
                    // addForm 为ref指向的表单
                } catch (e) { }
            }
        }
    },
    created() {
    },
    methods: {
        getFormData(val, title) {
            this.form = val
            this.editVisible = true
            if (title === 'add') {
                this.title = '新增'
                this.modeRole = 'multiple'
                this.modeCompany = 'multiple'
            }
            this.getCompanyList()
            this.getRolesList()
        },
        //  获取公司列表
        getCompanyList() {
            let companylist = Busscommutil.checkNull(this.$store.state.companylist)
            if (companylist) {
                this.companyList = this.$store.state.companylist
            } else {
                this.fetchingCm = true
                this.$company.doGetCompanyByDisable('', 0, '').then((res) => {
                    this.fetchingCm = false
                    this.$store.commit('saveCompanyList', res.data);
                    this.companyList = res.data
                }).catch(() => {
                    this.fetchingCm = false
                })
            }
        },
        // 获取角色列表
        getRolesList() {
            this.$rolemanage.getRolesList().then((res) => {
                this.rolesList = res.data
            })
        },
        // 组装数据
        getAppMenuRoles() {
            this.appMenuRoles = []
            for (let i = 0; i < this.form.company.length; i++) {
                for (let j = 0; j < this.form.role.length; j++) {
                    this.appMenuRoles.push({
                        companyId: this.form.company[i].key,
                        companyName: this.form.company[i].label,
                        roleId: this.form.role[j].key,
                        roleName: this.form.role[j].label
                    })
                    if (i === this.form.company.length - 1 && j === this.form.role.length - 1) {
                        this.isCommit = true
                        this.$wechatFun.doAddAppMenuRole(this.appMenuRoles).then(res => {
                            this.isCommit = false
                            this.$message.success('新增成功')
                            this.$emit('update')
                            this.editVisible = false
                        }).catch(() => {
                            this.isCommit = false
                        })
                    }
                }
            }
        },
        saveModelData() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    if (this.title === '新增') {
                        this.getAppMenuRoles()
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>
<style scoped lang="less">
@deep: ~'>>>';
</style>
