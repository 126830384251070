<template>
<a-modal v-model="editVisible" title="重置密码" ok-text="保存" cancel-text="关闭" @ok="saveModelData">
  <a-form-model
    class="editFormList"
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-model-item label="用户名">
      <a-input
        v-model="form.username" disabled/>
    </a-form-model-item>
    <a-form-model-item label="新密码" prop="password">
      <a-input
        v-model="form.password"/>
        <span>6-10位的数字或字母，不包括空格</span>
    </a-form-model-item>
  </a-form-model>
</a-modal>
</template>
<script>
import moment from 'moment'
import { getAction, postAction, postActionQs } from '../../../api/manage.js'
export default {
  name: 'editFormList',
  props: ['type'],
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 12 },
      editVisible: false,
      form: {
        username: '',
        userId: '',
        password: ''
      },
      rules: {
        password: [{ required: true, message: '请填写新密码', trigger: 'blur' }]
      }
    };
  },
   watch: {
    // 去掉表单验证信息二次打开残留
    editVisible(val, newVal) { // 监听的是控制模态框显示或影藏开关的布尔值
        // 监视dialog状态（打开、关闭）
        if (val) {
            try {
                this.$refs.ruleForm.resetFields() // 重置编辑表单
                // addForm 为ref指向的表单
            } catch (e) {}
        }
    }
  },
  created() {
  },
  methods: {
    moment,
    getFormData(val) {
      this.editVisible = true
      this.form.userId = val.id
      this.form.username = val.username
    },
    saveModelData() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let reg = /^[0-9A-Za-z]{6,10}$/
          if (!reg.test(this.form.password)) {
            this.$message.warning('请输入6-10位的数字或字母')
            return false
          }
          if (this.form.password.length < 6 || this.form.password.length > 10) {
            this.$message.warning('请输入6-10位的数字或字母')
            return false
          }
          this.$user.doUpdatePass(this.form).then((res) => {
            if (res.status === 200) {
              if (this.form.userId === this.$store.state.userInfo.id) {
                this.$message.success('重置成功,请重新登录')
                this.editVisible = false
                this.$router.push({ path: '/login' });
                this.$store.commit('clearAllSession')
              } else {
                this.$message.success('重置成功')
                this.editVisible = false
              }
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
};
</script>
<style scoped lang="less">

  @deep: ~'>>>';
</style>
