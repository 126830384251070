<template>
  <a-form @submit="handleSubmit" :form="repository" class="form" v-bind="layout">
    <a-row class="form-row" :gutter="24">
      <a-col :xxl="6" :xl="8" :lg="8" :md="12" :sm="24">
        <a-form-item label="角色名称">
          <a-input placeholder="角色名称" v-model="repository.roleName" allowClear oninput="value=value.replace(/\s/g,'')"/>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
        <a-col style="margin-bottom: 20px;float: right">
            <a-button type="primary" style="margin-right: 20px" @click="addData">新增</a-button>
            <a-button htmlType="submit" type="primary">查询</a-button>
        </a-col>
    </a-row>
  </a-form>
</template>

<script>
  export default {
    name: 'category-type',
    data () {
      return {
        repository: {},
        layout: {
            labelCol: { span: 5 },
            wrapperCol: { span: 19 }
        }
      }
    },
    methods: {
      handleSubmit (e) {
        e.preventDefault()
        this.$emit('formData', this.repository)
      },
      addData() {
        this.$emit('add')
      }
    }
  }
</script>

<style scoped>

</style>
