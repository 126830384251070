import { getAction, deleteAction, putAction, postAction, postActionQs, httpAction } from '@/api/manage'
import Vue from 'vue'
const base = {
    RoleApi: '/api/v1/manager/RoleApi/',
    RoleAuthApi: '/api/v1/manager/RoleAuthApi/'
}
// 角色管理
const getRolesList = (params) => getAction(base.RoleApi + 'doGetAllRole', {
    roleName: params // 角色名称
  }); // 获取所有角色
const doAddRole = (params) => postActionQs(base.RoleApi + 'doAddRole', params) // 新增
const doUpdateRole = (params) => postActionQs(base.RoleApi + 'doUpdateRole', params) // 修改
const doDelRole = (id) => postActionQs(base.RoleApi + 'doDelRole', {
    id: id
}) // 删除
const doGetRoleList = (params) => postActionQs(base.RoleApi + 'doGetRoleList', params) // 获取
// 授权角色
const doAddRoleAuth = (params) => postAction(base.RoleAuthApi + 'doAddRoleAuth', params) // 新增
const doGetRoleAuthList = (params) => postActionQs(base.RoleAuthApi + 'doGetRoleAuthList', params) // 获取
const doDelRoleAuth = (ids) => postActionQs(base.RoleAuthApi + 'doDelRoleAuth', {
    ids: ids
}) // 删除
const doGetClickRoleAuth = () => postActionQs(base.RoleAuthApi + 'doGetClickRoleAuth') // 获取点选授权角色
export default {
    getRolesList,
    doAddRole,
    doUpdateRole,
    doDelRole,
    doGetRoleList,
    doAddRoleAuth,
    doGetRoleAuthList,
    doDelRoleAuth,
    doGetClickRoleAuth
}
