import 'babel-polyfill'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Viewer from 'v-viewer'// 图片预览

import './config/router.js'// 接口类

import './assets/less/style.less' // 公共的样式
import * as demandImport from './assets/js/demandImport' // 按需加载的ant组件
import Base64 from './assets/js/base64.js' // 加密解密文件
import 'viewerjs/dist/viewer.css'

Vue.prototype.$Base64 = Base64;
Vue.prototype.demandImport = demandImport
Vue.prototype.$axios = axios

Vue.use(Viewer) // 图片预览

axios.interceptors.request.use((config) => {
  config.headers.token = window.sessionStorage.getItem('token')
  config.headers.userToken = window.sessionStorage.getItem('token')
  // 在最后必须return config
  return config
})

// 回到页面顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
let startApp = function () {
  new Vue({
    router,
    store,
    // components: { App },
    // template: '<App :LANGUAGES ="LANGUAGES" />',
    // data: {
    //   LANGUAGES: LANGUAGES['en'] // en这个值，根据项目情况动态获取
    // },
    // mounted: function () {
    //   console.log('mounted')
    // },
    render: h => h(App)
  }).$mount('#app')
}

startApp()
