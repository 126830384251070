<template>
    <div>
        <div class="fixed-mask" v-if="loading">
            <a-spin tip="保存中...">
            </a-spin>
        </div>
        <OrgFormList :id="currentParentId" @formData="getOrgData" @add="addModal" @delete="deleteAll"
            @goback="goBackFirst" ref="searchRef"></OrgFormList>
        <ATable :columns="columns" :pagination="pagination" :pageInfo="pageInfo" :scroll='scroll' type="org"
            ref="tableRef" @doSelect="doSelect" @edit="editOrg" @update="getAuthData1" @status="changeStatus"></ATable>
        <OrgModal ref="orgaddRef" @update="getAuthData1"></OrgModal>
        <BatchAddModal ref='BatchAddRef' @update="getAuthData1"></BatchAddModal>
        <APagination :pagination="pagination" @showsizechange="getShowSizeChange" @pageSizeChange="onPageSizeChange"
            ref="paginationRef"></APagination>
    </div>
</template>
<script src="">
import APagination from '@/components/Pagination/index'
import OrgModal from '@/components/DictionaryData/Organization/RightContent/Modal/OrgModal'
import OrgFormList from '@/components/DictionaryData/Organization/RightContent/Search'
import ATable from '@/components/Table/index'
import BatchAddModal from '@/components/DictionaryData/Organization/RightContent/Modal/BatchAdd'
const columns = [
    {
        title: '机构名称',
        dataIndex: 'orgName',
        scopedSlots: { customRender: 'rulemsg' },
        width: '250px',
        fixed: 'left'
    },
    {
        title: '机构简称',
        dataIndex: 'orgNameAbbr',
        scopedSlots: { customRender: 'rulemsg' }
    }, {
        title: '机构标识代码',
        dataIndex: 'orgRemarkCode',
        scopedSlots: { customRender: 'rulemsg' }
    }, {
        title: '机构级别',
        dataIndex: 'level',
        width: '6%'
    }, {
        title: '状态',
        dataIndex: 'status',
        scopedSlots: { customRender: 'orgstatus' },
        width: '8%'
    }, {
        title: '备注',
        dataIndex: 'remark',
        scopedSlots: { customRender: 'rulemsg' }
    }, {
        title: '创建人',
        dataIndex: 'createBy',
        scopedSlots: { customRender: 'rulemsg' },
        width: '6%'
    }, {
        title: '创建时间',
        dataIndex: 'createTime'
    }, {
        title: '更新人',
        dataIndex: 'updateBy',
        scopedSlots: { customRender: 'rulemsg' },
        width: '6%'
    }, {
        title: '更新时间',
        dataIndex: 'updateTime'
    },
    {
        title: '操作',
        dataIndex: 'operation',
        width: '100px',
        scopedSlots: { customRender: 'org' },
        fixed: 'right'
    }];
export default {
    data() {
        return {
            columns: columns,
            userType: 1,
            totalData: [],
            expandedRowKeys: [],
            pageInfo: {
                page: 1,
                limit: 10
            },
            loading: false,
            scroll: {
                x: 1600,
                y: 500
            },
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0,
                pageSizeOptions: ['5', '10', '20', '30'], // 这里注意只能是字符串，不能是数字
                showTotal: (total, range) => `显示${range[0]}-${range[1]}条，共有 ${total}条`
            },
            selectedRowKeys: [], // 被选中的删除数据
            currentParentId: '', // 当前父级id
            currentData: {} // 当前父级数据
        }
    },
    components: {
        OrgModal, OrgFormList, APagination, ATable, BatchAddModal
    },
    methods: {
        // 获取数据
        getOrgData(val) {
            this.currentParentId = val.id // 父级id
            this.pageInfo.page = 1
            this.pageInfo.limit = 10
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.pageInfo.parentId = val.id
            this.pageInfo.orgName = val.orgName
            if (val.type === 0) { // 0: 左侧菜单获取下一级 1：右侧搜索
                this.currentData = val
                this.$refs.searchRef.repository.orgName = ''
            }
            this.$refs.tableRef.getList(this.pageInfo)
        },
        // 获取当前节点id以及下面所有子孙节点的id
        getNodeAndChildrenids(data) {
            let temp = [];
            const loop = (data) => {
                data.map(item => {
                    temp.push(item.id)
                    if (item.children && item.children.length) {
                        loop(item.children);
                    }
                })
            }
            loop(data);
            return temp;
        },
        // 获取数据
        getAuthData1() {
            this.$refs.tableRef.getList(this.pageInfo)
            this.$emit('updateTree', {
                id: this.pageInfo.parentId
            })
        },
        // 修改
        editOrg(val) {
            let obj = { ...val }
            this.$refs.orgaddRef.getFormData(obj)
        },
        // 新增
        addModal() {
            let val = this.currentData
            var arr = Object.keys(val);
            let newObj = {}
            if (arr.length === 0) {
                newObj = {
                    orgName: '',
                    parentId: 0,
                    orgNameAbbr: '',
                    orgRemarkCode: '',
                    level: 1
                }
            } else {
                newObj = {
                    orgName: '',
                    parentId: val.id,
                    orgNameAbbr: '',
                    orgRemarkCode: val.orgRemarkCode,
                    level: val.level + 1
                }
            }
            this.$refs.BatchAddRef.getFormData(newObj)
        },
        // 删除
        doSelect(val) {
            this.selectedRowKeys = val
        },
        deleteAll() {
            if (this.selectedRowKeys.length === 0) {
                this.$message.warning('请选择要删除的数据')
                return false
            }
            let data = JSON.stringify({
                ids: this.selectedRowKeys
            })
            let _this = this
            this.$confirm({
                title: '删除',
                content: '真的删除吗',
                okText: '确定',
                mask: false,
                onOk: () => {
                    _this.$organization.doDelBatchSysOrg(data).then(res => {
                        _this.$message.success('批量删除成功')
                        _this.selectedRowKeys = []
                        _this.getAuthData1()
                    })
                }
            })
        },
        // 修改状态
        changeStatus(row) {
            this.loading = true
            this.$organization.doUpdateSysOrg(row).then((res) => {
                this.loading = false
                this.$message.success('修改成功')
            }).catch(() => {
                this.loading = false
            })
        },
        // 返回一级
        goBackFirst() {
            this.$emit('goback')
        },
        // 获取分页
        getShowSizeChange(page) {
            this.handleTableChange(page)
        },
        handleTableChange(pagination) {
            this.$refs.tableRef.getList(this.pageInfo)
        },
        onPageSizeChange(page) {
            this.handleTableChange(page)
        }
    }
}
</script>
