<template>
  <div class="zlp-pagination">
    <a-pagination
        show-size-changer
        :pageSizeOptions="pagination.pageSizeOptions"
        :total="pagination.total"
        :show-total="pagination.showTotal"
        :page-size="pagination.pageSize"
        :current="pagination.current"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
    />
  </div>
</template>

<script>
  export default {
    name: 'zlp-pagination',
    props: ['pagination'],
    data() {
      return {
      }
    },
    created() {
    },
    methods: {
        onChange(page, pageSize) {
            this.pagination.current = page
            this.pagination.pageSize = pageSize
            this.$emit('pageSizeChange', this.pagination)
        },
        onShowSizeChange(current, pageSize) {
            this.pagination.current = 1
            this.pagination.pageSize = pageSize
            this.$emit('showsizechange', this.pagination)
        }
    }
  }
</script>

<style lang="less" scoped>
    @deep: ~ '>>>';
    .zlp-pagination {
        display: flex;
        justify-content: flex-end;
        padding-top: 30px;
    }
    .ant-pagination {
        display: inline-block;
    }
</style>
